var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MainFrame", {
        scopedSlots: _vm._u([
          {
            key: "columnRight",
            fn: function() {
              return [
                _c("img", { attrs: { src: _vm.imageRightIndex, alt: "" } })
              ]
            },
            proxy: true
          },
          {
            key: "columnLeft",
            fn: function() {
              return [
                _c("h1", { staticClass: "a-headline a-headline--1" }, [
                  _vm._v(_vm._s(_vm.$t("preconfigure.title")))
                ]),
                _vm._v(" "),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.$t("preconfigure.body")) }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "m-btn-group m-btn-group--block" }, [
                  _c(
                    "button",
                    {
                      staticClass: "a-btn a-btn--primary a-btn--big",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmit()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("preconfigure.actions.yes")) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "a-btn a-btn--primary a-btn--big",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onSkipStep()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("preconfigure.actions.no")) +
                          "\n                "
                      )
                    ]
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }