var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "myopus-eu" } }, [
    _c("div", { staticClass: "layout", attrs: { id: "panel" } }, [
      _c("header", { staticClass: "layout__header" }, [
        _c("div", { staticClass: "layout__container" }, [
          _c(
            "div",
            { staticClass: "layout__header-inner" },
            [
              _c(
                "div",
                { staticClass: "layout__logo" },
                [
                  _c("router-link", { attrs: { to: { name: "index" } } }, [
                    _c("img", { attrs: { src: _vm.logo, alt: "Opus Logo" } })
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "portal-target",
                {
                  staticClass: "layout__headline",
                  attrs: { name: "header-headline" }
                },
                [
                  _c("router-link", { attrs: { to: { name: "index" } } }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$t("global.pageTitle")) +
                        "\n                        "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout__nav" },
                [
                  _vm.currentCode
                    ? _c("span", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("saveConfiguration.children.success.code")
                            ) +
                            " " +
                            _vm._s(_vm.currentCode) +
                            "\n                            "
                        ),
                        _c("img", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t(
                                  "saveConfiguration.children.success.codeTooltip"
                                )
                              },
                              expression:
                                "{ content:$t('saveConfiguration.children.success.codeTooltip')}"
                            }
                          ],
                          attrs: {
                            src: _vm.iconInfo,
                            alt: _vm.$t(
                              "saveConfiguration.children.success.codeTooltip"
                            )
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("LocaleSwitcher")
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("main", { staticClass: "layout__main" }, [
        _c("div", { staticClass: "layout__container" }, [_c("router-view")], 1)
      ]),
      _vm._v(" "),
      _c("footer", { staticClass: "layout__footer o-footer" }, [
        _c("div", { staticClass: "o-footer__meta" }, [
          _c("div", { staticClass: "layout__container" }, [
            _c("div", { staticClass: "o-footer__meta-inner" }, [
              _c("div", { staticClass: "o-footer__contact" }, [
                _c("ul", { staticClass: "o-footer__contact-list" }, [
                  _c("li", { staticClass: "o-footer__contact-list-item" }, [
                    _c("span", { staticClass: "a-icon__pin" }),
                    _vm._v(
                      "\n                                    " +
                        _vm._s(_vm.$t("global.usp.shipping.label")) +
                        "\n                                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "o-footer__contact-list-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "a-icon__phone",
                        attrs: { href: _vm.$t("global.usp.phone.href") }
                      },
                      [
                        _vm._v(
                          "\n                                        " +
                            _vm._s(_vm.$t("global.usp.phone.label")) +
                            "\n                                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "o-footer__contact-list-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "a-icon__mail",
                        attrs: { href: _vm.$t("global.usp.email.href") }
                      },
                      [
                        _vm._v(
                          "\n                                        " +
                            _vm._s(_vm.$t("global.usp.email.label")) +
                            "\n                                    "
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "o-footer__footnotes",
                    attrs: { id: "footnotes" }
                  },
                  [
                    _c("small", [
                      _c("sup", [
                        _vm._v("* " + _vm._s(_vm.$t("global.footnotes.price")))
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "o-footer__social" }, [
                _c("ul", { staticClass: "o-footer__social-list" }, [
                  _c("li", { staticClass: "o-footer__social-list-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "a-icon__social-twitter",
                        attrs: {
                          href: _vm.$t("global.socialNetworks.twitter.href"),
                          target: "_blank"
                        }
                      },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("global.socialNetworks.twitter.label")
                            )
                          )
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "o-footer__social-list-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "a-icon__social-youtube",
                        attrs: {
                          href: _vm.$t("global.socialNetworks.youtube.href"),
                          target: "_blank"
                        }
                      },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("global.socialNetworks.youtube.label")
                            )
                          )
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "o-footer__social-list-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "a-icon__social-instagram",
                        attrs: {
                          href: _vm.$t("global.socialNetworks.instagram.href"),
                          target: "_blank"
                        }
                      },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("global.socialNetworks.instagram.label")
                            )
                          )
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "o-footer__social-list-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "a-icon__social-pinterest",
                        attrs: {
                          href: _vm.$t("global.socialNetworks.pinterest.href"),
                          target: "_blank"
                        }
                      },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("global.socialNetworks.pinterest.label")
                            )
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "o-footer__legal" }, [
          _c("div", { staticClass: "layout__container" }, [
            _c("div", { staticClass: "o-footer__legal-inner" }, [
              _c("div", [_vm._v("© JÄGER DIREKT " + _vm._s(_vm.currentYear))]),
              _vm._v(" "),
              _c("nav", { staticClass: "o-footer__legal-nav" }, [
                _c("ul", { staticClass: "o-footer__legal-nav-list" }, [
                  _c("li", { staticClass: "o-footer__legal-nav-list-item" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.$t("global.footerMenu.items.imprint.href")
                        }
                      },
                      [
                        _vm._v(
                          "\n                                        " +
                            _vm._s(
                              _vm.$t("global.footerMenu.items.imprint.label")
                            ) +
                            "\n                                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "o-footer__legal-nav-list-item" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.$t("global.footerMenu.items.privacy.href")
                        }
                      },
                      [
                        _vm._v(
                          "\n                                        " +
                            _vm._s(
                              _vm.$t("global.footerMenu.items.privacy.label")
                            ) +
                            "\n                                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "o-footer__legal-nav-list-item" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.$router.resolve({ name: "termsOfUse" }).href
                        }
                      },
                      [
                        _vm._v(
                          "\n                                        " +
                            _vm._s(
                              _vm.$t("global.footerMenu.items.termsOfUse.label")
                            ) +
                            "\n                                    "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.currentCode
                    ? _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t(
                                  "saveConfiguration.children.success.codeTooltip"
                                )
                              },
                              expression:
                                "{ content:$t('saveConfiguration.children.success.codeTooltip')}"
                            }
                          ],
                          staticClass: "o-footer__legal-nav-list-item"
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "saveConfiguration.children.success.code"
                                )
                              ) +
                              " " +
                              _vm._s(_vm.currentCode) +
                              "\n                                "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }