var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainFrame", {
    attrs: { "has-divider": false, "has-right-column-slot": false },
    scopedSlots: _vm._u([
      {
        key: "columnLeft",
        fn: function() {
          return [
            _c("h1", { staticClass: "a-headline a-headline--1" }, [
              _vm._v(_vm._s(_vm.$t("subprocess.children.surveillance.title")))
            ]),
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("subprocess.children.surveillance.body")
                )
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "grid__row" }, [
              _c("div", { staticClass: "grid__col" }, [
                _c(
                  "ul",
                  { staticClass: "o-cards o-cards--fill" },
                  _vm._l(_vm.subpages, function(subpage) {
                    return _c(
                      "li",
                      {
                        staticClass: "m-card",
                        class: { "m-card--checked": subpage.completed }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "m-card__trigger",
                            attrs: { to: subpage.route }
                          },
                          [
                            subpage.icon
                              ? _c("div", { staticClass: "m-card__image" }, [
                                  _c("img", {
                                    attrs: { src: subpage.icon, alt: "" }
                                  })
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-card__title" }, [
                              _vm._v(_vm._s(subpage.title))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "m-btn-group m-btn-group--right" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "a-btn a-btn--primary",
                    attrs: { to: { name: "subprocesses" } }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t(
                            "subprocess.children.surveillance.cards.exit.title"
                          )
                        ) +
                        "\n            "
                    )
                  ]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }