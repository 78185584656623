<template>
    <section class="o-main">
        <ProgressBar v-if="hasProgressBar" :max="progressBarMax" :current="progressBarCurrent"/>

        <div class="o-main__row">
            <div v-if="hasLeftColumnSlot" class="o-main__column " :class="{'o-main__column--left': hasRightColumnSlot}">
                <slot name="columnLeft"></slot>
            </div>
            <div v-if="hasDivider" class="o-main__divider"></div>
            <div v-if="hasRightColumnSlot" class="o-main__column o-main__column--right">
                <slot name="columnRight">
                    <img :src="placeholderImage" alt="">
                </slot>
            </div>
        </div>
    </section>
</template>

<script>
import AppIcons from '../mixins/app-icons';
import ProgressBar from './ProgressBar';

export default {
    name: 'MainFrame',
    components: {ProgressBar},
    mixins: [AppIcons],
    props: {
        hasDivider: {
            type: Boolean,
            required: false,
            default: true,
        },
        hasProgressBar: {
            type: Boolean,
            required: false,
            default: false,
        },
        progressBarMax: {
            type: Number,
            required: false,
        },
        progressBarCurrent: {
            type: Number,
            required: false,
        },
        hasRightColumnSlot: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        hasLeftColumnSlot() {
            return !!this.$slots['columnLeft']
        },
        // hasRightColumnSlot () {
        //     return !!this.$slots['columnRight']
        // }
    }
};
</script>
