export default {
  global: {
    pageTitle: 'Konfigurator',
    metaMenu: {
      items: {
        save: 'Speichern',
      },
    },
    socialNetworks: {
      twitter: {
        label: 'Twitter',
        href: 'https://twitter.com/DiscoverOpus',
      },
      youtube: {
        label: 'YouTube',
        href: 'https://www.youtube.com/channel/UC5hU8LMoL4gRCCaanf533Hw',
      },
      instagram: {
        label: 'Instagram',
        href: 'https://www.instagram.com/discoveropus/',
      },
      pinterest: {
        label: 'Pinterest',
        href: 'https://www.pinterest.de/discoveropus/',
      },
      // linkedin: {
      //   label: 'LinkedIn',
      //   href: '',
      // },
      // facebook: {
      //   label: 'Facebook',
      //   href: '',
      // },
    },
    footerMenu: {
      items: {
        imprint: {
          href: 'https://myopus.eu/impressum',
          label: 'Impressum',
        },
        privacy: {
          href: 'https://myopus.eu/datenschutz',
          label: 'Datenschutz',
        },
        termsOfUse: {
          label: 'Nutzungsbedingungen',
        },
      },
    },
    forms: {
      errors: {
        required: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    usp: {
      shipping: {
        href: '',
        label: 'Kostenloser Versand in Deutschland ab 99,- €',
      },
      phone: {
        href: 'tel:+4961649300649',
        label: '06164 9300 649',
      },
      email: {
        href: 'mailto:service@myopus.eu',
        label: 'service@myopus.eu',
      },
    },
    actions: {
      edit: 'Bearbeiten',
      delete: 'Löschen',
      back: 'zurück',
      complete: 'Konfiguration abschließen'
    },
    attributes: {
      productNo: 'Artikel-Nr.:',
      priceFrom: 'Preis: ab ',
      allRoundPrice: 'Gesamtpreis',
      unitPrice: 'Stückpreis',
      sum: 'Summe',
    },
    footnotes: {
      price: 'Unverbindlicher Preiseinidkator inkl. der jeweils gültigen gesetzlichen Umsatzsteuer.',
    }
  },
  index: {
    title: 'Herzlich willkommen!',
    body: '<p>Konfigurieren Sie sich Ihr OPUS Smart Home Raum für Raum – genau nach Ihren Wünschen. Sie können auch eine bereits erstellte Konfiguration hochladen und dort weiterarbeiten.</p>',
    form: {
      fields: {
        termsOfUse: {
          label: 'Ich habe die <a href="{url}" target="_blank">Nutzungsbedingungen</a> zur Kenntnis genommen.',
        },
        code: {
          label: 'Code',
          placeholder: 'Zum Laden einer Konfiguration bitte hier den Code eingeben',
        },
      },
    },
    actions: {
      next: 'Start',
      continue: 'Fortsetzen',
      reset: 'Neu beginnen',
      load: 'Laden',
    },
    notifications: {
      configurationReseted: 'Ihre Konfiguration wurde zurückgesetzt',
      configurationLoaded: 'Ihre Konfiguration wurde erfolgreich geladen',
      configurationFailed: 'Es konnte keine Konfiguration unter dem angegebenen Code gefunden werden',
    },
  },
  editRoom: {
    title: 'Raum umbenennen.',
  },
  addRoom: {
    title: 'Welchen Raum wollen Sie konfigurieren?',
    body: '<p>Bevor es losgeht, geben Sie bitte an welchen Raum Sie mit Ihren OPUS Komponenten intelligent steuern wollen. Mehrere Räume werden nacheinander eingerichtet. Bitte wählen Sie die Bezeichnungen so, dass Sie Ihre Räume immer wieder zuordnen können.</p>',
    form: {
      fields: {
        roomName: {
          label: 'Raumname',
          placeholder: 'Bitte geben Sie hier den Namen ein z.B. Wohnzimmer',
          hint: 'Bereits hinzugefügt: {names}',
        },
      },
    },
    actions: {
      cancel: 'überspringen',
      next: 'weiter',
    },
    notifications: {
      added: 'Neuer Raum wurde hinzugefügt',
      edited: 'Raum wurde umbenannt',
    },
  },
  subprocess: {
    actions: {
      addMoreRooms: 'Weiteren Raum hinzufügen',
      editRoom: 'bearbeiten',
      deleteRoom: 'löschen',
      resetSubprocess: 'zurücksetzen',
      continue: 'Konfiguration abschließen',
      subprocessLight: 'Beleuchtung',
      subprocessShading: 'Verschattung',
      subprocessSurveillance: 'Überwachung',
    },
    confirms: {
      roomDelete: 'Möchten Sie den Raum "{room}" wirklich löschen?'
    },
    children: {
      light: {
        notifications: {
          saved: 'Lichtprozess wurde gespeichert',
        },
        children: {
          dimmer: {
            title: 'Möchten Sie einen Dimmer hinzufügen?',
            body: '<p>Der OPUS BRiDGE Universaldimmer sorgt zu jedem Zeitpunkt für die perfekte Beleuchtung und ist universell für die unterschiedlichsten Leuchtmittel einsetzbar.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nein',
            },
            notifications: {
              added: 'OPUS BRiDGE Dimmer hinzugefügt',
            },
          },
          doubleRockerSwitch: {
            title: 'Welche Lichtsteuerung benötigen Sie?',
            bodyBridge1: '<p>Sie wollen das Licht in der Wohnküche anschalten, aber das Licht im direkt angeschlossenen Esszimmer soll aus bleiben? Dann fügen Sie die OPUS BRiDGE 2 Kanal Licht diesem Raum hinzu. Sie ermöglicht die Steuerung von zwei Lichtstromkreisen unabhängig voneinander und verfügt über eine Doppelwippe.</p>',
            bodyBridge2: '<p>Im Raum gibt es nur einen Lichtstromkreis? Dann reicht die OPUS BRiDGE 1 Kanal Licht für die Steuerung mit einer einfachen Wippe. </p>',
            body: '<p>Sie wollen das Licht in der Wohnküche anschalten, aber das Licht im direkt angeschlossenen Esszimmer soll aus bleiben? Klicken Sie auf "Ja".<br>Die OPUS BRiDGE 2 Kanal Licht mit Doppelwippe ermöglicht die Steuerung von zwei Lichtstromkreisen unabhängig voneinander.</p>',
            actions: {
              yes: 'OPUS BRiDGE 2 Kanal Licht',
              no: 'OPUS BRiDGE 1 Kanal Licht',
            },
            notifications: {
              yes: 'OPUS BRiDGE 2 Kanal Licht mit Doppelwippe hinzugefügt',
              no: 'OPUS BRiDGE 1 Kanal Licht wird hinzugefügt',
            },
          },
          wirelessSwitch: {
            title: 'Möchten Sie eine zusätzliche Schaltstelle hinzufügen?',
            body: '<p>Schaffen Sie eine zusätzliche Steuermöglichkeit mit den kabel- und batterielosen OPUS Wandsendern. Absolute Flexibilität, denn die Wandsender können überall hingelegt, angeschraubt oder angeklebt werden.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nein',
            },
            notifications: {
              added: 'OPUS Wandsender hinzugefügt',
            },
          },
          repeat: {
            title: 'Möchten Sie diesem Raum einen weiteren Schalter hinzufügen?',
            body: '<p>Benötigen Sie in diesem Raum noch einen weiteren Schalter, z.B.  für die Lichtsteuerung eines zusätzlichen Lichtstromkreises? Dann klicken Sie auf "Ja". Sie haben an alles gedacht? Wählen Sie "nein".</p>',
            actions: {
              yes: 'Ja',
              no: 'Nein',
            },
          },
        },
      },
      shading: {
        notifications: {
          saved: 'Verschattungsprozess wurde gespeichert',
        },
        children: {
          landingpage: {
            title: 'Haben Sie elektrische Rollläden?',
            body: '<p>Steuern Sie Ihre Rollläden oder Jalousien schon elektrisch über einen Rollladenmotor? Dann klicken Sie auf „Ja“. Falls nicht, dann lautet die Antwort „nein“.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nein',
            },
          },
          tubularMotor: {
            title: 'Haben Sie elektrische Rollläden?',
            body: '<p>Steuern Sie Ihre Rollläden oder Jalousien schon elektrisch über einen Rollladenmotor? Dann klicken Sie auf „Ja“. Falls nicht, dann lautet die Antwort „nein“.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nein',
            },
            notifications: {
              added: 'Fenstermaße und Rollädenmaterial wurden erfasst.',
            },
            children: {
              options: {
                title: 'Elektrisch & smart: Fügen Sie einen OPUS Rollladenantrieb hinzu',
                body: '<p>Wenn Sie keine elektrischen Rollläden haben, können Sie diese mit dem OPUS Rollladenantrieb nachrüsten. So schlagen Sie zwei Fliegen mit einer Klappe, denn der Rollladenantrieb ist elektrisch und vernetzbar – ohne zusätzlichen Rollladenschalter.</p><p>Bitte füllen Sie die nachfolgende Tabelle für jedes Fenster im Raum aus, das Sie elektrisch und smart steuern wollen.</p>',
                form: {
                  fields: {
                    width: {
                      label: 'Fensterbreite',
                      placeholder: 'Bitte geben Sie die Fensterbreite in mm an.',
                    },
                    height: {
                      label: 'Fensterhöhe',
                      placeholder: 'Bitte geben Sie die Fensterhöhe in mm an.',
                    },
                    material: {
                      label: 'Material',
                      placeholder: 'Bitte wählen Sie das Material Ihrer Rolläden aus.',
                      options: {
                        wood: 'Holz',
                        plastic: 'Kunststoff',
                        aluminium: 'Aluminium',
                      },
                    }
                  }
                },
                  actions: {
                    cancel: 'überspringen',
                    next: 'weiter',
                  },
              },
              repeat: {
                title: 'Möchten Sie weitere OPUS Rollladenantriebe hinzufügen?',
                body: '<p>Sie haben für jedes Fenster Rollladen einen OPUS Rollladenantrieb hinzugefügt? Dann wählen Sie „Ja“. Sie sind sich nicht sicher? Zählen Sie doch nochmal nach.</p>',
                actions: {
                  yes: 'Ja',
                  no: 'Nein',
                },
              },
            },
          },
          shutterSwitch: {
            title: 'Möchten Sie intelligente Rollladen-Schalter hinzufügen? ',
            body: '<p>Sie haben bereits elektrische Rollläden oder Jalousien. Wenn Sie Ihre Rollläden smart steuern wollen, dann fügen Sie pro Fenster mit Rollladen einen OPUS BRiDGE Rollladen/Jalousie-Schalter hinzu und binden Sie diese flexible in Szenen und Automationen ein. Zum Beispiel: Bei Sonnenaufgang öffnen sich die Rollläden automatisch und bei Sonnenuntergang schließen sie. Auch eine Zentral-Steuerung ist denkbar.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nein',
            },
            notifications: {
              added: 'OPUS BRiDGE Rollladen/Jalousie-Schalter hinzugefügt',
            },
            children: {
              repeat: {
                title: 'Möchten Sie weitere intelligente Rollladen-Schalter hinzufügen?',
                body: 'Sie haben für jedes Fenster mit elektrischem Rollladen einen OPUS BRiDGE Rollladen/Jalousie-Schalter hinzugefügt? Dann wählen Sie „Ja“. Sie sind sich nicht sicher? Zählen Sie doch nochmal nach. ',
                actions: {
                  yes: 'Ja',
                  no: 'Nein',
                },
              },
            },
          },
          centralSwitch: {
            title: 'Alle Rollläden gleichzeitig steuern?',
            body: '<p>Durch die Aktivierung der Zentralsteuerung können Sie alle Rollläden und Jalousien gleichzeitig öffnen oder schließen. Erstellen Sie nützliche Automationen, sodass z.B. täglich alle Rollläden automatisch um 20.00 Uhr geschlossen werden.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nein',
            },
            notifications: {
              added: 'OPUS Zentralsteuerung hinzugefügt',
            },
          },
        },
      },
      surveillance: {
        title: 'Überwachung',
        body: '<p>Mit den Überwachungsfunktionen für Ihr OPUS Smart Home immer auf der sicheren Seite: Die Möglichkeiten reichen von Warnungen bei drohendem Wassserschaden über Rauchwarnmelder und Hitze- oder Temperaturüberwachung bis hin zu Diebstahlschutz durch die Kontrolle von Fenstern und Türen.</p>',
        cards: {
          door: {title: 'Fenster und Türen überwachen?'},
          heat: {title: 'Hitze überwachen?'},
          motion: {title: 'Bewegung überwachen?'},
          smoke: {title: 'Rauch überwachen?'},
          temperature: {title: 'Temperatur kontrollieren?'},
          water: {title: 'Wasserschäden vorbeugen?'},
          exit: {title: 'Konfiguration der Überwachungsfunktionen abschließen'},
        },
        children: {
          door: {
            title: 'Geben Sie die Anzahl von Fenstern und Türen ein',
            body: '<p>Keine geöffnete Tür und kein geöffnetes Fenster bleiben unbemerkt, denn mit dieser Überwachungsfunktion erkennt Ihr OPUS Smart Home ob sie offen oder geschlossen sind.  Bitte geben Sie an, wie viele Fenster und Türen Sie überwachen wollen.</p>',
            form: {
              fields: {
                doors: {
                  label: 'Anzahl der Türen',
                  placeholder: 'Bitte geben Sie die Anzahl der Türen an',
                  info: 'Bei doppelflügligen Fenstern, werden 2 Kontakte bzw. 2 Griffe benötigt',
                },
                windows: {
                  label: 'Anzahl der Fenster',
                  placeholder: 'Bitte geben Sie die Anzahl der Fenster an',
                },
              },
            },
            actions: {
              next: 'weiter',
            },
            notifications: {
              added: 'OPUS Tür-/Fensterkontakt hinzugefügt',
              removed: 'OPUS Tür-/Fensterkontakt entfernt',
            },
          },
          heat: {
            title: 'Bitte geben Sie die Anzahl der benötigten Hitzemelder an',
            body: '<p>Der OPUS Hitzemelder dient zur Detektion von Hitze durch Feuer in Wohnräumen, in denen Rauchmelder aufgrund möglicher Fehlalarme nicht einsetzbar sind. Dies kann z.B. in Küchen oder Bädern der Fall sein, wo große Dampf- und Rauchentwicklung zu erwarten sind.</p>',
            form: {
              fields: {
                sensors: {
                  label: 'Anzahl der Hitzemelder',
                  placeholder: 'Bitte geben Sie hier die Anzahl der Hitzemelder ein',
                  info: '',
                },
              },
            },
            actions: {
              next: 'weiter',
            },
            notifications: {
              added: 'OPUS Hitzemelder hinzugefügt',
              removed: 'OPUS Hitzemelder entfernt',
            },
          },
          motion: {
            title: 'Bitte geben Sie die Anzahl der Bewegungsmelder an',
            body: '<p>Der OPUS Smart Motion Sensor ist multifunktional einsetzbar: Er steuert Licht in Abhängigkeit von Bewegung, kann aber zusätzlich die Umgebungstemperatur und den Wert der Luftfeuchtigkeit messen und entsprechende Automationen auslösen.</p>',
            form: {
              fields: {
                sensors: {
                  label: 'Anzahl der Bewegungsmelder',
                  placeholder: 'Bitte geben Sie hier die Anzahl der Bewegungsmelder ein',
                  info: '',
                },
              },
            },
            actions: {
              next: 'weiter',
            },
            notifications: {
              added: 'OPUS Smart Motion Sensor hinzugefügt',
              removed: 'OPUS Smart Motion Sensor entfernt',
            },
          },
          smoke: {
            title: 'Bitte geben Sie die Anzahl der benötigten Rauchmelder an',
            body: '<p>Der OPUS Rauchwarnmelder erkennt die Entwicklung von Rauch- oder Brandgas und warnt zuverlässig durch eine laute Sirene oder per Push-Benachrichtigung auf Ihr Mobile Device. Binden Sie Ihre Rauchwarnmelder in intelligent Automationen ein: Bei Auslösen des Alarms gehen Lichter an und Rollläden öffnen sich.</p>',
            form: {
              fields: {
                detectors: {
                  label: 'Anzahl der Rauchmelder',
                  placeholder: 'Bitte geben Sie hier die Anzahl der Rauchmelder ein',
                  info: 'Übrigens: Sie benötigen mindestens einen Rauchmelder in jedem Kinderzimmer, in jedem Schlafzimmer und in jedem Flur, der eine Verbindung zu Aufenthaltsräumen hat.',
                },
              },
            },
            actions: {
              next: 'weiter',
            },
            notifications: {
              added: 'OPUS Rauchwarnmelder hinzugefügt',
              removed: 'OPUS Rauchwarnmelder entfernt',
            },
          },
          temperature: {
            title: 'Bitte geben Sie die Anzahl der benötigten Temperatursensoren an',
            body: '<p>Der OPUS Temperatur- und Feuchtesensor misst in regelmäßigen Abständen die Umgebungstemperatur sowie den Wert der Luftfeuchtigkeit. Bei auffälligen Messdaten schlägt er Ihnen z.B. per Push-Nachricht vor zu lüften oder passt, in Verbindung mit einer Heizungssteuerung, die Temperatur des Raumes automatisch an.</p>',
            form: {
              fields: {
                sensors: {
                  label: 'Anzahl der Temperatursensoren',
                  placeholder: 'Bitte geben Sie hier die Anzahl der Temperatur Sensoren ein',
                  info: '',
                },
              },
            },
            actions: {
              next: 'weiter',
            },
            notifications: {
              added: 'OPUS Temperatur- und Feuchtesensor hinzugefügt',
              removed: 'OPUS Temperatur- und Feuchtesensor entfernt',
            },
          },
          water: {
            title: 'Welchen Wassermelder möchten Sie hinzufügen?',
            body: '<p>Schutz und Früherkennung von Wasserschäden: Der OPUS Sofortmelder ist geeignet für gut zugängliche Bereiche mit Lichteinfall. Der OPUS Wassermelder mit Quellscheiden ist energieautark und funktioniert auch in absoluter Dunkelheit.</p>',
            actions: {
              instant: 'OPUS Sofort-Wassermelder',
              slices: 'OPUS Wassermelder mit Quellscheiben\n',
            },
            children: {
              instant: {
                title: 'Bitte geben Sie die Anzahl der benötigten Sofort-Wassermelder an',
                body: '<p>Sie haben sich für den OPUS Sofort-Wassermelder entschieden. Er meldet Wasseransammlungen in Echtzeit an Ihr Mobile Device und überträgt zusätzlich die Umgebungstemperatur.</p>',
                form: {
                  fields: {
                    sensors: {
                      label: 'Anzahl der Sofort-Wassermelder',
                      placeholder: 'Bitte geben Sie hier die Anzahl der Sofort-Wassermelder ein',
                      info: '',
                    },
                  },
                },
                actions: {
                  next: 'weiter',
                },
                notifications: {
                  added: 'OPUS Sofort-Wassermelder hinzugefügt',
                  removed: 'OPUS Sofort-Wassermelder entfernt',
                },
              },
              slices: {
                title: 'Bitten geben Sie die Anzahl der Quellscheibenwassermelder an',
                body: '<p>Sie haben sich für den OPUS Wassermelder mit Quellscheiben entschieden. Er meldet Wasseransammlungen zuverlässig und funktioniert ohne zusätzliche Energiequelle auch dann, wenn er im Dunkeln zwischen Wasserrohre gesteckt oder unter Waschmaschinen angebracht wird.</p>',
                form: {
                  fields: {
                    sensors: {
                      label: 'Anzahl der Quellscheibenwassermelder',
                      placeholder: 'Bitte geben Sie hier die Anzahl der Quellscheibenwassermelder ein',
                      info: '',
                    },
                  },
                },
                actions: {
                  next: 'weiter',
                },
                notifications: {
                  added: 'OPUS Wassermelder mit Quellscheiben hinzugefügt',
                  removed: 'OPUS Wassermelder mit Quellscheiben entfernt',
                },
              },
            },
          },
        },
      },
    },
  },
  controlSystem: {
    title: 'Wie wollen Sie Ihr OPUS Smart Home steuern?',
    body: '<p>Egal ob Apple HomeKit, Amazon Alexa oder Google Home - Mit OPUS steuern Sie Ihr Smart Home genau so wie Sie es sich wünschen: Über Smartphone, Tablet, Smart Watch oder via Sprachsteuerung - von Zuhause und von unterwegs!</p><p>Keine Lust auf Apps und Mobile Devices, sondern einfach bei normaler Schalterbedienung bleiben? Wählen Sie einfach "softwarefreie Steuerung" aus.</p>',
    actions: {
      amazonAlexa: 'Amazon Alexa',
      appleHomeKit: 'Apple HomeKit',
      googleHome: 'Google Home',
      none: 'Softwarefreie Steuerung',
    },
  },
  overview: {
    titleSelectedControlSystem: 'Gewählte OPUS Smart Home Steuerung',
    tooltips: {
      deleteAllDevices: 'Alle Produkte vom Typ "{device}" ({num}) aus dem Raum "{room}" entfernen.'
    },
    notifications: {
      deviceDeleted: '{device} ({quantity}) wurde gelöscht',
    },
  },
  preconfigure: {
    title: 'Sollen wir Ihr OPUS Smart Home für Sie vorkonfigurieren?',
    body: '<p>Nutzen Sie unseren Vorkonfigurationsservice und wir liefern Ihnen Ihre Smart Home Komponenten einbaufertig nach Hause.<br>Besonders bei umfangreichen Planungen spart Ihnen der Vorkonfigurationsservice wertvolle Zeit bei der Installation.</p>',
    actions: {
      yes: 'Ja',
      no: 'Nein',
    },
  },
  saveConfiguration: {
    title: 'Möchten Sie Ihre Konfiguration speichern?',
    body: '<p>Bitte speichern Sie Ihre Konfiguration, damit keine Einstellungen verloren gehen. Die Bearbeitung ist im Nachhinein jederzeit möglich.</p>',
    actions: {
      yes: 'Ja',
      no: 'Nein',
    },
    children: {
      success: {
        title: 'Gespeichert',
        body: '<p>Vielen Dank! Ihre Konfiguration wurde erfolgreich gespeichert.</p>',
        code: 'Ihr Code:',
        codeTooltip: 'Mit diesem Code können Sie den aktuellen Stand Ihrer Konfiguration später wieder aufrufen oder teilen.',
        goBack: 'Zurück zum Anfang',
        consultation: 'Beratungstermin',
        offer: 'Angebot',
      },
      contact: {
        title: {
          offer: 'Gerne unterbreiten wir Ihnen ein Angebot.',
          consultation: 'Sie wünschen einen Beratungstermin?',
        },
        form: {
          fields: {
            name: {
              label: 'Name',
              placeholder: 'Bitte geben Sie Ihren Namen ein',
              info: '',
            },
            email: {
              label: 'E-Mail-Addresse',
              placeholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
              info: '',
            },
            phone: {
              label: 'Telefonnummer',
              placeholder: 'Bitte geben Sie Ihre Telefonnummer ein',
              info: '',
            },
            privacyPolicy: {
              label: 'Ich erkläre mich mit den <a href="{url}" target="_blank">Datenschutzbestimmungen</a> von JÄGER DIREKT einverstanden.',
              info: '',
            },
          }
        },
        actions: {
          send: 'Senden',
        },
        notifications: {
          sendSuccess: 'Ihre Anfrage wurde erfolgeich verschickt.',
          sendError: 'Beim Senden ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.'
        }
      }
    },
  },
  privacyPolicy: {
    title:'Datenschutzbestimmungen',
    body: '<p></p>'
  },
  termsOfUse: {
    title:'Nutzungsbedingungen',
    body: '<p>Wir weisen Sie darauf hin, dass die nachfolgend dargestellten Produkte und angegebenen Preise lediglich zu Informationszwecken erfolgen und <strong>kein verbindliches</strong> Angebot zum Abschluss eines Vertrages darstellen. Es besteht keine Möglichkeit, im Konfigurator Produkte verbindlich zu bestellen.</p><p>Mit Abschluss der Konfiguration haben Sie die Möglichkeit, ein unverbindliches Angebot anzufordern. Hierfür müssen Sie die notwendigen Kontaktdaten hinterlassen.</p><p>Die im Rahmen der Konfiguration genannten <strong>Preise sollen einen Richtwert zur Orientierung geben und stellen daher Preisindikatoren dar</strong>. Sie stellen kein verbindliches Angebot dar. Wir weisen Sie ausdrücklich darauf hin, dass die im schlussendlich angeforderten Angebot abgegebenen Preise von den während der Konfiguration ermittelten Preisen im Einzelfall abweichen können.</p><p>Alle Preise, die im Rahmen der Konfiguration angegeben sind, verstehen sich einschließlich der jeweils gültigen gesetzlichen Umsatzsteuer.</p><p>Die im Konfigurator angegebenen Preise beinhalten nicht möglicherweise anfallende Kosten für Verpackung und den Versand. Die Höhe der im Einzelnen geltenden Versandkosten entnehmen Sie bitte dem ggf. angeforderten Angebot.</p><p>Wir behalten uns das Recht vor, alle angebotenen Produkte ohne Angabe von Gründen aus dem Konfigurator und/oder dem angeforderten Angebot zu nehmen.</p><p>Eine Garantie, dass die konfigurierten Produkte zum Zeitpunkt der Angebotseinholung verfügbar und/oder lieferbar sind, kann leider nicht gegeben werden.</p>'
  },
  products: {
    controlSystems: {
      amazonAlexa: {
        title: 'Amazon Alexa',
        description: '<p>Intuitive Bedienung, kompatible Geräte und beinahe unerschöpfliche Möglichkeiten im OPUS Smart Home.</p>',
      },
      appleHomeKit: {
        title: 'Apple HomeKit',
        description: '<p>Dein OPUS Smart Home hört auf dich und ist außerdem kompatibel mit deinen liebsten Apple-Funktionen.</p>',
      },
      googleHome: {
        title: 'Google Home',
        description: '<p>Du kannst das OPUS Smart Home via Google Home mit vielen Smart Home-Geräte wie Lampen, Kameras und Thermostate steuern.</p>',
      },
      none: {
        title: 'Softwarefreie Steuerung',
        description: '<p>Deine OPUS Geräte sind auch ohne Software smart vernetzt. Erweiterungen sind flexibel möglich – auch nachträglich!</p>',
      },
    },
    lightDimmer: {
      title: 'OPUS BRiDGE Dimmer',
      description: '<p>Flackerfrei und geräuschlos - Smarter Universaldimmer und Basis für die weitere Vernetzung.</p>',
      image: '',
      productNo: '563.076',
      price: '107.00',
    },
    lightDoubleRockerSwitch: {
      title: 'OPUS BRiDGE 2 Kanal Licht',
      description: '<p>Smarter Lichtschalter für die Steuerung von zwei unabhängigen Lichtstromkreisen. Basis für die weitere Vernetzung.</p>',
      image: '',
      productNo: '563.020',
      price: '91.64',
    },
    lightSingleRockerSwitch: {
      title: 'OPUS BRiDGE 1 Kanal Licht',
      description: '<p>Smarter Lichtschalter für die Steuerung von zwei unabhängigen Lichtstromkreisen. Basis für die weitere Vernetzung.</p>',
      image: '',
      productNo: '563.010',
      price: '87.00',
    },
    lightWirelessSwitch: {
      title: 'OPUS Wandsender',
      description: '<p>Freipositionierbarer Wandsender, der sich selbst mit Strom versorgt – ohne Batterie oder Kabel.</p>',
      image: '',
      productNo: '514.441',
      price: '49.54',
    },
    doorSensor: {
      title: 'OPUS Türkontakt',
      description: '<p>Wartungsfreier Funksender mit Solarzelle – erkennt sofort, wenn die Tür geöffnet oder geschlossen wird.</p>',
      image: '',
      productNo: '561.454',
      price: '76.84',
    },
    windowSensor: {
      title: 'OPUS Fensterkontakt',
      description: '<p>Wartungsfreier Funksender mit Solarzelle – erkennt sofort, wenn das Fenster geöffnet oder geschlossen wird.</p>',
      image: '',
      productNo: '561.454',
      price: '76.84',
    },
    heatSensor: {
      title: 'OPUS Hitzemelder',
      description: '<p>Erkennt und warnt bei Hitzeentwicklung – Vermeidet Fehlalarme in Räumen mit viel Dampfentwicklung.</p>',
      image: '',
      productNo: '561.457',
      price: '115.99',
    },
    motionSensor: {
      title: 'OPUS Smart Motion Sensor',
      description: '<p>Der smarte Sensor erkennt Bewegung in seiner näheren Umgebung.</p>',
      image: '',
      productNo: '563.051',
      price: '109.04',
    },
    smokeDetector: {
      title: 'OPUS Rauchwarnmelder',
      description: '<p>Erkennt die Entwicklung von Rauch- oder Brandgas. Einbindung in Automationen möglich.</p>',
      image: '',
      productNo: '563.069',
      price: '115.42',
    },
    temperatureSensor: {
      title: 'OPUS Temperatur- und Feuchtesensor',
      description: '<p>Der Temperatur- und Feuchtesensor misst in regelmäßigen Abständen die Umgebungstemperatur sowie den Wert der Luftfeuchtigkeit.</p>',
      image: '',
      productNo: '561.456',
      price: '65.31',
    },
    waterInstantSensors: {
      title: 'OPUS Sofort-Wassermelder',
      description: '<p>Gibt bei Kontakt mit Wasser sofort einen Echtzeit-Warnhinweis auf Ihr Smartphone weiter. Wartungsfrei dank Solarzelle.</p>',
      image: '',
      productNo: '563.067',
      price: '134.22',
    },
    waterSlicesSensors: {
      title: 'OPUS Wassermelder mit Quellscheiben',
      description: '<p>Warnt zuverlässig vor Wasserschäden, auch in völliger Dunkelheit – 100% Energieautark.</p>',
      image: '',
      productNo: '563.068',
      price: '115.90',
    },
    shadingShutterSwitch: {
      title: 'OPUS BRiDGE Rollladen/Jalousie-Schalter',
      description: '<p>Intelligente Rollladensteuerung für Ihren elektrischen Rollladen und Basis für die weitere Vernetzung</p>',
      image: '',
      productNo: '563.031',
      price: '91.64',
    },
    shadingCentralSwitch: {
      title: 'OPUS Zentralsteuerung',
      description: '<p>Ein Schalter für die gesamtheitliche Steuerung Ihrer Wunschfunktion: Alle Lichter aus? Alle Rollläden auf?</p>',
      image: '',
      productNo: 'ABC',
      price: '9.99',
    },
    shadingTubularMotor: {
      title: 'OPUS Rollladenantrieb',
      description: '<p>Der einfachste Weg zu elektrischen und vernetzbaren Rollläden.</p>',
      image: '',
      width: 'Breite',
      height: 'Höhe',
      material: 'Material',
      productNo: '20X052000X0',
      price: '172.27',
    },
  },
};
