<template>
    <MainFrame :has-progress-bar="true"
               :progress-bar-current="progressCurrent"
               :progress-bar-max="progressMax">
        <template v-slot:columnRight>
            <img :src="imageRight" alt="">
        </template>
        <template v-slot:columnLeft>
            <h1 class="a-headline a-headline--1">
                <img :src="iconLightDimmer" alt="">
                {{ $t('subprocess.children.light.children.dimmer.title') }}
            </h1>
            <div v-html="$t('subprocess.children.light.children.dimmer.body')"></div>
            <ProductNoWithPriceRow :product-no="$t('products.lightDimmer.productNo')"
                                   :price="$t('products.lightDimmer.price')"/>

            <div class="m-btn-group m-btn-group--block">
                <button @click.prevent="onSubmit()" class="a-btn a-btn--primary a-btn--big">
                    {{ $t('subprocess.children.light.children.dimmer.actions.yes') }}
                </button>
                <button @click.prevent="onSkipStep()" class="a-btn a-btn--primary a-btn--big">
                    {{ $t('subprocess.children.light.children.dimmer.actions.no') }}
                </button>
                <button @click.prevent="onBack()" class="a-btn a-btn--secondary a-btn--big">
                    {{ $t('global.actions.back') }}
                </button>
            </div>
        </template>
    </MainFrame>
</template>

<script>

import ImageRight from '../../../../../images/pages/light-dimmer.jpg';
import ProgressBar from '../../../components/ProgressBar';
import MainFrame from '../../MainFrame';
import AppIcons from '../../../mixins/app-icons';
import ProductNoWithPriceRow from '../../ProductNoWithPriceRow';

export default {
    name: 'LightDimmer',
    components: {ProductNoWithPriceRow, MainFrame, ProgressBar},
    mixins: [AppIcons],
    props: {
        progressMax: {
            type: Number,
            required: true,
        },
        progressCurrent: {
            type: Number,
            required: true,
            default: 1,
        },
    },
    data() {
        return {
            imageRight: ImageRight,
        };
    },
    methods: {
        onSubmit: function() {
            this.$emit('subprocessLightDimmerAdd');
        },
        onSkipStep: function() {
            this.$emit('subprocessLightDimmerSkip');
        },
        onBack: function() {
            this.$emit('subprocessLightSkip');
        }
    },
};
</script>
