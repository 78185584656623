var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentStep === "tubularMotor"
        ? _c("TubularMotor", {
            attrs: { progressCurrent: 1, progressMax: 3 },
            on: {
              subprocessShadingTubularMotorAdd: function($event) {
                return _vm.addTubularMotor($event)
              },
              subprocessShadingTubularMotorSkip: function($event) {
                return _vm.skipTubularMotor($event)
              },
              subprocessShadingTubularMotorSkipAndExit: function($event) {
                return _vm.skipTubularMotorAndExit($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep === "shutterSwitch"
        ? _c("ShutterSwitch", {
            attrs: {
              progressCurrent: 2,
              progressMax: 3,
              tubularMotorsNumber: _vm.tubularMotors.length
            },
            on: {
              subprocessShadingShutterSwitchAdd: function($event) {
                return _vm.addShutterSwitch($event)
              },
              subprocessShadingShutterSwitchSkip: function($event) {
                return _vm.showCentralSwitchOrStore($event)
              },
              subprocessShadingShutterSwitchSkipAndExit: function($event) {
                return _vm.skipShutterSwitchAndExit($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep === "centralSwitch"
        ? _c("CentralSwitch", {
            attrs: { progressCurrent: 3, progressMax: 3 },
            on: {
              subprocessShadingCentralSwitchAdd: function($event) {
                return _vm.addCentralSwitch($event)
              },
              subprocessShadingCentralSwitchSkip: function($event) {
                return _vm.skipCentralSwitch($event)
              },
              subprocessShadingCentralSwitchSkipAndExit: function($event) {
                return _vm.skipCentralSwitchAndExit($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }