<template>
    <div>
        <TubularMotor v-if="currentStep === 'tubularMotor'"
                      :progressCurrent="1"
                      :progressMax="3"
                      @subprocessShadingTubularMotorAdd="addTubularMotor($event)"
                      @subprocessShadingTubularMotorSkip="skipTubularMotor($event)"
                      @subprocessShadingTubularMotorSkipAndExit="skipTubularMotorAndExit($event)"/>

        <ShutterSwitch v-if="currentStep === 'shutterSwitch'"
                       :progressCurrent="2"
                       :progressMax="3"
                       :tubularMotorsNumber="tubularMotors.length"
                       @subprocessShadingShutterSwitchAdd="addShutterSwitch($event)"
                       @subprocessShadingShutterSwitchSkip="showCentralSwitchOrStore($event)"
                       @subprocessShadingShutterSwitchSkipAndExit="skipShutterSwitchAndExit($event)"/>

        <CentralSwitch v-if="currentStep === 'centralSwitch'"
                       :progressCurrent="3"
                       :progressMax="3"
                       @subprocessShadingCentralSwitchAdd="addCentralSwitch($event)"
                       @subprocessShadingCentralSwitchSkip="skipCentralSwitch($event)"
                       @subprocessShadingCentralSwitchSkipAndExit="skipCentralSwitchAndExit($event)"/>

    </div>
</template>

<script>
import TubularMotor from '../../components/Subprocess/Shading/TubularMotor';
import ShutterSwitch from '../../components/Subprocess/Shading/ShutterSwitch';
import CentralSwitch from '../../components/Subprocess/Shading/CentralSwitch';
import App from '../../mixins/app';
import Notification from '../../components/Notification';

export default {
    name: 'Shading',
    components: {CentralSwitch, ShutterSwitch, TubularMotor},
    mixins: [App],
    data() {
        return {
            currentStep: 'tubularMotor',
            tubularMotors: [],
            shutterSwitches: 0,
            centralSwitches: 0,
        };
    },
    methods: {
        addTubularMotor: function(config) {
            this.tubularMotors = config;
            this.$toast.success({
                component: Notification, props: {
                    content: this.$t('subprocess.children.shading.children.tubularMotor.notifications.added'),
                },
            });
            this.onStoreAndExit();
        },
        skipTubularMotor: function() {
            this.currentStep = 'shutterSwitch';
        },
        addShutterSwitch: function(number) {
            this.shutterSwitches += number;
            this.showCentralSwitchOrStore(number);
            this.$toast.success({
                component: Notification, props: {
                    content: this.$t('subprocess.children.shading.children.shutterSwitch.notifications.added'),
                },
            });
        },
        showCentralSwitchOrStore: function(number) {
            this.shutterSwitches += number;

            if ((this.shutterSwitches + this.tubularMotors.length) > 1) {
                this.currentStep = 'centralSwitch';
                this.$toast.success({
                    component: Notification, props: {
                        content: this.$t('subprocess.children.shading.children.centralSwitch.notifications.added'),
                    },
                });
            } else {
                this.onStoreAndExit();
            }
        },
        addCentralSwitch: function(number) {
            this.centralSwitches += number;

            this.onStoreAndExit();
        },
        skipCentralSwitch: function(number) {
            this.centralSwitches += number;

            this.onStoreAndExit();
        },
        skipTubularMotorAndExit: function(config) {
            this.tubularMotors = config;
            this.onStoreAndExit();
        },
        skipShutterSwitchAndExit: function(number) {
            this.shutterSwitches = number;
            this.onStoreAndExit();
        },
        skipCentralSwitchAndExit: function(number) {
            this.centralSwitches = number;
            this.onStoreAndExit();
        },
        onStoreAndExit: function() {
            this.storeConfiguration().then(() => {
                this.$router.push({name: 'subprocesses'});
            });
        },
        storeConfiguration: function() {
            return this.$store.dispatch('configuration/addSubprocess', {
                type: 'shading',
                room: this.currentRoom.id,
                config: {
                    tubularMotors: this.tubularMotors,
                    shutterSwitches: parseInt(this.shutterSwitches, 10),
                    centralSwitches: parseInt(this.centralSwitches, 10),
                },
            }).then(() => {
                this.$toast.success({
                    component: Notification, props: {
                        content: this.$t('subprocess.children.shading.notifications.saved'),
                    },
                });
            });
        },
    },
};
</script>
