var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    staticClass: "m-form",
                    attrs: { action: "#" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _c("MainFrame", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "columnRight",
                            fn: function() {
                              return [
                                _c("img", {
                                  attrs: { src: _vm.imageRight, alt: "" }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "columnLeft",
                            fn: function() {
                              return [
                                _c(
                                  "h1",
                                  { staticClass: "a-headline a-headline--1" },
                                  [
                                    _c("img", {
                                      attrs: { src: _vm.iconAddRoom, alt: "" }
                                    }),
                                    _vm._v(" "),
                                    _vm.isEditView
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("editRoom.title"))
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("addRoom.title"))
                                          )
                                        ])
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.isEditView
                                  ? _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("addRoom.body")
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  staticClass: "m-form__group",
                                  attrs: {
                                    name: _vm.$t(
                                      "addRoom.form.fields.roomName.label"
                                    ),
                                    rules: "required",
                                    tag: "div"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "m-form__label",
                                                attrs: { for: "roomName" }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [
                                                    _vm._v(
                                                      "                                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "addRoom.form.fields.roomName.label"
                                                          )
                                                        ) +
                                                        "                                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.roomName,
                                                      expression: "roomName"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "m-form__control",
                                                  attrs: {
                                                    id: "roomName",
                                                    type: "text",
                                                    min: "0",
                                                    placeholder: _vm.$t(
                                                      "addRoom.form.fields.roomName.placeholder"
                                                    )
                                                  },
                                                  domProps: {
                                                    value: _vm.roomName
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.roomName =
                                                        $event.target.value
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "m-form__message m-form__message--error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.$t(errors[0])
                                                        ) +
                                                        "\n                                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.rooms && _vm.rooms.length
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "m-form__message m-form__message--info"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addRoom.form.fields.roomName.hint",
                                                                {
                                                                  names: _vm.rooms
                                                                    .map(
                                                                      function(
                                                                        room
                                                                      ) {
                                                                        return room.name
                                                                      }
                                                                    )
                                                                    .join(", ")
                                                                }
                                                              )
                                                            ) +
                                                            "\n                            "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "m-btn-group m-btn-group--left"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "a-btn a-btn--primary",
                                        attrs: { type: "submit" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.$t("addRoom.actions.next")
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.rooms && _vm.rooms.length
                                      ? _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "a-btn a-btn--secondary",
                                            attrs: {
                                              to: { name: "subprocesses" }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "addRoom.actions.cancel"
                                                  )
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }