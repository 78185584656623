var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "form",
              {
                staticClass: "m-form",
                attrs: { action: "#" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.onSubmit)
                  }
                }
              },
              [
                _c("MainFrame", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "columnRight",
                        fn: function() {
                          return [
                            _c("img", {
                              attrs: { src: _vm.imageRight, alt: "" }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "columnLeft",
                        fn: function() {
                          return [
                            _c(
                              "h1",
                              { staticClass: "a-headline a-headline--1" },
                              [_vm._v(_vm._s(_vm.$t("index.title")))]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.$t("index.body"))
                              }
                            }),
                            _vm._v(" "),
                            !_vm.formTermsOfUse
                              ? _c("ValidationProvider", {
                                  staticClass: "m-form__group",
                                  attrs: {
                                    name: _vm.$t(
                                      "index.form.fields.termsOfUse.label"
                                    ),
                                    rules: "required",
                                    tag: "div"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.formTermsOfUse,
                                                  expression: "formTermsOfUse"
                                                }
                                              ],
                                              staticClass: "m-form__control",
                                              attrs: {
                                                id: "formTermsOfUse",
                                                type: "checkbox",
                                                required: ""
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.formTermsOfUse
                                                )
                                                  ? _vm._i(
                                                      _vm.formTermsOfUse,
                                                      null
                                                    ) > -1
                                                  : _vm.formTermsOfUse
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.formTermsOfUse,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.formTermsOfUse = $$a.concat(
                                                          [$$v]
                                                        ))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.formTermsOfUse = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.formTermsOfUse = $$c
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "m-form__label",
                                                attrs: { for: "formTermsOfUse" }
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t(
                                                        "index.form.fields.termsOfUse.label",
                                                        {
                                                          url: _vm.$router.resolve(
                                                            {
                                                              name: "termsOfUse"
                                                            }
                                                          ).href
                                                        }
                                                      )
                                                    )
                                                  }
                                                }),
                                                _vm._v(" "),
                                                errors && errors.length
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "m-form__message m-form__message--error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(errors[0])
                                                            ) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "m-btn-group m-btn-group--left" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "a-btn a-btn--arrow a-btn--wider",
                                    on: {
                                      click: function($event) {
                                        return _vm.onContinue()
                                      }
                                    }
                                  },
                                  [
                                    _vm.hasStoredConfig
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("index.actions.continue")
                                            ) + "\n                        "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "a-icon__arrow-right"
                                            },
                                            [_vm._v(" ")]
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("index.actions.next")
                                            ) + "\n                        "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "a-icon__arrow-right"
                                            },
                                            [_vm._v(" ")]
                                          )
                                        ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.hasStoredConfig
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "a-btn a-btn--secondary a-btn--wider",
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.onReset()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("index.actions.reset")
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "m-form__group",
                              attrs: {
                                name: _vm.$t("index.form.fields.code.label"),
                                rules: "required",
                                tag: "div"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "m-form__label",
                                            attrs: { for: "roomName" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "sr-only" },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "index.form.fields.code.label"
                                                      )
                                                    ) +
                                                    "\n                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.code,
                                                  expression: "code"
                                                }
                                              ],
                                              staticClass: "m-form__control",
                                              attrs: {
                                                id: "roomName",
                                                type: "text",
                                                min: "0",
                                                placeholder: _vm.$t(
                                                  "index.form.fields.code.placeholder"
                                                )
                                              },
                                              domProps: { value: _vm.code },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.code = $event.target.value
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "m-form__message m-form__message--error"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(_vm.$t(errors[0])) +
                                                    "\n                                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "m-btn-group m-btn-group--left" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "a-btn a-btn--upload",
                                    attrs: { type: "submit" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("index.actions.load")) +
                                        "\n                                "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "a-icon__upload" },
                                      [_vm._v(" ")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }