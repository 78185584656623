<template>
    <!--    <div class="m-form__group m-form__group&#45;&#45;prefixed m-form__group&#45;&#45;suffixed ">-->
    <!--        <div class="m-form__prefix">-->
    <!--            <img src="https://picsum.photos/88/88" alt="">-->
    <!--        </div>-->
    <!--        <label class="m-form__label" for="doors">-->
    <!--            <span class="sr-only">{{ $t('subprocess.surveillance.door.form.fields.doors.label') }}</span>-->
    <!--            <input v-model="doors"-->
    <!--                   class="m-form__control"-->
    <!--                   id="doors"-->
    <!--                   type="number"-->
    <!--                   :placeholder="$t('subprocess.surveillance.door.form.fields.doors.placeholder')"/>-->
    <!--            <span v-if="errors && errors.length" class="m-form__message m-form__message&#45;&#45;error">{{ $t(errors[0]) }}</span>-->
    <!--        </label>-->
    <!--        <div class="m-form__suffix">-->
    <!--            <img src="https://picsum.photos/88/88" alt=""-->
    <!--                 :title="$t('subprocess.surveillance.door.form.fields.windows.info')" />-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="m-form__group m-form__group--prefixed m-form__group--suffixed " :class="{'': hasInfo}">
        <div class="m-form__prefix">
            <img src="https://picsum.photos/88/88" alt="">
        </div>
        <label class="m-form__label" for="doors">
            <span class="sr-only">{{ label }}</span>
            <input v-model="value"
                   class="m-form__control"
                   id="doors"
                   :type="type"
                   :placeholder="placeholder"/>
            <span v-if="errors && errors.length" class="m-form__message m-form__message--error">{{ $t(errors[0]) }}</span>
        </label>
        <div v-if="hasInfo" class="m-form__suffix">
            <img src="https://picsum.photos/88/88" alt="" :title="info"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OpusInput',
    props: {
        default: {
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: 'text',
        },
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        hint: {
            type: String,
            required: false,
            default: '',
        },
        info: {
            type: String,
            required: false,
            default: '',
        },
        errors: {
            type: Array,
            required: false,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            value: null,
        };
    },
    computed: {
        hasInfo() {
            return this.info && this.info.length;
        },
    },
    mounted() {
        if (this.default && this.default.length) {
            this.value = this.default;
        }
    },
    watch: {
        value: function(value) {
            this.$emit('changedValue', value);
        },
    },
};
</script>

<style scoped>

</style>
