var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-language-switcher" }, [
    _vm.$i18n.locale === "de"
      ? _c(
          "a",
          {
            staticClass: "m-language-switcher__item",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                return _vm.switchLocale("nl")
              }
            }
          },
          [_vm._v("\n        NL\n    ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$i18n.locale === "nl"
      ? _c(
          "a",
          {
            staticClass: "m-language-switcher__item",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                return _vm.switchLocale("de")
              }
            }
          },
          [_vm._v("\n        DE\n    ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }