<template>
    <div id="myopus-eu">
        <!--        <Slideout menu="#menu" panel="#panel" :toggleSelectors="['.hamburger']" side="right" :padding="360">-->
        <!--            <section id="menu" class="m-off-canvas">-->
        <!--                <nav class="m-off-canvas__nav">-->
        <!--                    <ul class="m-off-canvas__nav-list">-->
        <!--                        <li class="m-off-canvas__nav-item">-->
        <!--                            <a @click.prevent="onSave()" href="#">-->
        <!--                                {{ $t('global.metaMenu.items.save') }}-->
        <!--                            </a>-->
        <!--                        </li>-->
        <!--                    </ul>-->
        <!--                </nav>-->
        <!--            </section>-->
        <!--        </Slideout>-->

        <div id="panel" class="layout">
            <header class="layout__header">

                <div class="layout__container">
                    <div class="layout__header-inner">
                        <div class="layout__logo">
                            <router-link :to="{name: 'index'}">
                                <img :src="logo" alt="Opus Logo">
                            </router-link>
                        </div>

                        <portal-target name="header-headline" class="layout__headline">
                            <router-link :to="{name: 'index'}">
                                {{ $t('global.pageTitle') }}
                            </router-link>
                        </portal-target>

                        <div class="layout__nav">
                            <span v-if="currentCode">
                                {{ $t('saveConfiguration.children.success.code') }} {{ currentCode }}
                                <img :src="iconInfo"
                                     :alt="$t('saveConfiguration.children.success.codeTooltip')"
                                     v-tooltip="{ content:$t('saveConfiguration.children.success.codeTooltip')}"/>
                            </span>
                            <!--                            <button @click="offCanvasActive = !offCanvasActive"-->
                            <!--                                    class="hamburger hamburger&#45;&#45;collapse"-->
                            <!--                                    :class="{'is-active': offCanvasActive}"-->
                            <!--                                    type="button">-->
                            <!--                                <span class="hamburger-box">-->
                            <!--                                    <span class="hamburger-inner"></span>-->
                            <!--                                </span>-->
                            <!--                            </button>-->

                            <LocaleSwitcher />
                        </div>
                    </div>
                </div>
            </header>

            <main class="layout__main">
                <div class="layout__container">
                    <router-view></router-view>
                </div>
            </main>

            <footer class="layout__footer o-footer">
                <div class="o-footer__meta">

                    <div class="layout__container">
                        <div class="o-footer__meta-inner">
                            <div class="o-footer__contact">
                                <ul class="o-footer__contact-list">
                                    <li class="o-footer__contact-list-item">
                                        <span class="a-icon__pin"></span>
                                        {{ $t('global.usp.shipping.label') }}
                                    </li>
                                    <li class="o-footer__contact-list-item">
                                        <a :href="$t('global.usp.phone.href')" class="a-icon__phone">
                                            {{ $t('global.usp.phone.label') }}
                                        </a>
                                    </li>
                                    <li class="o-footer__contact-list-item">
                                        <a :href="$t('global.usp.email.href')" class="a-icon__mail">
                                            {{ $t('global.usp.email.label') }}
                                        </a>
                                    </li>
                                </ul>
                                <div id="footnotes" class="o-footer__footnotes">
                                    <small><sup>* {{ $t('global.footnotes.price') }}</sup></small>
                                </div>
                            </div>
                            <div class="o-footer__social">
                                <ul class="o-footer__social-list">
                                    <li class="o-footer__social-list-item">
                                        <a class="a-icon__social-twitter" :href="$t('global.socialNetworks.twitter.href')" target="_blank">
                                            <span class="sr-only">{{ $t('global.socialNetworks.twitter.label') }}</span>
                                        </a>
                                    </li>
                                    <li class="o-footer__social-list-item">
                                        <a class="a-icon__social-youtube" :href="$t('global.socialNetworks.youtube.href')" target="_blank">
                                            <span class="sr-only">{{ $t('global.socialNetworks.youtube.label') }}</span>
                                        </a>
                                    </li>
                                    <li class="o-footer__social-list-item">
                                        <a class="a-icon__social-instagram" :href="$t('global.socialNetworks.instagram.href')" target="_blank">
                                            <span class="sr-only">{{ $t('global.socialNetworks.instagram.label') }}</span>
                                        </a>
                                    </li>
                                    <li class="o-footer__social-list-item">
                                        <a class="a-icon__social-pinterest" :href="$t('global.socialNetworks.pinterest.href')" target="_blank">
                                            <span class="sr-only">{{ $t('global.socialNetworks.pinterest.label') }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="o-footer__legal">
                    <div class="layout__container">
                        <div class="o-footer__legal-inner">
                            <div>© JÄGER DIREKT {{ currentYear }}</div>
                            <nav class="o-footer__legal-nav">
                                <ul class="o-footer__legal-nav-list">
                                    <li class="o-footer__legal-nav-list-item">
                                        <a :href="$t('global.footerMenu.items.imprint.href')">
                                            {{ $t('global.footerMenu.items.imprint.label') }}
                                        </a>
                                    </li>
                                    <li class="o-footer__legal-nav-list-item">
                                        <a :href="$t('global.footerMenu.items.privacy.href')">
                                            {{ $t('global.footerMenu.items.privacy.label') }}
                                        </a>
                                    </li>
                                    <li class="o-footer__legal-nav-list-item">
                                        <a :href="$router.resolve({name: 'termsOfUse'}).href">
                                            {{ $t('global.footerMenu.items.termsOfUse.label') }}
                                        </a>
                                    </li>
                                    <li v-if="currentCode" class="o-footer__legal-nav-list-item"
                                        v-tooltip="{ content:$t('saveConfiguration.children.success.codeTooltip')}">
                                        {{ $t('saveConfiguration.children.success.code') }} {{ currentCode }}
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import Slideout from 'vue-slideout';
import logo from '../../images/OPUS-logo--blue.svg';
import App from './mixins/app';
import Temperature from './pages/Subprocess/Surveillance/Temperature';
import AppIcons from './mixins/app-icons';
import LocaleSwitcher from "./components/LocaleSwitcher";

export default {
    name: 'App',
    components: {LocaleSwitcher, Temperature, Slideout},
    mixins: [App, AppIcons],
    data() {
        return {
            logo: logo,
            currentYear: new Date().getFullYear(),
            // offCanvasActive: false,
        };
    },
    // methods: {
    //     onSave: function() {
    //         this.apiStoreConfiguration(this.$store.state.configuration).then(() => {
    //             this.offCanvasActive = !this.offCanvasActive;
    //             this.$children[0].slideout.toggle();
    //         });
    //     },
    // },
};
</script>

<style lang="scss">
    //@import "./assets/scss/themes/myopus/normalize";
    //@import "./assets/scss/themes/skeleton/main";
    //@import "./assets/scss/themes/myopus/main";
</style>
