<template>
    <MainFrame :has-divider="false" :has-right-column-slot="false">
        <template v-if="currentRoom" v-slot:columnLeft>
            <h1 class="a-headline a-headline--1">

                <span style="cursor:pointer;" @click="showRoomSelection = !showRoomSelection"
                      href="#">{{ currentRoom.name }}</span>

                <a @click.prevent="onEditRoom(currentRoom)"
                   href="#"
                   class="a-headline__action"
                   v-tooltip="{ content:$t('subprocess.actions.editRoom')}">
                    <img size="50" :src="iconPen" :alt="$t('subprocess.actions.editRoom')">
                    <span class="sr-only">{{ $t('subprocess.actions.editRoom') }}</span>
                </a>

                <a @click.prevent="onDeleteRoom(currentRoom)"
                   href="#"
                   class="a-headline__action"
                   v-tooltip="{ content:$t('subprocess.actions.deleteRoom')}">
                    <img size="50" :src="iconTrash" :alt="$t('subprocess.actions.deleteRoom')">
                    <span class="sr-only">{{ $t('subprocess.actions.deleteRoom') }}</span>
                </a>


                <ul v-show="showRoomSelection" class="m-select">
                    <li class="m-select__item" :class="{'m-select__item--selected': room.id === selectedRoom}"
                        v-for="room in getRooms()" :key="room.id">
                        <a @click.prevent="selectedRoom = room.id; showRoomSelection = !showRoomSelection"
                           class="m-select__link" href="#">
                            {{ room.name }}
                        </a>
                    </li>
                </ul>
            </h1>

            <div class="grid__row">
                <div class="grid_col grid_col--offset-1">
                    <ul class="o-cards o-cards--thirds">
                        <li class="m-card m-card--light" :class="{'m-card--checked': lightCompleted}">
                            <a v-if="lightCompleted"
                               @click.prevent="onResetSubprocess(currentRoom.id, 'light')"
                               class="m-card__reset" href="#"
                               v-tooltip="{ content:$t('subprocess.actions.resetSubprocess')}">
                                <img :src="iconReset" :alt="$t('subprocess.actions.resetSubprocess')">
                                <span class="sr-only">{{ $t('subprocess.actions.resetSubprocess') }}</span>
                            </a>
                            <router-link class="m-card__trigger" :to="{name: 'light'}">
<!--                                <div class="m-card__image">-->
<!--                                    <img :src="iconPlaceholderImageIconDark" alt="">-->
<!--                                </div>-->
                                <div class="m-card__title">
                                    {{ $t('subprocess.actions.subprocessLight') }}
                                </div>
                            </router-link>
                        </li>
                        <li class="m-card m-card--shading" :class="{'m-card--checked': shadingCompleted}">
                            <a v-if="shadingCompleted"
                               @click.prevent="onResetSubprocess(currentRoom.id, 'shading')"
                               class="m-card__reset" href="#"
                               v-tooltip="{ content:$t('subprocess.actions.resetSubprocess')}">
                                <img :src="iconReset" :alt="$t('subprocess.actions.resetSubprocess')">
                                <span class="sr-only">{{ $t('subprocess.actions.resetSubprocess') }}</span>
                            </a>
                            <router-link class="m-card__trigger" :to="{name: 'shading'}">
<!--                                <div class="m-card__image">-->
<!--                                    <img :src="iconPlaceholderImageIconDark" alt="">-->
<!--                                </div>-->
                                <div class="m-card__title">
                                    {{ $t('subprocess.actions.subprocessShading') }}
                                </div>
                            </router-link>
                        </li>
                        <li class="m-card m-card--surveillance" :class="{'m-card--checked': surveillanceCompleted}">
                            <a v-if="surveillanceCompleted"
                               @click.prevent="onResetSubprocess(currentRoom.id, 'surveillance')"
                               class="m-card__reset" href="#"
                               v-tooltip="{ content:$t('subprocess.actions.resetSubprocess')}">
                                <img :src="iconReset" :alt="$t('subprocess.actions.resetSubprocess')">
                                <span class="sr-only">{{ $t('subprocess.actions.resetSubprocess') }}</span>
                            </a>
                            <router-link class="m-card__trigger" :to="{name: 'surveillance'}">
<!--                                <div class="m-card__image">-->
<!--                                    <img :src="iconPlaceholderImageIconDark" alt="">-->
<!--                                </div>-->
                                <div class="m-card__title">
                                    {{ $t('subprocess.actions.subprocessSurveillance') }}
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="m-btn-group m-btn-group--right">
                <router-link :to="{name: 'room'}" class="a-btn a-btn--primary">
                    {{ $t('subprocess.actions.addMoreRooms') }}
                </router-link>
                <router-link :to="{name: 'overview'}" class="a-btn a-btn--primary">
                    {{ $t('subprocess.actions.continue') }}
                </router-link>
            </div>
        </template>
    </MainFrame>
</template>

<script>

import AppIcons from '../mixins/app-icons';
import App from '../mixins/app';
import MainFrame from '../components/MainFrame';

export default {
    name: 'Subprocesses',
    components: {MainFrame},
    mixins: [App, AppIcons],
    data() {
        return {
            showRoomSelection: false,
            subprocesses: [],
            selectedRoom: null,
        };
    },
    computed: {
        lightCompleted: function() {
            let match = this.subprocesses.find(subprocess => {
                return subprocess.type === 'light';
            });

            return !!match;
        },
        shadingCompleted: function() {
            let match = this.subprocesses.find(subprocess => {
                return subprocess.type === 'shading';
            });

            return !!match;
        },
        surveillanceCompleted: function() {
            let match = this.subprocesses.find(subprocess => {
                return subprocess.type.includes('surveillance.');
            });

            return !!match;
        },
    },
    beforeMount() {
        if (this.$route.params.hasOwnProperty('room') && this.$route.params.room && this.$route.params.room.length) {
            this.$store.dispatch('configuration/changeRoom', this.$route.params.room);
        }

        if (!this.$store.getters['configuration/currentRoom']()) {
            this.$router.push({name: 'room'});
        }
        this.fetchSubprocesses();
    },
    mounted() {
        this.selectedRoom = this.currentRoom.id;
    },
    methods: {
        fetchSubprocesses: function() {
            this.subprocesses = this.$store.getters['configuration/currentRoomSubprocessSet']();
        },
        getRooms: function() {
            return this.$store.getters['configuration/roomSet']();

        },
        onResetSubprocess: function(room, type) {
            this.$store.dispatch('configuration/truncateSubprocessByType', {room: room, type: type}).then(() => {
                this.fetchSubprocesses();
            });
        },
        onEditRoom: function(room) {
            this.$router.push({name: 'room', params: {id: room.id}});
        },
        onDeleteRoom: function(room) {
            if (confirm(this.$t('subprocess.confirms.roomDelete', {room: room.name}))) {
                this.$store.dispatch('configuration/deleteRoom', room.id).then(() => {
                    if (!this.currentRoom) {
                        this.$router.push({name: 'room'});
                        return;
                    }
                    this.selectedRoom = this.currentRoom.id;
                    this.$forceUpdate();
                });
            }
        },
    },
    watch: {
        selectedRoom: function(value) {
            this.$store.dispatch('configuration/changeRoom', value);
            this.fetchSubprocesses();
        },
    },
};
</script>
