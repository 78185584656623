<template>

    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" class="m-form" action="#">
                <MainFrame>
                    <template v-slot:columnRight>
                        <img :src="imageRight" alt="">
                    </template>
                    <template v-slot:columnLeft>
                        <h1 class="a-headline a-headline--1">
                            <img :src="iconSurveillanceSmoke" alt="">
                            {{ $t('subprocess.children.surveillance.children.smoke.title') }}
                        </h1>
                        <div v-html="$t('subprocess.children.surveillance.children.smoke.body')"></div>
                        <ProductNoWithPriceRow :product-no="$t('products.smokeDetector.productNo')"
                                               :price="$t('products.smokeDetector.price')"/>


                        <ValidationProvider
                            :name="$t('subprocess.children.surveillance.children.smoke.form.fields.detectors.label')"
                            rules="" v-slot="{ errors }" tag="div"
                            class="m-form__group m-form__group--prefixed m-form__group--suffixed ">
                            <div class="m-form__prefix">
                                <img :src="iconSurveillanceSmokePlus" alt="">
                            </div>
                            <label class="m-form__label" for="detectors">
                                    <span class="sr-only">
                                        {{
                                            $t('subprocess.children.surveillance.children.smoke.form.fields.detectors.label')
                                        }}
                                    </span>
                                <input v-model="detectors"
                                       class="m-form__control"
                                       id="detectors"
                                       type="number"
                                       min="0"
                                       :placeholder="$t('subprocess.children.surveillance.children.smoke.form.fields.detectors.placeholder')"/>
                                <span class="m-form__message m-form__message--error">
                                        {{ $t(errors[0]) }}
                                    </span>
                            </label>
                            <div class="m-form__suffix">
                                <img :src="iconInfo" alt=""
                                     v-tooltip="{ content:$t('subprocess.children.surveillance.children.smoke.form.fields.detectors.info')}"/>
                            </div>
                        </ValidationProvider>

                        <div class="m-btn-group m-btn-group--block">
                            <button type="submit" class="a-btn a-btn--primary  a-btn--big">
                                {{ $t('subprocess.children.surveillance.children.smoke.actions.next') }}
                            </button>
                        </div>
                    </template>
                </MainFrame>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import AppIcons from '../../../mixins/app-icons';
import App from '../../../mixins/app';
import MainFrame from '../../../components/MainFrame';
import Notification from '../../../components/Notification';
import ProductNoWithPriceRow from '../../../components/ProductNoWithPriceRow';
import ImageRight from "../../../../../images/pages/surveillance-smoke.jpg";

export default {
    name: 'Smoke',
    components: {
        ProductNoWithPriceRow,
        MainFrame,
        ValidationObserver,
        ValidationProvider,
    },
    mixins: [App, AppIcons],
    data() {
        return {
            imageRight: ImageRight,
            currentValue: 0,
            detectors: null,
        };
    },
    computed: {
        currentRoom() {
            return this.$store.getters['configuration/currentRoom']();
        },
        currentState() {
            return this.$store.getters['configuration/currentRoomUniqueSubprocessByType']('surveillance.smoke');
        },
    },
    beforeMount() {
        if (this.currentState) {
            this.detectors = parseInt(this.currentState.config.detectors, 10);
            this.currentValue  = this.detectors;
        }
    },
    methods: {
        onSubmit: function() {
            const detectors = parseInt(this.detectors, 10);

            this.$store.dispatch('configuration/addSubprocess', {
                type: 'surveillance.smoke',
                room: this.currentRoom.id,
                config: {
                    detectors: detectors,
                },
            }).then(() => {
                this.adddedOrRemovedProductNotification(
                    this.currentValue,
                    detectors,
                    this.$t('subprocess.children.surveillance.children.smoke.notifications.added'),
                    this.$t('subprocess.children.surveillance.children.smoke.notifications.removed'),
                    {name: 'surveillance'}
                );
            });
        },
    },
};
</script>
