import Notification from '../components/Notification';

const App = {
  components: {Notification},
  data() {
    return {};
  },
  computed: {
    rooms: function() {
      return this.$store.getters['configuration/roomSet']();
    },
    currentCode: function() {
      return this.$store.getters['configuration/code']();
    },
    currentRoom: function() {
      return this.$store.getters['configuration/currentRoom']();
    },
  },
  beforeMount() {
    this.$store.dispatch('configuration/refreshCurrentRoom');
  },
  created() {
    const syncMutations = [
      'configuration/addRoom',
      'configuration/addSubprocess',
      'configuration/addUniqueSubprocess',
      'configuration/deleteSubprocessesByType',
      'configuration/deleteRoom',
      'configuration/setPreconfigureOptions',
      'configuration/setControlSystems',
    ];

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (syncMutations.includes(mutation.type) &&
          Object.values(state.configuration.rooms).length) {
        this.apiStoreConfiguration(state.configuration);
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    adddedOrRemovedProductNotification: function(
        storedValue, newValue, addedMessage, removedMessage,
        redirectRoute = null) {
      if (newValue === storedValue) {
        if (redirectRoute) {
          this.$router.push(redirectRoute);
        }
        return;
      }

      this.$toast.success({
        component: Notification,
        props: {
          content: newValue > storedValue ? addedMessage : removedMessage,
        },
      });

      if (redirectRoute) {
        this.$router.push({name: 'surveillance'});
      }
    },
    apiStoreConfiguration: function(config) {
      let params = {
        config: config,
      };

      if (config.code) {
        params.code = config.code;
      }

      return this.$http.post(`${process.env.VUE_APP_OPUS_DOMAIN}/api/v1/store`, params).then(response => {
        if (response.status === 200) {
          this.$store.dispatch('configuration/setCode', response.data.code);
        }
      });
    },
    roundPrice: function(number) {
      return (Math.round((number + Number.EPSILON) * 100) / 100);
    },
    getProductsForRoom: function(room) {
      return this.$store.getters['configuration/lockupDevicesByConfiguration'](room);
    },
    fetchDevices: function() {
      return this.rooms.map(room => {
        let sum = 0;
        const productsForRoom = this.getProductsForRoom(room.id);
        productsForRoom.map(device => {
          let devicePriceTransKey = 'products.shadingTubularMotor.price';
          if (device.name.includes('shadingTubularMotor--')) {
            device.translatedName = this.$t('products.shadingTubularMotor.title');
            device.productNo = this.$t('products.shadingTubularMotor.productNo');
            let options = [
              `${this.$t('products.shadingTubularMotor.width')}: ${device.config.windowWidth} mm`,
              `${this.$t('products.shadingTubularMotor.height')}: ${device.config.windowHeight} mm`,
              `${this.$t('products.shadingTubularMotor.material')}: ${this.$t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.options.'+device.config.shutterMaterial)}`,
            ]
            device.translatedName = `${device.translatedName} (${options.join(', ')})`;
            device.translatedDescription = this.$t('products.shadingTubularMotor.description');
          } else {
            devicePriceTransKey = 'products.' + device.name + '.price';
            device.translatedName = this.$t('products.' + device.name + '.title');
            device.translatedDescription = this.$t('products.' + device.name + '.description');
            device.productNo = this.$t('products.' + device.name + '.productNo');
          }

          device.price = this.$t(devicePriceTransKey);
          device.sum = device.price;


          if(!Number.isNaN(parseFloat(device.price))) {
            device.price = this.roundPrice(parseFloat(device.price));
            device.sum = this.roundPrice(device.quantity * device.price);

            sum += device.sum;
            device.price = device.price.toFixed(2) +' EUR';
            device.sum = device.sum.toFixed(2) +' EUR';
          }

          if (!device.hasOwnProperty('currentQuantity')) {
            device.currentQuantity = device.quantity;
          }
        });

        return {room: room, devices: productsForRoom, sum: sum.toFixed(2)};
      });
    },
  },
};

export default App;
