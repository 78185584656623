<template>
    <div class="m-language-switcher">
        <a v-if="$i18n.locale === 'de'"
           @click="switchLocale('nl')"
           class="m-language-switcher__item"
           href="#">
            NL
        </a>

        <a v-if="$i18n.locale === 'nl'"
           @click="switchLocale('de')"
           class="m-language-switcher__item"
           href="#">
            DE
        </a>
    </div>
</template>

<script>
export default {
    name: "LocaleSwitcher",
    data() {
        return {
        }
    },
    methods: {
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                const params = this.$route.params;
                params.locale = locale;
                this.$router.push({name: this.$route.name, params: params})
            }
        }
    },
}
</script>

<style scoped>

</style>
