import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import PortalVue from 'portal-vue';
import VTooltip from 'v-tooltip';
import router from './app/router';
import store from './app/store';
import i18n from './app/i18n';
import App from './app/App';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

let axiosInstance = axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

Vue.use(Toast, {
  icon: false,
  showCloseButtonOnHover: true,
});
Vue.use(VueAxios, axiosInstance);
Vue.use(PortalVue);
Vue.use(VTooltip);

Vue.config.productionTip = true;
new Vue({
  router,
  store: store,
  i18n,
  render: h => h(App),
}).$mount('#app');

