export default {
  global: {
    pageTitle: 'Configurator',
    metaMenu: {
      items: {
        save: 'Opslaan',
      },
    },
    socialNetworks: {
      twitter: {
        label: 'Twitter',
        href: 'https://twitter.com/DiscoverOpus',
      },
      youtube: {
        label: 'YouTube',
        href: 'https://www.youtube.com/channel/UC5hU8LMoL4gRCCaanf533Hw',
      },
      instagram: {
        label: 'Instagram',
        href: 'https://www.instagram.com/discoveropus/',
      },
      pinterest: {
        label: 'Pinterest',
        href: 'https://www.pinterest.de/discoveropus/',
      },
      // linkedin: {
      //   label: 'LinkedIn',
      //   href: '',
      // },
      // facebook: {
      //   label: 'Facebook',
      //   href: '',
      // },
    },
    footerMenu: {
      items: {
        imprint: {
          href: 'https://myopus.eu/nl/impressum',
          label: 'Impressum',
        },
        privacy: {
          href: 'https://myopus.eu/nl/gegevensbescherming',
          label: 'Gegevensbescherming',
        },
        termsOfUse: {
          label: 'Gebruiksvoorwaarden',
        },
      },
    },
    forms: {
      errors: {
        required: 'Gelieve dit veld in te vullen.',
      },
    },
    usp: {
      shipping: {
        href: '',
        label: 'Gratis verzending in Nederland vanaf 99,- €',
      },
      phone: {
        href: 'tel:+31165 380490',
        label: '+31165 380490',
      },
      email: {
        href: 'mailto:service@myopus.eu',
        label: 'service@myopus.eu',
      },
    },
    actions: {
      edit: 'Bewerken',
      delete: 'Delete',
      back: 'Terug',
      complete: 'Configurator afsluiten'
    },
    attributes: {
      productNo: 'Artikel-Nr.:',
      priceFrom: 'Prijs: vanaf',
      allRoundPrice: 'Totaal prijs',
      unitPrice: 'Prijs per stuk',
      sum: 'Totaal',
    },
    footnotes: {
      price: 'Niet-bindende prijsindicatie incl. de respectieve geldige wettelijke omzetbelasting.',
    }
  },
  index: {
    title: 'Hartelijk welkom!',
    body: '<p>Configureer uw OPUS Smarthome ruimte voor ruimte - precies volgens uw wensen. U kunt ook een reeds gemaakte configuratie uploaden en daarin verder werken.</p>',
    form: {
      fields: {
        termsOfUse: {
          label: 'Ik heb kennis genomen van de <a href="{url}" target="_blank">gebruiksvoorwaarden</a>.',
        },
        code: {
          label: 'Code',
          placeholder: 'Voer hier de code in om een reeds bestaande configuratie te laden.',
        },
      },
    },
    actions: {
      next: 'Start',
      continue: 'Ga door',
      reset: 'Begin opnieuw',
      load: 'Laden',
    },
    notifications: {
      configurationReseted: 'Uw configuratie is gereset',
      configurationLoaded: 'Uw configuratie is met succes geladen',
      configurationFailed: 'Er kon geen configuratie gevonden worden onder de opgegeven code.',
    },
  },
  editRoom: {
    title: 'Hernoem ruimte.',
  },
  addRoom: {
    title: 'Welke ruimte wilt u inrichten?',
    body: '<p>Voordat u begint, dient u aan te geven welke ruimte u Smart wilt regelen met uw OPUS componenten. Meerdere ruimtes zullen na elkaar worden ingericht. Geef de ruimtes namen, zodat u altijd uw ruimtes kunt toewijzen.</p>',
    form: {
      fields: {
        roomName: {
          label: 'Naam van de ruimte',
          placeholder: 'Vul hier de naam in, bijv. Woonkamer',
          hint: 'Al toegevoegd: {namen}',
        },
      },
    },
    actions: {
      cancel: 'Overslaan',
      next: 'Volgende',
    },
    notifications: {
      added: 'Nieuwe ruimte wordt toegevoegd',
      edited: 'Ruimte werd hernoemd',
    },
  },
  subprocess: {
    actions: {
      addMoreRooms: 'Een andere ruimte toevoegen',
      editRoom: 'Bewerken',
      deleteRoom: 'Delete',
      resetSubprocess: 'Reset',
      continue: 'Configurator afsluiten',
      subprocessLight: 'Verlichting',
      subprocessShading: 'Zonwering',
      subprocessSurveillance: 'Toezicht',
    },
    confirms: {
      roomDelete: 'Weet u zeker dat u de ruimte "{room}" wilt verwijderen?'
    },
    children: {
      light: {
        notifications: {
          saved: 'Verlichtingsproces is opgeslagen',
        },
        children: {
          dimmer: {
            title: 'Wilt u een dimmer toevoegen?',
            body: '<p>De OPUS BRiDGE universele dimmer zorgt voor de door u gewenste verlichting en kan universeel worden gebruikt voor een breed scala aan lichtbronnen.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nee',
            },
            notifications: {
              added: 'OPUS BRiDGE Dimmer toegevoegd',
            },
          },
          doubleRockerSwitch: {
            title: 'Welke lichtregeling heeft u nodig?',
            bodyBridge1: '<p>Wilt u de verlichting in de keuken aan doen, maar de verlichting in de direct aangrenzende eetkamer uit laten? Voeg dan de OPUS BRiDGE 2-kanaals verlichting toe aan die kamer. Hiermee kunt u twee verlichtingsgroepen onafhankelijk van elkaar bedienen. Tevens is hij voorzien van een dubbele serieschakelaar.</p>',
            bodyBridge2: '<p>Is er maar één verlichtingsgroep in de kamer? Dan is de OPUS BRiDGE 1-kanaal verlichting voldoende voor bediening met een enkele wip. </p>',
            body: '<p>U wilt de verlichting in de keuken aan doen, maar u wilt dat de verlichting in de direct aangrenzende eetkamer uit blijft? Klik op "Ja".<br>De OPUS BRiDGE 2-kanaals verlichting met dubbele wip stelt u in staat twee verlichtingsgroepen onafhankelijk te bedienen.</p>',
            actions: {
              yes: 'OPUS BRiDGE 2-kanaals verlichting',
              no: 'OPUS BRiDGE 1-kanaal velichting',
            },
            notifications: {
              yes: 'OPUS BRiDGE 2-kanaals verlichting met dubbele wip toegevoegd',
              no: 'OPUS BRiDGE 1-kanaal verlichting is toegevoegd',
            },
          },
          wirelessSwitch: {
            title: 'Wilt u een extra schakelaar toevoegen?',
            body: '<p>Creëer een extra bedieningsmogelijkheid met de draad- en batterijloze OPUS wandzender. Totale flexibiliteit, want de wandzender kan overal worden geplaatst.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nee',
            },
            notifications: {
              added: 'OPUS Wandzender toegevoegd',
            },
          },
          repeat: {
            title: 'Wilt u nog een schakelaar in deze kamer toevoegen?',
            body: '<p>Heeft u in deze kamer nog een schakelaar nodig, b.v. voor de lichtregeling van een extra lichtkring? Klik dan op "Ja." Heb je aan alles gedacht? Klik dan op “Nee”.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nee',
            },
          },
        },
      },
      shading: {
        notifications: {
          saved: 'zonweringproces is opgeslagen',
        },
        children: {
          landingpage: {
            title: 'Heeft u elektrische rolluiken?',
            body: '<p>Bestuurt u uw rolluiken of jaloezieën al elektrisch via een rolluikaandrijving? Zo ja, klik dan op "Ja." Zo niet, klik dan op “Nee”.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nee',
            },
          },
          tubularMotor: {
            title: 'Heeft u elektrische rolluiken?',
            body: '<p>Bestuurt u uw rolluiken of jaloezieën al elektrisch via een rolluikaandrijving? Zo ja, klik dan op "Ja." Zo niet, klik dan op “Nee”.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nee',
            },
            notifications: {
              added: 'De afmetingen van de ramen en het materiaal van de rolluiken zijn genoteerd.',
            },
            children: {
              options: {
                title: 'Elektrisch & Smart: voeg een OPUS rolluikaandrijving toe.',
                body: '<p>Als u geen elektrische rolluiken heeft, kunt u ze achteraf uitrusten met de OPUS rolluikaandrijving. Zo slaat u twee vliegen in één klap, want de rolluikaandrijving is elektrisch en eenvoudig in een netwerk op te nemen - zonder een extra rolluikschakelaar.</p><p>Vul onderstaande formulier in voor elk raam in de kamer dat u elektrisch en Smart wilt bedienen.</p>',
                form: {
                  fields: {
                    width: {
                      label: 'Vensterbreedte',
                      placeholder: 'Voer de breedte van het venster in mm in.',
                    },
                    height: {
                      label: 'Vensterhoogte',
                      placeholder: 'Voer de hoogte van het venster in mm in.',
                    },
                    material: {
                      label: 'Materiaal',
                      placeholder: 'Selecteer het materiaal van uw rolluiken.',
                      options: {
                        wood: 'Hout',
                        plastic: 'Kunststof',
                        aluminium: 'Aluminium',
                      },
                    }
                  }
                },
                  actions: {
                    cancel: 'Overslaan',
                    next: 'Volgende',
                  },
              },
              repeat: {
                title: 'Wilt u meer OPUS rolluikaandrijvingen toevoegen?',
                body: '<p>Heeft u een OPUS rolluikaandrijving toegevoegd voor elk raam? Kies dan "Ja." Weet je het niet zeker? Waarom tel je het niet nog een keer na. </p>',
                actions: {
                  yes: 'Ja',
                  no: 'Nee',
                },
              },
            },
          },
          shutterSwitch: {
            title: 'Wilt u een Smart rolluikschakelaar toevoegen?',
            body: '<p>U heeft al elektrische rolluiken of jaloezieën. Als u uw rolluiken Smart wilt sturen, voeg dan een OPUS BRiDGE rolluik-/jaloezieschakelaar toe per raam met een rolluik en neem ze flexibel op in scènes en automatiseringen. Bijvoorbeeld: bij zonsopgang gaan de rolluiken automatisch open en bij zonsondergang gaan ze dicht. Centrale controle is ook mogelijk.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nee',
            },
            notifications: {
              added: 'OPUS BRiDGE rolluik-/jaloezieschakelaar toegevoegd.',
            },
            children: {
              repeat: {
                title: 'Wilt u meer Smart rolluikschakelaars toevoegen?',
                body: 'Heeft u een OPUS BRiDGE rolluik-/jaloezieschakelaar toegevoegd voor elk raam met een elektrisch rolluik? Selecteer dan "Ja". Weet je het niet zeker? Waarom tel je niet opnieuw? ',
                actions: {
                  yes: 'Ja',
                  no: 'Nee',
                },
              },
            },
          },
          centralSwitch: {
            title: 'Alle rolluiken/jaloezieën tegelijk bedienen?',
            body: '<p>Door de centrale bediening te activeren, kunt u alle rolluiken en jaloezieën gelijktijdig openen of sluiten. Maak nuttige automatiseringen, zodat bijvoorbeeld alle rolluiken elke dag om 20:00 uur automatisch worden gesloten.</p>',
            actions: {
              yes: 'Ja',
              no: 'Nee',
            },
            notifications: {
              added: 'OPUS centrale besturing toegevoegd',
            },
          },
        },
      },
      surveillance: {
        title: 'Veiligheidsfuncties',
        body: '<p>Veiligheid staat voorop met de veiligheidsfuncties voor uw OPUS Smarthome: opties variëren van waarschuwingen voor dreigende waterschade tot rookalarmen en warmte- of temperatuurbewaking tot bescherming tegen diefstal door het controleren van ramen en deuren.</p>',
        cards: {
          door: {title: 'Ramen en deuren bewaken?'},
          heat: {title: 'Hitte detecteren?'},
          motion: {title: 'Beweging detecteren?'},
          smoke: {title: 'Rook detecteren?'},
          temperature: {title: 'Temperatuur regelen?'},
          water: {title: 'Waterschade voorkomen?'},
          exit: {title: 'Voltooi de configuratie van de veiligheidsfuncties'},
        },
        children: {
          door: {
            title: 'Voer het aantal ramen en deuren in.',
            body: '<p>Geen open deur of raam zal onopgemerkt blijven, want met deze veiligheidsfunctie zal uw OPUS Smarthome detecteren of ze open of gesloten zijn.  Geef aan hoeveel ramen en deuren u wilt beveiligen.</p>',
            form: {
              fields: {
                doors: {
                  label: 'Aantal deuren',
                  placeholder: 'Gelieve het aantal deuren op te geven.',
                  info: 'Voor dubbele schuiframen zijn 2 contacten of 2 venstergrepen nodig.',
                },
                windows: {
                  label: 'Aantal ramen',
                  placeholder: 'Gelieve het aantal ramen op te geven.',
                },
              },
            },
            actions: {
              next: 'Volgende',
            },
            notifications: {
              added: 'OPUS deur-/venstercontact toegevoegd. ',
              removed: 'OPUS deur-/venstercontact verwijderd.',
            },
          },
          heat: {
            title: 'Geef het aantal gewenste warmtemelders op.',
            body: '<p>De OPUS warmtemelder wordt gebruikt om hitte van vuur te detecteren in woonruimten waar rookmelders niet kunnen worden gebruikt wegens mogelijke valse alarmen. Dit kan bijvoorbeeld het geval zijn in keukens of badkamers waar grote hoeveelheden stoom en rook worden verwacht.</p>',
            form: {
              fields: {
                sensors: {
                  label: 'Aantal warmtemelders',
                  placeholder: 'Gelieve het aantal warmtemelders op te geven.',
                  info: '',
                },
              },
            },
            actions: {
              next: 'Volgende',
            },
            notifications: {
              added: 'OPUS warmtemelder toegevoegd.',
              removed: 'OPUS warmtemelder verwijderd.',
            },
          },
          motion: {
            title: 'Voer het aantal bewegingsmelders in.',
            body: '<p>De OPUS Smart Motion Sensor kan multifunctioneel worden gebruikt: hij regelt het licht afhankelijk van de beweging, maar kan ook de omgevingstemperatuur en de waarde van de luchtvochtigheid meten en de bijbehorende automatiseringen activeren.</p>',
            form: {
              fields: {
                sensors: {
                  label: 'Aantal bewegingsmelders',
                  placeholder: 'Gelieve het aantal gewenste bewegingsmelders op te geven.',
                  info: '',
                },
              },
            },
            actions: {
              next: 'Volgende',
            },
            notifications: {
              added: 'OPUS Smart Motion Sensor toegevoegd',
              removed: 'OPUS Smart Motion Sensor verwijderd',
            },
          },
          smoke: {
            title: 'Geef het aantal gewenste rookmelders aan.',
            body: '<p>De OPUS rookmelders detecteren de ontwikkeling van rook of brandgas en waarschuwen betrouwbaar met een luide sirene of via een push-notificatie naar uw mobiele apparaat. Integreer uw rookmelders in intelligente automatiseringen: als het alarm afgaat, gaan de lichten aan en de rolluiken open.</p>',
            form: {
              fields: {
                detectors: {
                  label: 'Aantal rookmelders',
                  placeholder: 'Gelieve het aantal gewenste rookmelders in te vullen.',
                  info: 'Let op: u heeft minstens één rookmelder nodig in elke kinderkamer, in elke slaapkamer en in elke gang die in verbinding staat met gemeenschappelijke ruimtes.',
                },
              },
            },
            actions: {
              next: 'Volgende',
            },
            notifications: {
              added: 'OPUS rookmelder toegevoegd.',
              removed: 'OPUS rookmelder verwijderd',
            },
          },
          temperature: {
            title: 'Geef het aantal temperatuursensoren op dat u nodig heeft.',
            body: '<p>De OPUS temperatuur- en vochtigheidssensor meet met regelmatige tussenpozen de omgevingstemperatuur en de vochtigheidswaarde. Bij opvallende meetgegevens stelt hij u voor te ventileren, bijv. via een pushbericht, of past hij, in combinatie met een verwarmingsregeling, de temperatuur van de ruimte automatisch aan.</p>',
            form: {
              fields: {
                sensors: {
                  label: 'Aantal temperatuursensoren',
                  placeholder: 'Gelieve het aantal gewenste temperatuursensoren op te geven.',
                  info: '',
                },
              },
            },
            actions: {
              next: 'Volgende',
            },
            notifications: {
              added: 'OPUS temperatuur- en vochtigheidssensor toegevoegd',
              removed: 'OPUS temperatuur- en vochtigheidssensor verwijderd',
            },
          },
          water: {
            title: 'Welke watersensor wilt u toevoegen?',
            body: '<p>Watersensor: bescherming en vroegtijdige opsporing van waterschade. De OPUS instantwatersensor is geschikt voor makkelijk toegankelijke plaatsen met licht inval. De OPUS watersensor met veerschijven is energie-zelfvoorzienend en werkt zelfs in absolute duisternis.</p>',
            actions: {
              instant: 'OPUS instantwatersensor',
              slices: 'OPUS watersensor met veerschijven',
            },
            children: {
              instant: {
                title: 'Gelieve het aantal gewenste watersensoren op te geven.',
                body: '<p>U heeft gekozen voor de OPUS instantwatersensor. De instantwatersensor stuurt eventuele waterophopingen naar uw mobiele apparaat en geeft daarnaast ook direct de omgevingstemperatuur door.</p>',
                form: {
                  fields: {
                    sensors: {
                      label: 'Aantal instantwatersensoren',
                      placeholder: 'Gelieve het aantal instantwatersensoren op te geven.',
                      info: '',
                    },
                  },
                },
                actions: {
                  next: 'Volgende',
                },
                notifications: {
                  added: 'OPUS instantwatersensor toegevoegd',
                  removed: 'OPUS instantwatersensor verwijderd',
                },
              },
              slices: {
                title: 'Geef het aantal watersensoren met veerschijven aan',
                body: '<p>U heeft gekozen voor de OPUS watersensor met veerschijven. De watersensor meldt waterophopingen op betrouwbare wijze en werkt zonder extra stroombron, zelfs als hij in het donker tussen waterleidingen of onder wasmachines wordt geplaatst.</p>',
                form: {
                  fields: {
                    sensors: {
                      label: 'Aantal watersensoren met veerschijven',
                      placeholder: 'Gelieve het aantal watersensoren met veerschijven op te geven.',
                      info: '',
                    },
                  },
                },
                actions: {
                  next: 'Volgende',
                },
                notifications: {
                  added: 'OPUS watersensor met veerschijven toegevoegd.',
                  removed: 'OPUS watersensor met veerschijven verwijderd.',
                },
              },
            },
          },
        },
      },
    },
  },
  controlSystem: {
    title: 'Hoe wilt u uw OPUS Smarthome bedienen?',
    body: '<p>Of het nu Apple HomeKit, Amazon Alexa of Google Home is - met OPUS bedient u uw Smarthome precies zoals u dat wilt via uw smartphone, tablet, smartwatch of via spraakbesturing - vanuit huis en onderweg!</p><p>Geen behoefte aan Apps of mobiele apparaten. Klik dan op "softwareloze besturing".</p>',
    actions: {
      amazonAlexa: 'Amazon Alexa',
      appleHomeKit: 'Apple HomeKit',
      googleHome: 'Google Home',
      none: 'Softwareloze besturing',
    },
  },
  overview: {
    titleSelectedControlSystem: 'Geselecteerde OPUS Smarthome bediening',
    tooltips: {
      deleteAllDevices: 'Verwijder alle producten van het type "{device}" ({aantal}) uit kamer "{room}"".'
    },
    notifications: {
      deviceDeleted: '{device} ({quantity}) is verwijderd.',
    },
  },
  preconfigure: {
    title: 'Wilt u dat wij uw OPUS Smarthome vooraf voor u installeren?',
    body: '<p>Gebruik onze pre-configuratie service en wij leveren uw Smarthome componenten bij u thuis, klaar voor installatie.<br>Zeker als u uitgebreide plannen heeft, bespaart de pre-configuratie service u kostbare tijd tijdens de installatie.</p>',
    actions: {
      yes: 'Ja',
      no: 'Nee',
    },
  },
  saveConfiguration: {
    title: 'Wilt u uw configuratie opslaan?',
    body: '<p>Sla uw configuratie op, zodat er geen instellingen verloren gaan. Bewerken is daarna op elk moment mogelijk.</p>',
    actions: {
      yes: 'Ja',
      no: 'Nee',
    },
    children: {
      success: {
        title: 'Opgeslagen',
        body: '<p>Bedankt! Uw configuratie is succesvol opgeslagen.</p>',
        code: 'Uw Code:',
        codeTooltip: 'U kunt deze code gebruiken om de actuele stand van uw configuratie op een later tijdstip op te roepen of te delen.',
        goBack: 'Terug naar het begin.',
        consultation: 'Afsrpaak inplannen',
        offer: 'Offerte',
      },
      contact: {
        title: {
          offer: 'We willen u graag een offerte aanbieden.',
          consultation: 'Wenst u een afspraak in te plannen?',
        },
        form: {
          fields: {
            name: {
              label: 'Naam',
              placeholder: 'Gelieve uw naam in te vullen.',
              info: '',
            },
            email: {
              label: 'E-mail adres',
              placeholder: 'Gelieve uw e-mail adres in te vullen.',
              info: '',
            },
            phone: {
              label: 'Telefoonnummer',
              placeholder: 'Gelieve uw telefoonnummer in te vullen.',
              info: '',
            },
            privacyPolicy: {
              label: 'Ik ga akkoord met het <a href="{url}" target="_blank">privacybeleid</a> van JÄGER DIREKT.',
              info: '',
            },
          }
        },
        actions: {
          send: 'Verstuur',
        },
        notifications: {
          sendSuccess: 'Uw verzoek is succesvol verzonden.',
          sendError: 'Er is een fout opgetreden tijdens het verzenden. Probeer het later nog eens.'
        }
      }
    },
  },
  privacyPolicy: {
    title:'Privacybeleid',
    body: '<p></p>'
  },
  termsOfUse: {
    title:'Gebruiksvoorwaarden',
    body: '<p>Wij willen u erop wijzen dat de hieronder getoonde producten en prijzen slechts ter informatie dienen en <strong>geen bindend</strong> aanbod vormen om een contract te sluiten. Er is geen mogelijkheid om producten bindend te bestellen in de configurator.</p><p>Met het voltooien van de configuratie heeft u de mogelijkheid om een niet-bindende offerte aan te vragen. Daartoe dient u de nodige contactgegevens achter te laten.</p><p>De <strong>prijzen die in het kader van de configuratie worden genoemd, zijn bedoeld als richtwaarde ter oriëntatie en vormen derhalve prijsindicatoren</strong>. Zij vormen geen bindend aanbod. Wij wijzen er uitdrukkelijk op dat de in de definitief aangevraagde offerte vermelde prijzen in individuele gevallen kunnen afwijken van de tijdens de configuratie vastgestelde prijzen.</p><p>Alle in het kader van de configuratie vermelde prijzen zijn inclusief de geldende wettelijke BTW.</p><p>De in de configurator vermelde prijzen zijn de eventueel gemaakte kosten voor verpakking en verzending niet inbegrepen. De hoogte van de verzendkosten die in detail van toepassing zijn, kunt u vinden in de aangevraagde offerte, indien van toepassing.</p><p>Wij behouden ons het recht voor om alle aangeboden producten zonder opgaaf van redenen uit de configurator en/of de aangevraagde offerte te verwijderen.</p><p>Er kan helaas geen garantie worden gegeven dat de geconfigureerde producten beschikbaar en/of leverbaar zijn op het moment dat de offerte wordt verkregen.</p>'
  },
  products: {
    controlSystems: {
      amazonAlexa: {
        title: 'Amazon Alexa',
        description: '<p>Intuïtieve bediening, compatibele apparaten en bijna onuitputtelijke mogelijkheden in de OPUS Smarthome.</p>',
      },
      appleHomeKit: {
        title: 'Apple HomeKit',
        description: '<p>Uw OPUS Smarthome luistert naar u en is ook compatibel met uw favoriete Apple functies.</p>',
      },
      googleHome: {
        title: 'Google Home',
        description: '<p>U kunt de OPUS Smarthome via Google Home bedienen met vele Smarthome apparaten zoals verlichting, camera\'s en thermostaten.</p>',
      },
      none: {
        title: 'Softwareloze besturing',
        description: '<p>Uw OPUS apparaten zijn slim in een netwerk opgenomen, zelfs zonder software. Uitbreidingen zijn flexibel mogelijk - zelfs met terugwerkende kracht!</p>',
      },
    },
    lightDimmer: {
      title: 'OPUS BRiDGE dimmer',
      description: '<p>Flikkervrij en geruisloos - Smart universele dimmer. Basis voor verdere netwerken.</p>',
      image: '',
      productNo: '563.076',
      price: '107.00',
    },
    lightDoubleRockerSwitch: {
      title: 'OPUS BRiDGE 2-kanaals verlichting',
      description: '<p>Smart lichtschakelaar voor het regelen van twee onafhankelijke verlichtingsgroepen. Basis voor verdere netwerken.</p>',
      image: '',
      productNo: '563.020',
      price: '91.64',
    },
    lightSingleRockerSwitch: {
      title: 'OPUS BRiDGE 1-kanaal verlichting',
      description: '<p>Smart lichtschakelaar voor het regelen van twee onafhankelijke verlichtingsgroepen. Basis voor verdere netwerken.</p>',
      image: '',
      productNo: '563.010',
      price: '87.00',
    },
    lightWirelessSwitch: {
      title: 'OPUS wandzender',
      description: '<p>Vrij te plaatsen batterij- en draadloze wandzender die zijn eigen stroom levert.</p>',
      image: '',
      productNo: '514.441',
      price: '49.54',
    },
    doorSensor: {
      title: 'OPUS deurcontact',
      description: '<p>Onderhoudsvrije draadlozezender met zonnecel - detecteert onmiddellijk wanneer de deur wordt geopend of gesloten.</p>',
      image: '',
      productNo: '561.454',
      price: '76.84',
    },
    windowSensor: {
      title: 'OPUS venstercontact',
      description: '<p>Onderhoudsvrije draadlozezender met zonnecel - detecteert onmiddellijk wanneer het raam wordt geopend of gesloten.</p>',
      image: '',
      productNo: '561.454',
      price: '76.84',
    },
    heatSensor: {
      title: 'OPUS hittedetector',
      description: '<p>Detecteert en waarschuwt wanneer warmte wordt gegenereerd - Vermijdt vals alarm in kamers met veel stoomontwikkeling.</p>',
      image: '',
      productNo: '561.457',
      price: '115.99',
    },
    motionSensor: {
      title: 'OPUS Smart Motion Sensor',
      description: '<p>De Smart Motion Sensor detecteert beweging in zijn onmiddellijke omgeving.</p>',
      image: '',
      productNo: '563.051',
      price: '109.04',
    },
    smokeDetector: {
      title: 'OPUS Rookmelder',
      description: '<p>Detecteert de ontwikkeling van rook of brandgas. Integratie in automatiseringen mogelijk.</p>',
      image: '',
      productNo: '563.069',
      price: '115.42',
    },
    temperatureSensor: {
      title: 'OPUS temperatuur- en vochtigheidssensor',
      description: '<p>De temperatuur- en vochtigheidssensor meet met regelmatige tussenpozen de omgevingstemperatuur en de waarde van de luchtvochtigheid..</p>',
      image: '',
      productNo: '561.456',
      price: '65.31',
    },
    waterInstantSensors: {
      title: 'OPUS instantwatermelder',
      description: '<p>Geeft onmiddellijk een waarschuwing door aan uw smartphone in geval van contact met water. Onderhoudsvrij dankzij de zonnecel.</p>',
      image: '',
      productNo: '563.067',
      price: '134.22',
    },
    waterSlicesSensors: {
      title: 'OPUS watermelder met veerschijven',
      description: '<p>Waarschuwt op betrouwbare wijze voor waterschade, zelfs in volledige duisternis - 100% energie zelfvoorzienend.</p>',
      image: '',
      productNo: '563.068',
      price: '115.90',
    },
    shadingShutterSwitch: {
      title: 'OPUS BRiDGE Rolluik-/jaloezieschakelaar',
      description: '<p>Smart rolluiksturing voor uw elektrisch rolluik en basis voor verdere netwerking.</p>',
      image: '',
      productNo: '563.031',
      price: '91.64',
    },
    shadingCentralSwitch: {
      title: 'OPUS centrale besturing',
      description: '<p>Eén schakelaar voor de algemene bediening van uw gewenste functie: alle lichten uit? alle rolluiken open?</p>',
      image: '',
      productNo: 'ABC',
      price: '9.99',
    },
    shadingTubularMotor: {
      title: 'OPUS rolluikaandrijving',
      description: '<p>De gemakkelijkste manier voor elektrische en netwerkgebonden rolluiken.</p>',
      image: '',
      width: 'Breedte',
      height: 'Hoogte',
      material: 'Materiaal',
      productNo: '20X052000X0',
      price: '172.27',
    },
  },
};
