<template>
    <MainFrame>
        <template v-slot:columnLeft>
            <h1 class="a-headline a-headline--1">{{ $t('privacyPolicy.title') }}</h1>
            <div v-html="$t('privacyPolicy.body')"></div>
        </template>
    </MainFrame>
</template>

<script>
import MainFrame from '../components/MainFrame';
export default {
name: "PrivacyPolicy",
    components: {
        MainFrame,
    },
}
</script>

<style scoped>

</style>
