var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentStep === "init"
        ? _c("MainFrame", {
            attrs: {
              "has-progress-bar": true,
              "progress-bar-current": _vm.progressCurrent,
              "progress-bar-max": _vm.progressMax
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "columnRight",
                  fn: function() {
                    return [
                      _c("img", {
                        attrs: { src: _vm.imageRightIndex, alt: "" }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "columnLeft",
                  fn: function() {
                    return [
                      _c("h1", { staticClass: "a-headline a-headline--1" }, [
                        _c("img", {
                          attrs: { src: _vm.iconShadingShutterSwitch, alt: "" }
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t(
                                "subprocess.children.shading.children.shutterSwitch.title"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "subprocess.children.shading.children.shutterSwitch.body"
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("ProductNoWithPriceRow", {
                        attrs: {
                          "product-no": _vm.$t(
                            "products.shadingShutterSwitch.productNo"
                          ),
                          price: _vm.$t("products.shadingShutterSwitch.price")
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "m-btn-group m-btn-group--block" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSelect()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "subprocess.children.shading.children.shutterSwitch.actions.yes"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSkipStep()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "subprocess.children.shading.children.shutterSwitch.actions.no"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--secondary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onBack()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("global.actions.back")) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              851107694
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep === "repeat"
        ? _c("MainFrame", {
            attrs: {
              "has-progress-bar": true,
              "progress-bar-current": _vm.progressCurrent,
              "progress-bar-max": _vm.progressMax
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "columnRight",
                  fn: function() {
                    return [
                      _c("img", {
                        attrs: { src: _vm.imageRightRepeat, alt: "" }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "columnLeft",
                  fn: function() {
                    return [
                      _c("h1", { staticClass: "a-headline a-headline--1" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.iconShadingShutterSwitchPlus,
                            alt: ""
                          }
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t(
                                "subprocess.children.shading.children.shutterSwitch.children.repeat.title"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "subprocess.children.shading.children.shutterSwitch.children.repeat.body"
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "m-btn-group m-btn-group--block" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.currentStep = "init"
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "subprocess.children.shading.children.shutterSwitch.children.repeat.actions.yes"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSubmit()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "subprocess.children.shading.children.shutterSwitch.children.repeat.actions.no"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--secondary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onBack()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("global.actions.back")) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3394039561
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }