import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../i18n'
import AddRoom from '../pages/AddRoom';
import Surveillance from '../pages/Subprocess/Surveillance';
import Water from '../pages/Subprocess/Surveillance/Water';
import Temperature from '../pages/Subprocess/Surveillance/Temperature';
import Smoke from '../pages/Subprocess/Surveillance/Smoke';
import Motion from '../pages/Subprocess/Surveillance/Motion';
import Heat from '../pages/Subprocess/Surveillance/Heat';
import Door from '../pages/Subprocess/Surveillance/Door';
import Index from '../pages/Index';
import Subprocesses from '../pages/Subprocesses';
import Light from '../pages/Subprocess/Light';
import Overview from '../pages/Overview';
import Shading from '../pages/Subprocess/Shading';
import Preconfigure from '../pages/Preconfigure';
import ControlSystem from '../pages/ControlSystem';
import SaveConfiguration from '../pages/SaveConfiguration';
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfUse from "../pages/TermsOfUse";

Vue.use(VueRouter);

let router = new VueRouter({
    routes: [
        {name: 'index', path: '/:locale/', component: Index},
        {name: 'room', path: '/:locale/room/:id?', component: AddRoom},
        {name: 'subprocesses', path: '/:locale/subprocesses/:room?', component: Subprocesses},
        {name: 'light', path: '/:locale/light', component: Light},
        {name: 'shading', path: '/:locale/shading', component: Shading},
        {name: 'surveillance', path: '/:locale/surveillance', component: Surveillance},
        {name: 'surveillanceDoors', path: '/:locale/surveillance/doors', component: Door},
        {name: 'surveillanceHeat', path: '/:locale/surveillance/heat', component: Heat},
        {name: 'surveillanceMotion', path: '/:locale/surveillance/motion', component: Motion},
        {name: 'surveillanceSmoke', path: '/:locale/surveillance/smoke', component: Smoke},
        {name: 'surveillanceTemperature', path: '/:locale/surveillance/temperature', component: Temperature},
        {name: 'surveillanceWater', path: '/:locale/surveillance/water', component: Water},
        {name: 'controlSystem', path: '/:locale/controlSystem', component: ControlSystem},
        {name: 'overview', path: '/:locale/overview', component: Overview},
        {name: 'preconfigure', path: '/:locale/preconfigure', component: Preconfigure},
        {name: 'save', path: '/:locale/save', component: SaveConfiguration},
        {name: 'privacyPolicy', path: '/:locale/privacy-policy', component: PrivacyPolicy},
        {name: 'termsOfUse', path: '/:locale/terms-of-use', component: TermsOfUse},
    ],
});

router.beforeEach((to, from, next) => {
    let language = to.params.locale;

    if (!language) {
        const browserLocale = navigator.language.split('-')[0];

        if (['de', 'nl'].includes(browserLocale)) {
            i18n.locale = browserLocale;
            next({name: 'index', params: {locale: browserLocale}});
            return;
        }

        i18n.locale = 'de';
        next({name: 'index', params: {locale: 'de'}});
        return;
    }

    i18n.locale = language;
    next();
});

export default router;
