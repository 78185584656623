<template>
    <div>
        <Dimmer v-if="currentStep === 'dimmer'"
                :progressCurrent="1"
                :progressMax="4"
                @subprocessLightDimmerAdd="addDimmer()"
                @subprocessLightDimmerSkip="skipDimmer()"
        @subprocessLightSkip="onStoreAndExit()"/>

        <DoubleRockerSwitch v-if="currentStep === 'doubleRockerSwitch'"
                            :progressCurrent="2"
                            :progressMax="4"
                            @subprocessLightDoubleRockerSwitchAdd="addDoubleRockerSwitch()"
                            @subprocessLightDoubleRockerSwitchSkip="skipDoubleRockerSwitch()"
                            @subprocessLightSkip="onStoreAndExit()"/>

        <WirelessSwitch v-if="currentStep === 'wirelessSwitch'"
                        :progressCurrent="3"
                        :progressMax="4"
                        @subprocessLightWirelessSwitchAdd="addWirelessSwitch()"
                        @subprocessLightWirelessSwitchSkip="skipWirelessSwitch()"
                        @subprocessLightSkip="onStoreAndExit()"/>

        <LightRepeat v-if="currentStep === 'repeat'"
                     :progressCurrent="4"
                     :progressMax="4"
                     @subprocessLightRepeat="onStoreAndRepeat"
                     @subprocessLightSkip="onStoreAndExit"/>
    </div>
</template>

<script>
import Dimmer from '../../components/Subprocess/Light/Dimmer';
import DoubleRockerSwitch from '../../components/Subprocess/Light/DoubleRockerSwitch';
import LightRepeat from '../../components/Subprocess/Light/LightRepeat';
import WirelessSwitch from '../../components/Subprocess/Light/WirelessSwitch';
import App from '../../mixins/app';
import Notification from '../../components/Notification';

export default {
    name: 'Light',
    components: {DoubleRockerSwitch, LightRepeat, WirelessSwitch, Dimmer},
    mixins: [App],
    data() {
        return {
            currentStep: 'dimmer',
            dimmer: false,
            doubleRockerSwitch: false,
            singleRockerSwitch: false,
            wirelessSwitch: false,
        };
    },
    methods: {
        addDimmer: function() {
            this.dimmer = true;
            this.currentStep = 'wirelessSwitch';
            this.$toast.success({
                component: Notification, props: {
                    content: this.$t('subprocess.children.light.children.dimmer.notifications.added'),
                },
            });
        },
        skipDimmer: function() {
            this.currentStep = 'doubleRockerSwitch';
        },
        addDoubleRockerSwitch: function() {
            this.doubleRockerSwitch = true;
            this.currentStep = 'wirelessSwitch';
            this.$toast.success({
                component: Notification, props: {
                    content: this.$t('subprocess.children.light.children.doubleRockerSwitch.notifications.yes'),
                },
            });
        },
        skipDoubleRockerSwitch: function() {
            this.singleRockerSwitch = true;
            this.currentStep = 'wirelessSwitch';
            this.$toast.success({
                component: Notification, props: {
                    content: this.$t('subprocess.children.light.children.doubleRockerSwitch.notifications.no'),
                },
            });
        },
        addWirelessSwitch: function() {
            this.wirelessSwitch = true;
            this.currentStep = 'repeat';
            this.$toast.success({
                component: Notification, props: {
                    content: this.$t('subprocess.children.light.children.wirelessSwitch.notifications.added'),
                },
            });
        },
        skipWirelessSwitch: function() {
            this.currentStep = 'repeat';
        },
        onStoreAndRepeat: function() {
            this.storeConfiguration().then(() => {
                this.currentStep = 'dimmer';
                this.dimmer = false;
                this.doubleRockerSwitch = false;
                this.singleRockerSwitch = false;
                this.wirelessSwitch = false;
            });
        },
        onStoreAndExit: function() {
            this.storeConfiguration().then(() => {
                this.$router.push({name: 'subprocesses'});
            });
        },
        storeConfiguration: function() {
            return this.$store.dispatch('configuration/addSubprocess', {
                type: 'light',
                room: this.currentRoom.id,
                config: {
                    dimmer: this.dimmer,
                    doubleRockerSwitch: this.doubleRockerSwitch,
                    singleRockerSwitch: this.singleRockerSwitch,
                    wirelessSwitch: this.wirelessSwitch,
                },
            }).then(() => {
                this.$toast.success({
                    component: Notification, props: {
                        content: this.$t('subprocess.children.light.notifications.saved'),
                    },
                });
            });
        },
    },
};
</script>
