var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.icon ? _c("img", { attrs: { src: _vm.icon, alt: "" } }) : _vm._e(),
    _vm._v(" "),
    _vm.title ? _c("div", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
    _vm._v(" "),
    _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }