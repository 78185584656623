import iconPlaceholderImageIcon from '../../../images/icons/placeholder-image-icon.svg';
import iconPlaceholderImageIconDark from '../../../images/icons/placeholder-image-icon-dark.svg';
import iconArrowInfo from '../../../images/icons/arrow-right.svg';
import iconInfo from '../../../images/icons/info.svg';
import iconReset from '../../../images/icons/reset.svg';
import iconTrash from '../../../images/icons/trash.svg';
import iconPen from '../../../images/icons/pen.svg';
import iconPlaceholderSensor from '../../../images/icons/placeholder-sensor.png';
import iconProcessLight from '../../../images/icons/process/process-light.svg';
import iconProcessShading from '../../../images/icons/process/process-shading.svg';
import iconProcessSurveillance from '../../../images/icons/process/process-surveillance.svg';
import placeholderImage from '../../../images/placeholder-page-image.png';
import iconAddRoom from '../../../images/icons/room.svg';
import iconAddRoomPlus from '../../../images/icons/room-plus.svg';
import iconCompleted from '../../../images/icons/completed.svg';

/**
 * Light icons
 */
import iconLightDimmer from '../../../images/icons/process/light/dimmer.svg';
import iconLightDoubleRockerSwitch from '../../../images/icons/process/light/double-rocker-switch.svg';
import iconLightWirelessSwitch from '../../../images/icons/process/light/wireless-switch.svg';
import iconLightRepeat from '../../../images/icons/process/light/repeat.svg';

/**
 * Shading icons
 */
import iconShadingTubularMotor from '../../../images/icons/process/shading/tubular-motor.svg';
import iconShadingTubularMotorPlus from '../../../images/icons/process/shading/tubular-motor-plus.svg';
import iconShadingShutterSwitch from '../../../images/icons/process/shading/central-switch.svg';
import iconShadingShutterSwitchPlus from '../../../images/icons/process/shading/central-switch-plus.svg';
import iconShadingCentralSwitch from '../../../images/icons/process/shading/central-switch.svg';
import iconShadingCentralSwitchPlus from '../../../images/icons/process/shading/central-switch-plus.svg';

/**
 * Surveillance icons
 */
import iconSurveillanceHome from '../../../images/icons/process/surveillance/home.svg';
import iconSurveillanceDoorWindow from '../../../images/icons/process/surveillance/door-window.svg';
import iconSurveillanceDoorPlus from '../../../images/icons/process/surveillance/door-plus.svg';
import iconSurveillanceWindowPlus from '../../../images/icons/process/surveillance/window-plus.svg';

import iconSurveillanceHeat from '../../../images/icons/process/surveillance/heat.svg';
import iconSurveillanceHeatPlus from '../../../images/icons/process/surveillance/heat-plus.svg';

import iconSurveillanceMotion from '../../../images/icons/process/surveillance/motion.svg';
import iconSurveillanceMotionPlus from '../../../images/icons/process/surveillance/motion-plus.svg';

import iconSurveillanceSmoke from '../../../images/icons/process/surveillance/smoke.svg';
import iconSurveillanceSmokePlus from '../../../images/icons/process/surveillance/smoke-plus.svg';

import iconSurveillanceTemperature from '../../../images/icons/process/surveillance/temperature.svg';
import iconSurveillanceTemperaturePlus from '../../../images/icons/process/surveillance/temperature-plus.svg';

import iconSurveillanceWater from '../../../images/icons/process/surveillance/water.svg';
import iconSurveillanceWaterInstant from '../../../images/icons/process/surveillance/water-instant.svg';
import iconSurveillanceWaterInstantPlus from '../../../images/icons/process/surveillance/water-instant-plus.svg';
import iconSurveillanceWaterSlices from '../../../images/icons/process/surveillance/water-slices.svg';
import iconSurveillanceWaterSlicesPlus from '../../../images/icons/process/surveillance/water-slices-plus.svg';



 const AppIcons = {
   data() {
     return {
      iconAddRoom: iconAddRoom,
      iconAddRoomPlus: iconAddRoomPlus,
      iconPlaceholderImageIcon: iconPlaceholderImageIcon,
      iconPlaceholderImageIconDark: iconPlaceholderImageIconDark,
      iconArrowInfo: iconArrowInfo,
      iconInfo: iconInfo,
      iconReset: iconReset,
      iconTrash: iconTrash,
      iconPen: iconPen,
      iconPlaceholderSensor: iconPlaceholderSensor,
      iconProcessLight: iconProcessLight,
      iconProcessShading: iconProcessShading,
      iconProcessSurveillance: iconProcessSurveillance,
      placeholderImage: placeholderImage,
      iconCompleted: iconCompleted,


      // Light icons
      iconLightDimmer: iconLightDimmer,
      iconLightDoubleRockerSwitch: iconLightDoubleRockerSwitch,
      iconLightWirelessSwitch: iconLightWirelessSwitch,
      iconLightRepeat: iconLightRepeat,

      // Shading icons
      iconShadingCentralSwitch: iconShadingCentralSwitch,
      iconShadingCentralSwitchPlus: iconShadingCentralSwitchPlus,
      iconShadingShutterSwitch: iconShadingShutterSwitch,
      iconShadingShutterSwitchPlus: iconShadingShutterSwitchPlus,
      iconShadingTubularMotor: iconShadingTubularMotor,
      iconShadingTubularMotorPlus: iconShadingTubularMotorPlus,

      // Surveillance icons
      iconSurveillanceHome: iconSurveillanceHome,
      iconSurveillanceDoorWindow: iconSurveillanceDoorWindow,
      iconSurveillanceDoorPlus: iconSurveillanceDoorPlus,
      iconSurveillanceWindowPlus: iconSurveillanceWindowPlus,

      iconSurveillanceMotion: iconSurveillanceMotion,
      iconSurveillanceMotionPlus: iconSurveillanceMotionPlus,

      iconSurveillanceHeat: iconSurveillanceHeat,
      iconSurveillanceHeatPlus: iconSurveillanceHeatPlus,

      iconSurveillanceSmoke: iconSurveillanceSmoke,
      iconSurveillanceSmokePlus: iconSurveillanceSmokePlus,

      iconSurveillanceTemperature: iconSurveillanceTemperature,
      iconSurveillanceTemperaturePlus: iconSurveillanceTemperaturePlus,

      iconSurveillanceWater: iconSurveillanceWater,
      iconSurveillanceWaterInstant: iconSurveillanceWaterInstant,
      iconSurveillanceWaterInstantPlus: iconSurveillanceWaterInstantPlus,
      iconSurveillanceWaterSlices: iconSurveillanceWaterSlices,
      iconSurveillanceWaterSlicesPlus: iconSurveillanceWaterSlicesPlus,
    };
  }
 };

export default AppIcons;
