<template>
    <MainFrame :has-divider="false" :has-right-column-slot="false">
        <template v-slot:columnLeft>
            <h1 class="a-headline a-headline--1">{{ $t('termsOfUse.title') }}</h1>
            <div v-html="$t('termsOfUse.body')"></div>
        </template>
    </MainFrame>
</template>

<script>
import MainFrame from "../components/MainFrame";

export default {
    name: "TermsOfUse",
    components: {MainFrame}
}
</script>

<style scoped>

</style>
