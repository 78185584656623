import {v4 as uuidv4} from 'uuid';

const state = () => ({
  termsOfUse: false,
  code: null,
  currentRoom: null,
  rooms: {},
  subprocesses: {},
  preconfigureOptions: false,
  controlSystems: [],
  devices: [],
});

const getters = {
  termsOfUse: state => () => state.termsOfUse,

  code: state => () => state.code,

  controlSystems: state => () => state.controlSystems,

  preconfigureOptions: state => () => state.preconfigureOptions,

  roomSet: state => () => Object.values(state.rooms),

  currentRoom: state => () => {
    if (!state.rooms.hasOwnProperty(state.currentRoom)) {
      return null;
    }

    return state.rooms[state.currentRoom];
  },

  subprocessSet: state => () => Object.values(state.subprocesses),

  currentRoomSubprocessSet: state => () => {
    if (!state.rooms.hasOwnProperty(state.currentRoom)) {
      return [];
    }

    return Object.values(state.subprocesses).filter(subprocess => {
      return subprocess.room === state.currentRoom;
    });
  },

  currentRoomUniqueSubprocessByType: state => (type) => {
    if (!state.rooms.hasOwnProperty(state.currentRoom)) {
      return [];
    }

    return Object.values(state.subprocesses).find(subprocess => {
      const sameRoom = subprocess.room === state.currentRoom;
      const sameType = subprocess.type === type;

      return sameRoom && sameType;
    });

  },

  lockupDevicesByConfiguration: state => (roomId = null) => {
    let devices = [];
    let processes = Object.values(state.subprocesses);

    if (roomId !== null) {
      processes = processes.filter(process => process.room === roomId);
    }

    let addOrIncrement = function(
        devices = [], type, room, deviceName, onDelete = [], config = null) {
      const existingDeviceIndex = devices.findIndex(device => {
        let sameType = device.type === type;
        let sameRoom = device.room === room;
        let sameDevice = device.name === deviceName;

        return sameType && sameRoom && sameDevice;
      });

      if (existingDeviceIndex === -1) {
        devices.push({
          type: type,
          room: room,
          name: deviceName,
          quantity: 1,
          onDelete: onDelete,
          config: config,
        });
      } else {
        devices[existingDeviceIndex].quantity++;
      }
      return devices;
    };

    processes.forEach(process => {
      if (process.type === 'surveillance.door') {
        for (let i = 0; i < process.config.doors; i++) {
          devices = addOrIncrement(devices, 'surveillance', process.room,
              'doorSensor');
        }
        for (let i = 0; i < process.config.windows; i++) {
          devices = addOrIncrement(devices, 'surveillance', process.room,
              'windowSensor');
        }
      }

      if (process.type === 'surveillance.heat') {
        for (let i = 0; i < process.config.sensors; i++) {
          devices = addOrIncrement(devices, 'surveillance', process.room,
              'heatSensor');
        }
      }

      if (process.type === 'surveillance.motion') {
        for (let i = 0; i < process.config.sensors; i++) {
          devices = addOrIncrement(devices, 'surveillance', process.room,
              'motionSensor');
        }
      }

      if (process.type === 'surveillance.smoke') {
        for (let i = 0; i < process.config.detectors; i++) {
          devices = addOrIncrement(devices, 'surveillance', process.room,
              'smokeDetector');
        }
      }

      if (process.type === 'surveillance.temperature') {
        for (let i = 0; i < process.config.sensors; i++) {
          devices = addOrIncrement(devices, 'surveillance', process.room,
              'temperatureSensor');
        }
      }

      if (process.type === 'surveillance.water') {
        for (let i = 0; i < process.config.instantSensors; i++) {
          devices = addOrIncrement(devices, 'surveillance', process.room,
              'waterInstantSensors');
        }
        for (let i = 0; i < process.config.slicesSensors; i++) {
          devices = addOrIncrement(devices, 'surveillance', process.room,
              'waterSlicesSensors');
        }
      }

      if (process.type === 'light') {
        if ((process.config.dimmer || process.config.doubleRockerSwitch ||
            process.config.singleRockerSwitch) ||
            process.config.wirelessSwitch) {

          if (process.config.dimmer) {
            let reference = [];
            if (process.config.wirelessSwitch) {
              reference = ['lightWirelessSwitch'];
            }

            devices = addOrIncrement(devices, 'light', process.room,
                'lightDimmer', reference);
          }

          if (process.config.doubleRockerSwitch) {
            let reference = [];
            if (process.config.wirelessSwitch) {
              reference = ['lightWirelessSwitch'];
            }

            devices = addOrIncrement(devices, 'light', process.room,
                'lightDoubleRockerSwitch', reference);
          }

          if (process.config.singleRockerSwitch) {
            let reference = [];
            if (process.config.wirelessSwitch) {
              reference = ['lightWirelessSwitch'];
            }

            devices = addOrIncrement(devices, 'light', process.room,
                'lightSingleRockerSwitch', reference);
          }

          if (process.config.wirelessSwitch) {
            devices = addOrIncrement(devices, 'light', process.room,
                'lightWirelessSwitch');
          }
        }
      }

      if (process.type === 'shading') {
        process.config.tubularMotors.forEach(motor => {
          devices = addOrIncrement(devices, 'shading', process.room,
              'shadingTubularMotor--' + Object.values(motor).join('-'), [],
              motor);
        });

        if (process.config.shutterSwitches > 0) {
          for (let i = 0; i < process.config.shutterSwitches; i++) {
            devices = addOrIncrement(devices, 'shading', process.room,
                'shadingShutterSwitch');
          }
        }

        if (process.config.centralSwitches > 0) {
          for (let i = 0; i < process.config.centralSwitches; i++) {
            devices = addOrIncrement(devices, 'shading', process.room,
                'shadingCentralSwitch');
          }
        }
      }
    });

    return devices;
  },
};

const mutations = {
  setTermsOfUse(state, termsOfUse) {
    state.termsOfUse = termsOfUse;
  },

  setCode(state, code) {
    state.code = code;
  },

  addRoom(state, room) {
    state.rooms[room.id] = room;
    state.currentRoom = room.id;
  },

  changeRoom(state, id) {
    state.currentRoom = id;
  },

  refreshCurrentRoom(state) {
    if (state.currentRoom && state.rooms.hasOwnProperty(state.currentRoom)) {
      return;
    }

    if (Object.keys(state.rooms).length) {
      state.currentRoom = Object.keys(state.rooms).shift();
    }
  },

  addSubprocess(state, subprocess) {
    state.subprocesses[subprocess.id] = subprocess;
  },

  addUniqueSubprocess(state, subprocess) {
    let existingSubprocess = Object.values(state.subprocesses).
        find(storedObject => {
          const sameType = storedObject.type === subprocess.type;
          const sameRoom = storedObject.room === subprocess.room;

          return sameType && sameRoom;
        });

    if (!existingSubprocess) {
      state.subprocesses[subprocess.id] = subprocess;
    } else {
      state.subprocesses[existingSubprocess.id].config = subprocess.config;
    }
  },

  deleteSubprocessesByType(state, params) {
    const room = params.hasOwnProperty('room') ? params.room : null;
    const type = params.hasOwnProperty('type') ? params.type : null;

    Object.values(state.subprocesses).
        filter(subprocess => {
          const sameRoom = subprocess.room === room;
          const sameType = type ? subprocess.type.includes(type) : true; // all if no type given

          return sameRoom && sameType;
        }).
        map(subprocess => subprocess.id).
        forEach(id => {
          delete state.subprocesses[id];
        });
  },
  deleteRoom(state, id) {
    if (state.rooms.hasOwnProperty(id)) {
      delete state.rooms[id];
    }
  },

  deleteLightSubprocess(state, data) {
    for (let i = 0; i < data.quantity; i++) {
      const process = Object.values(state.subprocesses).find(process => {
        const sameType = process.type === 'light';
        const sameRoom = process.room === data.device.room;

        // Simple device without dependencies
        if (!data.device.onDelete.length) {
          let sameDevice = false;

          switch (data.device.name) {
            case 'lightDimmer':
              sameDevice = process.config.dimmer === true;
              break;
            case 'lightDoubleRockerSwitch':
              sameDevice = process.config.doubleRockerSwitch === true;
              break;
            case 'lightSingleRockerSwitch':
              sameDevice = process.config.singleRockerSwitch === true;
              break;
            case 'lightWirelessSwitch':
              sameDevice = process.config.wirelessSwitch === true;
              break;
          }

          return sameRoom && sameType && sameDevice;
        }

        let should = {
          dimmer: false,
          doubleRockerSwitch: false,
          singleRockerSwitch: false,
          wirelessSwitch: false,
        };

        if (data.device.name === 'lightDimmer') {
          should.dimmer = true;
        }
        if (data.device.name === 'lightDoubleRockerSwitch') {
          should.doubleRockerSwitch = true;
        }
        if (data.device.name === 'lightSingleRockerSwitch') {
          should.singleRockerSwitch = true;
        }
        if (data.device.name === 'lightWirelessSwitch') {
          should.wirelessSwitch = true;
        }

        data.device.onDelete.forEach(reference => {
          if (reference === 'lightDimmer') {
            should.dimmer = true;
          }
          if (reference === 'lightDoubleRockerSwitch') {
            should.doubleRockerSwitch = true;
          }
          if (reference === 'lightSingleRockerSwitch') {
            should.singleRockerSwitch = true;
          }
          if (reference === 'lightWirelessSwitch') {
            should.wirelessSwitch = true;
          }
        });

        const sameConfig = process.config.dimmer === should.dimmer
            && process.config.doubleRockerSwitch === should.doubleRockerSwitch
            && process.config.singleRockerSwitch === should.singleRockerSwitch
            && process.config.wirelessSwitch === should.wirelessSwitch;

        return sameRoom && sameType && sameConfig;
      });

      if (!process) {
        return;
      }

      if (data.device.name === 'lightDimmer') {
        state.subprocesses[process.id].config.dimmer = false;
      }
      if (data.device.name === 'lightDoubleRockerSwitch') {
        state.subprocesses[process.id].config.doubleRockerSwitch = false;
      }
      if (data.device.name === 'lightSingleRockerSwitch') {
        state.subprocesses[process.id].config.singleRockerSwitch = false;
      }
      if (data.device.name === 'lightWirelessSwitch') {
        state.subprocesses[process.id].config.wirelessSwitch = false;
      }
      data.device.onDelete.forEach(reference => {
        let configName = reference.replace('light', '');
        configName = configName[0].toLowerCase() + configName.slice(1);
        if (state.subprocesses[process.id].config.hasOwnProperty(configName)) {
          state.subprocesses[process.id].config[configName] = false;
        }
      });

      if (!state.subprocesses[process.id].config.dimmer &&
          !state.subprocesses[process.id].config.doubleRockerSwitch &&
          !state.subprocesses[process.id].config.singleRockerSwitch &&
          !state.subprocesses[process.id].config.wirelessSwitch) {
        delete state.subprocesses[process.id];
      }
    }
  },

  deleteShadingSubprocess(state, data) {
    for (let i = 0; i < data.quantity; i++) {
      const process = Object.values(state.subprocesses).find(process => {
        const sameType = process.type === 'shading';
        const sameRoom = process.room === data.device.room;

        return sameRoom && sameType;
      });

      if (data.device.name.includes('shadingTubularMotor--')) {
        let [type, value] = data.device.name.split('--');

        const index = state.subprocesses[process.id].config.tubularMotors.findIndex(
            motor => {
              console.log(value, Object.values(motor).join('-'));
              return value === Object.values(motor).join('-');
            });

        if (index !== -1) {
          state.subprocesses[process.id].config.tubularMotors.splice(index, 1);
        }
      }

      if (data.device.name === 'shadingShutterSwitch') {
        state.subprocesses[process.id].config.shutterSwitches -= 1;
      }

      if (data.device.name === 'shadingCentralSwitch') {
        state.subprocesses[process.id].config.centralSwitches -= 1;
      }
    }
  },

  deleteSurveillanceSubprocess(state, data) {
    const mapping = {
      doorSensor: 'surveillance.door',
      windowSensor: 'surveillance.door',
      heatSensor: 'surveillance.heat',
      motionSensor: 'surveillance.motion',
      smokeDetector: 'surveillance.smoke',
      temperatureSensor: 'surveillance.temperature',
      waterInstantSensors: 'surveillance.water',
      waterSlicesSensors: 'surveillance.water',
    };

    const process = Object.values(state.subprocesses).find(process => {
      const sameType = process.type === mapping[data.device.name];
      const sameRoom = process.room === data.device.room;
      return sameType && sameRoom;
    });

    if (!process) {
      return;
    }

    switch (data.device.name) {
      case 'doorSensor':
        state.subprocesses[process.id].config.doors -= data.quantity;
        break;
      case 'windowSensor':
        state.subprocesses[process.id].config.windows -= data.quantity;
        break;
      case 'heatSensor':
        state.subprocesses[process.id].config.sensors -= data.quantity;
        break;
      case 'motionSensor':
        state.subprocesses[process.id].config.sensors -= data.quantity;
        break;
      case 'smokeDetector':
        state.subprocesses[process.id].config.detectors -= data.quantity;
        break;
      case 'temperatureSensor':
        state.subprocesses[process.id].config.sensors -= data.quantity;
        break;
      case 'waterInstantSensors':
        state.subprocesses[process.id].config.instantSensors -= data.quantity;
        break;
      case 'waterSlicesSensors':
        state.subprocesses[process.id].config.slicesSensors -= data.quantity;
        break;
    }

  },

  setPreconfigureOptions(state, value= false) {
    state.preconfigureOptions = value;
  },

  setControlSystems(state, controlSystems = []) {
    state.controlSystems = controlSystems;
  },

  restore(state, config) {
    state.code = config.hasOwnProperty('code') ? config.code : null;
    state.currentRoom = config.hasOwnProperty('currentRoom') ?
        config.currentRoom :
        null;
    state.rooms = config.hasOwnProperty('rooms') ? config.rooms : {};
    state.subprocesses = config.hasOwnProperty('subprocesses') ?
        config.subprocesses :
        {};
    state.preconfigureOptions = config.hasOwnProperty('preconfigureOptions') ?
        config.preconfigureOptions :
        false;
    state.controlSystems = config.hasOwnProperty('controlSystems') ?
        config.controlSystems :
        [];
    state.devices = config.hasOwnProperty('devices') ? config.devices : [];
  },
};

const actions = {
  setTermsOfUse({commit, state}, termsOfUse) {
    if (termsOfUse) {
      commit('setTermsOfUse', termsOfUse);
    }
  },

  setCode({commit, state}, code) {
    if (code && code.length) {
      commit('setCode', code);
    }
  },

  addRoom({commit, state}, name) {
    if (name && name.length) {
      commit('addRoom', {id: uuidv4(), name: name});
    }
  },

  editRoom({commit, state}, room) {
    if (room.hasOwnProperty('id') && room.hasOwnProperty('name') &&
        room.name.length) {
      commit('addRoom', room);
    }
  },

  changeRoom({commit, state}, id) {
    if (Object.values(state.rooms).find(room => room.id === id)) {
      commit('changeRoom', id);
    }
  },

  refreshCurrentRoom({commit, state}) {
    commit('refreshCurrentRoom');
  },

  deleteRoom({commit, state}, id = null) {
    if (!id && !state.currentRoom) {
      return;
    }

    commit('deleteSubprocessesByType', {room: id || state.currentRoom.id});
    commit('deleteRoom', id || state.currentRoom.id);

    let roomIds = Object.keys(state.rooms);
    commit('changeRoom', roomIds.length ? roomIds.shift() : null);
  },

  addSubprocess({dispatch, commit, state}, subprocess) {
    subprocess.id = uuidv4();

    switch (subprocess.type) {
      case 'light':
        dispatch('addLightSubprocess', subprocess);
        break;
      case 'shading':
        dispatch('addShadingSubprocess', subprocess);
        break;
      case 'surveillance.door':
        dispatch('addSurveillanceSubprocess', subprocess);
        break;
      case 'surveillance.heat':
        dispatch('addSurveillanceSubprocess', subprocess);
        break;
      case 'surveillance.motion':
        dispatch('addSurveillanceSubprocess', subprocess);
        break;
      case 'surveillance.smoke':
        dispatch('addSurveillanceSubprocess', subprocess);
        break;
      case 'surveillance.temperature':
        dispatch('addSurveillanceSubprocess', subprocess);
        break;
      case 'surveillance.water':
        dispatch('addSurveillanceSubprocess', subprocess);
        break;
    }
  },

  addLightSubprocess({commit, state}, subprocess) {
    commit('addSubprocess', subprocess);
  },

  addShadingSubprocess({commit, state}, subprocess) {
    commit('addUniqueSubprocess', subprocess);
  },

  addSurveillanceSubprocess({commit, state}, subprocess) {
    commit('addUniqueSubprocess', subprocess);
  },

  truncateSubprocessByType({commit}, params) {
    commit('deleteSubprocessesByType', params);
  },

  setPreconfigureOptions({commit, state}, value) {
    commit('setPreconfigureOptions', value);
  },

  setControlSystems({commit, state}, controlSystems) {
    commit('setControlSystems', controlSystems);
  },

  deleteSubprocessByDevice({dispatch, commit, state}, data) {
    const surveillanceProducts = [
      'doorSensor',
      'windowSensor',
      'heatSensor',
      'motionSensor',
      'smokeDetector',
      'temperatureSensor',
      'waterInstantSensors',
      'waterSlicesSensors'];

    if (data.device.type === 'surveillance' &&
        surveillanceProducts.includes(data.device.name)) {
      dispatch('deleteSurveillanceSubprocess', data);
    }

    if (data.device.type === 'light') {
      dispatch('deleteLightSubprocess', data);
    }

    if (data.device.type === 'shading') {
      dispatch('deleteShadingSubprocess', data);
    }
  },

  deleteLightSubprocess({commit, state}, data) {
    commit('deleteLightSubprocess', data);
  },

  deleteShadingSubprocess({commit, state}, data) {
    commit('deleteShadingSubprocess', data);
  },

  deleteSurveillanceSubprocess({commit, state}, data) {
    commit('deleteSurveillanceSubprocess', data);
  },

  restore({commit, state}, data) {
    commit('restore', data.config);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
