import Vue from 'vue';
import VueI18n from 'vue-i18n';
import langDE from './locale/de';
import langNL from './locale/nl';

Vue.use(VueI18n);

const browserLocale = navigator.language.split('-')[0];
const defaultLocale = ['de', 'nl'].includes(browserLocale) ? browserLocale : 'de';

export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages: {
    'de': langDE,
    'nl': langNL,
  }
});

