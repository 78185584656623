<template>
    <div>
        <MainFrame v-if="currentStep === 'init'">
            <template v-slot:columnRight>
                <img :src="imageRight" alt="">
            </template>
            <template v-slot:columnLeft>
                <h1 class="a-headline a-headline--completed">
                    <img :src="iconCompleted" alt="">
                    {{ $t('saveConfiguration.children.success.title') }}
                </h1>
                <div v-html="$t('saveConfiguration.children.success.body')"></div>
                <div class="h-completed">
                    <span class="h-big">{{ $t('saveConfiguration.children.success.code') }}</span>
                    <span class="h-code">{{ currentCode }}</span>
                </div>

                <div class="m-btn-group m-btn-group--block">
                    <button @click.prevent="showForm('offer')" type="submit" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('saveConfiguration.children.success.offer') }}
                    </button>
                    <button @click.prevent="showForm('consultation')" type="submit"
                            class="a-btn a-btn--primary a-btn--big">
                        {{ $t('saveConfiguration.children.success.consultation') }}
                    </button>
                </div>

                <p>
                    <router-link :to="{name:'index'}" class="a-btn a-btn--secondary a-btn--big a-btn--block">
                        {{ $t('saveConfiguration.children.success.goBack') }}
                    </router-link>
                </p>
            </template>
        </MainFrame>

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" class="m-form" action="#">
                <MainFrame v-if="currentStep === 'form'">
                    <template v-slot:columnRight>
                        <img :src="imageRight" alt="">
                    </template>
                    <template v-slot:columnLeft>
                        <h1 v-if="formType === 'offer'" class="a-headline a-headline--1">
                            {{ $t('saveConfiguration.children.contact.title.offer') }}
                        </h1>
                        <h1 v-if="formType === 'consultation'" class="a-headline a-headline--1">
                            {{ $t('saveConfiguration.children.contact.title.consultation') }}
                        </h1>

                        <ValidationProvider
                            :name="$t('saveConfiguration.children.contact.form.fields.name.label')"
                            rules="required"
                            v-slot="{ errors }" tag="div"
                            class="m-form__group">
                            <label class="m-form__label" for="formName">
                                        <span class="sr-only">
                                            {{
                                                $t('saveConfiguration.children.contact.form.fields.name.label')
                                            }}
                                        </span>
                                <input v-model="formName"
                                       class="m-form__control"
                                       id="formName"
                                       type="text"
                                       min="0"
                                       :placeholder="$t('saveConfiguration.children.contact.form.fields.name.placeholder')"/>
                                <span v-if="errors && errors.length" class="m-form__message m-form__message--error">
                                            {{ $t(errors[0]) }}
                                        </span>
                            </label>
                        </ValidationProvider>

                        <ValidationProvider
                            :name="$t('saveConfiguration.children.contact.form.fields.email.label')"
                            rules="required"
                            v-slot="{ errors }" tag="div"
                            class="m-form__group">
                            <label class="m-form__label" for="formEmail">
                                        <span class="sr-only">
                                            {{
                                                $t('saveConfiguration.children.contact.form.fields.email.label')
                                            }}
                                        </span>
                                <input v-model="formEmail"
                                       class="m-form__control"
                                       id="formEmail"
                                       type="email"
                                       min="0"
                                       :placeholder="$t('saveConfiguration.children.contact.form.fields.email.placeholder')"/>
                                <span v-if="errors && errors.length" class="m-form__message m-form__message--error">
                                            {{ $t(errors[0]) }}
                                        </span>
                            </label>
                        </ValidationProvider>

                        <ValidationProvider
                            :name="$t('saveConfiguration.children.contact.form.fields.phone.label')"
                            rules=""
                            v-slot="{ errors }" tag="div"
                            class="m-form__group">
                            <label class="m-form__label" for="formPhone">
                                        <span class="sr-only">
                                            {{
                                                $t('saveConfiguration.children.contact.form.fields.phone.label')
                                            }}
                                        </span>
                                <input v-model="formPhone"
                                       class="m-form__control"
                                       id="formPhone"
                                       type="tel"
                                       min="0"
                                       :placeholder="$t('saveConfiguration.children.contact.form.fields.phone.placeholder')"/>
                                <span v-if="errors && errors.length" class="m-form__message m-form__message--error">
                                            {{ $t(errors[0]) }}
                                        </span>
                            </label>
                        </ValidationProvider>

                        <ValidationProvider
                            :name="$t('saveConfiguration.children.contact.form.fields.privacyPolicy.label')"
                            rules="required"
                            v-slot="{ errors }" tag="div"
                            class="m-form__group">
                            <input v-model="formTerms"
                                   class="m-form__control"
                                   id="formPrivacyPolicy"
                                   type="checkbox"
                                   required
                            />
                            <label class="m-form__label" for="formPrivacyPolicy">
                                <span
                                    v-html="$t('saveConfiguration.children.contact.form.fields.privacyPolicy.label', {'url': $t('global.footerMenu.items.privacy.href')})"></span>
                                <span v-if="errors && errors.length" class="m-form__message m-form__message--error">
                                            {{ $t(errors[0]) }}
                                        </span>
                            </label>
                        </ValidationProvider>

                        <div class="m-btn-group m-btn-group--block">
                            <button type="submit" class="a-btn a-btn--primary  a-btn--big">
                                {{
                                    $t('saveConfiguration.children.contact.actions.send')
                                }}
                            </button>
                        </div>
                    </template>
                </MainFrame>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import App from '../mixins/app';
import MainFrame from '../components/MainFrame';
import AppIcons from '../mixins/app-icons';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import Notification from '../components/Notification';
import ImageRightDe from '../../../images/pages/save-configuration-de.jpg';
import ImageRightNl from '../../../images/pages/save-configuration-nl.jpg';

export default {
    name: 'SaveConfiguration',
    components: {
        MainFrame,
        ValidationObserver,
        ValidationProvider,
    },
    mixins: [App, AppIcons],
    data() {
        return {
            imageRight: null,
            currentStep: 'init',
            data: {},
            formType: null,
            formName: null,
            formEmail: null,
            formPhone: null,
            formTerms: false,
            formPrivacyPolicy: null,
        };
    },
    beforeMount() {
        this.imageRight = this.$i18n.locale !== 'nl' ? ImageRightDe : ImageRightNl;
        this.fetchDevices().forEach(item => {
            this.data[item.room.name] = item.devices.map(device => {
                return {
                    name: device.translatedName,
                    description: device.translatedDescription,
                    quantity: device.quantity,
                    productNo: device.productNo,
                    price: device.price,
                }
            });
        });
    },
    methods: {
        showForm: function (type) {
            this.currentStep = 'form';
            this.formType = type;
        },
        onSubmit: function () {
            const params = {
                type: this.formType,
                locale: this.$i18n.locale,
                name: this.formName,
                email: this.formEmail,
                phone: this.formPhone,
                preconfigure: this.$store.getters['configuration/preconfigureOptions'](),
                configuration: this.data
            };

            this.$http.post(`${process.env.VUE_APP_OPUS_DOMAIN}/api/v1/contact/` + this.currentCode, params).then(response => {
                this.$toast.success({
                    component: Notification, props: {
                        content: this.$t('saveConfiguration.children.contact.notifications.sendSuccess'),
                    }
                });
                this.currentStep = 'init';
            }).catch(error => {
                this.$toast.error({
                    component: Notification, props: {
                        content: this.$t('saveConfiguration.children.contact.notifications.sendError'),
                    }
                });
            });
        },
    },
};
</script>
