var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "o-main" },
    [
      _vm.hasProgressBar
        ? _c("ProgressBar", {
            attrs: { max: _vm.progressBarMax, current: _vm.progressBarCurrent }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "o-main__row" }, [
        _vm.hasLeftColumnSlot
          ? _c(
              "div",
              {
                staticClass: "o-main__column ",
                class: { "o-main__column--left": _vm.hasRightColumnSlot }
              },
              [_vm._t("columnLeft")],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasDivider
          ? _c("div", { staticClass: "o-main__divider" })
          : _vm._e(),
        _vm._v(" "),
        _vm.hasRightColumnSlot
          ? _c(
              "div",
              { staticClass: "o-main__column o-main__column--right" },
              [
                _vm._t("columnRight", [
                  _c("img", { attrs: { src: _vm.placeholderImage, alt: "" } })
                ])
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }