<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" class="m-form" action="#">
            <MainFrame>
                <template v-slot:columnRight>
                    <img :src="imageRight" alt="">
                </template>
                <template v-slot:columnLeft>
                    <h1 class="a-headline a-headline--1">{{ $t('index.title') }}</h1>
                    <div v-html="$t('index.body')"></div>


                    <ValidationProvider
                        v-if="!formTermsOfUse"
                        :name="$t('index.form.fields.termsOfUse.label')"
                        rules="required"
                        v-slot="{ errors }" tag="div"
                        class="m-form__group">
                        <input v-model="formTermsOfUse"
                               class="m-form__control"
                               id="formTermsOfUse"
                               type="checkbox"
                               required
                        />
                        <label class="m-form__label" for="formTermsOfUse">
                            <span
                                v-html="$t('index.form.fields.termsOfUse.label', {'url': $router.resolve({name: 'termsOfUse'}).href})"></span>
                            <span v-if="errors && errors.length" class="m-form__message m-form__message--error">
                                            {{ $t(errors[0]) }}
                                        </span>
                        </label>
                    </ValidationProvider>

                    <div class="m-btn-group m-btn-group--left">
                        <button @click="onContinue()" class="a-btn a-btn--arrow a-btn--wider">
                    <span v-if="hasStoredConfig">{{ $t('index.actions.continue') }}
                            <span class="a-icon__arrow-right">&nbsp;</span>
                    </span>
                            <span v-else>{{ $t('index.actions.next') }}
                            <span class="a-icon__arrow-right">&nbsp;</span>
                    </span>
                        </button>
                        <button v-if="hasStoredConfig" @click.prevent="onReset()"
                                class="a-btn a-btn--secondary a-btn--wider">
                            {{ $t('index.actions.reset') }}
                        </button>
                    </div>

                    <hr>

                            <ValidationProvider :name="$t('index.form.fields.code.label')"
                                                rules="required" v-slot="{ errors }" tag="div"
                                                class="m-form__group">
                                <label class="m-form__label" for="roomName">
                                <span class="sr-only">
                                    {{ $t('index.form.fields.code.label') }}
                                </span>
                                    <input v-model="code"
                                           class="m-form__control"
                                           id="roomName"
                                           type="text"
                                           min="0"
                                           :placeholder="$t('index.form.fields.code.placeholder')"/>
                                    <span class="m-form__message m-form__message--error">
                                        {{ $t(errors[0]) }}
                                    </span>
                                </label>
                            </ValidationProvider>
                            <div class="m-btn-group m-btn-group--left">
                                <button type="submit" class="a-btn a-btn--upload">
                                    {{ $t('index.actions.load') }}
                                    <span class="a-icon__upload">&nbsp;</span>
                                </button>
                            </div>
                </template>
            </MainFrame>
        </form>
    </ValidationObserver>
</template>

<script>
import ImageRight from '../../../images/pages/index.png';
import App from '../mixins/app';
import AppIcons from '../mixins/app-icons';
import MainFrame from '../components/MainFrame';
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import Notification from '../components/Notification';
import {required} from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'global.forms.errors.required',
});

export default {
    name: 'Index',
    components: {
        ValidationObserver,
        ValidationProvider,
        MainFrame,
    },
    mixins: [App, AppIcons],
    data() {
        return {
            imageRight: ImageRight,
            code: null,
            formTermsOfUse: false,
        };
    },
    created() {
        this.formTermsOfUse = !!this.$store.getters['configuration/termsOfUse']()
    },
    computed: {
        hasStoredConfig: function () {
            return !!this.$store.getters['configuration/roomSet']().length;
        },
    },
    methods: {
        onContinue: function () {
            if(!this.formTermsOfUse) {
                return;
            }
            this.$store.dispatch('configuration/setTermsOfUse', true);
            this.$router.push({name: 'room'})
        },
        onReset: function () {
            if (!this.hasStoredConfig) {
                return;
            }

            this.$store.dispatch('configuration/restore', {config: {}}).then(() => {
                this.$toast.success({
                    component: Notification, props: {
                        content: this.$t('index.notifications.configurationReseted'),
                    }
                });
            });
        },
        onSubmit: function () {
            this.$http.get(`${process.env.VUE_APP_OPUS_DOMAIN}/api/v1/receive/` + this.code).then(response => {
                if (response.status !== 200) {
                    this.$toast.error({
                        component: Notification, props: {
                            content: this.$t('index.notifications.configurationFailed'),
                        }
                    });
                    return;
                }

                this.$store.dispatch('configuration/setTermsOfUse', true);
                this.$store.dispatch('configuration/restore', response.data).then(() => {
                    this.$toast.success({
                        component: Notification, props: {
                            content: this.$t('index.notifications.configurationLoaded'),
                        }
                    });
                    this.$router.push({name: 'subprocesses'});
                });
            }).catch(error => {
                this.$toast.error({
                    component: Notification, props: {
                        content: this.$t('index.notifications.configurationFailed'),
                    }
                });
            });
            console.log(this.code);
            console.groupEnd();
        },
    },
};
</script>
