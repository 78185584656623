<template>

    <MainFrame :has-divider="false" :has-right-column-slot="false">
        <template v-slot:columnLeft>
            <div v-for="item in data"
                 class="o-overview">
                <h1 class="o-overview__headline a-headline a-headline--1">
                    <router-link :to="{name: 'subprocesses', params: {room: item.room.id}}">
                        {{ item.room.name }}
                    </router-link>
                </h1>

                <table v-if="item.devices.length" class="m-cart">
                    <thead>
                    <tr class="m-cart__row">
                        <th colspan="1" class="m-cart__col m-cart__col--description">&nbsp;</th>
                        <th class="m-cart__col m-cart__col--price">
                            {{ $t('global.attributes.unitPrice') }}
                        </th>
                        <th class="m-cart__col m-cart__col--price">
                            {{ $t('global.attributes.allRoundPrice') }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="device in item.devices" class="m-cart__row">
                        <!--                        <td class="m-cart__col m-cart__col&#45;&#45;image">-&#45;&#45;</td>-->
                        <td class="m-cart__col m-cart__col--description">
                            <div>
                                <strong> {{ device.translatedName }} </strong>
                            </div>
                            <div class="m-product-card__body">
                                <div v-html="device.translatedDescription"></div>
                                {{ $t('global.attributes.productNo') }} {{device.productNo}}
                            </div>
                            <div class="m-cart__actions">
                                <select @change.prevent="onDelete(device)"
                                        v-model="device.currentQuantity">
                                    <option v-for="i in getReversedProductNumber(device.quantity)" :value="i.value">
                                        {{ i.label }}
                                    </option>
                                </select>
                                <a @click.prevent="onDelete(device, true)"
                                   class="a-icon__trash"
                                   href="#"
                                   v-tooltip="{ content:$t('overview.tooltips.deleteAllDevices', {
                                        device: device.translatedName,
                                        num:device.quantity,
                                        room:item.room.name,
                                    })}">
                                    <span class="sr-only">{{ $t('global.actions.delete') }}</span>
                                </a>
                            </div>
                        </td>
                        <td class="m-cart__col m-cart__col--price" :data-mobile-prefix="$t('global.attributes.unitPrice')">
                            {{ device.price }}<FootnotePriceIndicator />
                        </td>
                        <td class="m-cart__col m-cart__col--price" :data-mobile-prefix="$t('global.attributes.allRoundPrice')">
                            {{ device.sum }}<FootnotePriceIndicator />
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="3" class="m-cart__col m-cart__col--sum">
                            {{ $t('global.attributes.sum') }}: {{ item.sum }} EUR<FootnotePriceIndicator />
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>

            <div v-if="selectedControlSystem" class="o-overview">
                <h1 class="o-overview__headline a-headline a-headline--1">
                    <router-link :to="{name: 'controlSystem'}">
                        {{ $t('overview.titleSelectedControlSystem') }}
                    </router-link>
                </h1>

                <ul class="o-overview__items">
                    <li class="o-overview__item m-product-card">
                        <div class="m-product-card_inner">
                            <div class="m-product-card__content">
                                <h2 class="m-product-card__headline">
                                    {{ $t('products.controlSystems.' + selectedControlSystem + '.title') }}
                                    <router-link :to="{name: 'controlSystem'}" class="a-icon__reset">
                                        <span class="sr-only">{{ $t('global.actions.edit') }}</span>
                                    </router-link>
                                </h2>
                                <div v-html="$t('products.controlSystems.' + selectedControlSystem+'.description')"
                                     class="m-product-card__body">
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="m-btn-group m-btn-group--right">
                <router-link :to="{name: 'preconfigure'}" class="a-btn a-btn--primary">
                    {{ $t('global.actions.complete') }}
                </router-link>
            </div>
        </template>
    </MainFrame>
</template>

<script>
import App from '../mixins/app';
import MainFrame from '../components/MainFrame';
import Notification from '../components/Notification';
import FootnotePriceIndicator from "../components/FootnotePriceIndicator";

export default {
    name: 'Overview',
    components: {FootnotePriceIndicator, MainFrame, Notification},
    mixins: [App],
    data() {
        return {
            refreshToken: null,
            data: [],
            uniqueDevices: {},
        };
    },
    computed: {
        subprocesses: function() {
            return this.$store.getters['configuration/subprocessSet']();
        },
        selectedControlSystem: function() {
            return this.$store.getters['configuration/controlSystems']();
        },
    },
    beforeMount() {
        if (this.selectedControlSystem.length === 0) {
            this.$router.push({name: 'controlSystem'});
        }

        this.data = this.fetchDevices();
    },
    methods: {
        getReversedProductNumber: function(number) {
            let data = [{label: '0 ('+this.$t('global.actions.delete').toLowerCase()+')', value: 0}];
            for (let i = 1; i <= number; i++) {
                data.push({label: i, value: i});
            }
            return data.slice().reverse();
        },
        onDelete: function(device, all = false) {
            let devicesToDelete = all ? device.quantity : device.quantity - device.currentQuantity;
            console.log(device, devicesToDelete);

            if (devicesToDelete === 0) {
                return;
            }

            let data = {
                device: device,
                quantity: devicesToDelete,
            };

            this.$store.dispatch('configuration/deleteSubprocessByDevice', data).then(() => {
                this.data = this.fetchDevices();

                this.$toast.success({
                    component: Notification,
                    props: {
                        content: this.$t('overview.notifications.deviceDeleted', {
                            device: device.translatedName,
                            quantity: data.quantity,
                        }),
                    },
                });

                if (device.hasOwnProperty('onDelete')) {
                    device.onDelete.forEach(deviceName => {
                        this.$toast.success({
                            component: Notification, props: {
                                content: this.$t('overview.notifications.deviceDeleted', {
                                    device: this.$t('products.' + deviceName + '.title'),
                                    quantity: data.quantity,
                                }),
                            },
                        });
                    });
                }
            });
        },
    },
};
</script>
