<template>
    <p>
        {{ $t('global.attributes.productNo') }} {{ productNo }}<br>
        {{ $t('global.attributes.priceFrom') }} {{ price }} EUR<FootnotePriceIndicator /><br>
    </p>
</template>

<script>
import FootnotePriceIndicator from "./FootnotePriceIndicator";
export default {
    name: 'ProductNoWithPriceRow',
    components: {FootnotePriceIndicator},
    props: ['productNo', 'price'],
};
</script>

<style scoped>

</style>
