<template>
    <MainFrame :has-progress-bar="true"
               :progress-bar-current="progressCurrent"
               :progress-bar-max="progressMax">
        <template v-slot:columnRight>
            <img :src="imageRight" alt="">
        </template>
        <template v-slot:columnLeft>
            <h1 class="a-headline a-headline--1">
                <img :src="iconShadingCentralSwitch" alt="">
                {{ $t('subprocess.children.shading.children.centralSwitch.title') }}
            </h1>
            <div v-html="$t('subprocess.children.shading.children.centralSwitch.body')"></div>
            <ProductNoWithPriceRow :product-no="$t('products.shadingCentralSwitch.productNo')"
                                   :price="$t('products.shadingCentralSwitch.price')"/>

            <div class="m-btn-group m-btn-group--block">
                <button @click.prevent="onSubmit()" class="a-btn a-btn--primary a-btn--big">
                    {{ $t('subprocess.children.shading.children.centralSwitch.actions.yes') }}
                </button>
                <button @click.prevent="onSkipStep()" class="a-btn a-btn--primary a-btn--big">
                    {{ $t('subprocess.children.shading.children.centralSwitch.actions.no') }}
                </button>
                <button @click.prevent="onBack()" class="a-btn a-btn--secondary a-btn--big">
                    {{ $t('global.actions.back') }}
                </button>
            </div>
        </template>
    </MainFrame>
</template>

<script>
import ProgressBar from '../../ProgressBar';
import MainFrame from '../../MainFrame';
import AppIcons from '../../../mixins/app-icons';
import ProductNoWithPriceRow from '../../ProductNoWithPriceRow';
import ImageRight from "../../../../../images/pages/shading-central-switch.png";

export default {
    name: 'ShadingCentralSwitch',
    components: {ProductNoWithPriceRow, MainFrame, ProgressBar},
    mixins: [AppIcons],
    props: {
        progressMax: {
            type: Number,
            required: true,
        },
        progressCurrent: {
            type: Number,
            required: true,
            default: 1,
        },
    },
    data() {
        return {
            imageRight: ImageRight,
        };
    },
    methods: {
        onSubmit: function () {
            this.$emit('subprocessShadingCentralSwitchAdd', 1);
        },
        onSkipStep: function () {
            this.$emit('subprocessShadingCentralSwitchSkip', 0);
        },
        onBack: function () {
            this.$emit('subprocessShadingCentralSwitchSkipAndExit', 0);
        },
    },
};
</script>
