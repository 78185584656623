var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentStep === "dimmer"
        ? _c("Dimmer", {
            attrs: { progressCurrent: 1, progressMax: 4 },
            on: {
              subprocessLightDimmerAdd: function($event) {
                return _vm.addDimmer()
              },
              subprocessLightDimmerSkip: function($event) {
                return _vm.skipDimmer()
              },
              subprocessLightSkip: function($event) {
                return _vm.onStoreAndExit()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep === "doubleRockerSwitch"
        ? _c("DoubleRockerSwitch", {
            attrs: { progressCurrent: 2, progressMax: 4 },
            on: {
              subprocessLightDoubleRockerSwitchAdd: function($event) {
                return _vm.addDoubleRockerSwitch()
              },
              subprocessLightDoubleRockerSwitchSkip: function($event) {
                return _vm.skipDoubleRockerSwitch()
              },
              subprocessLightSkip: function($event) {
                return _vm.onStoreAndExit()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep === "wirelessSwitch"
        ? _c("WirelessSwitch", {
            attrs: { progressCurrent: 3, progressMax: 4 },
            on: {
              subprocessLightWirelessSwitchAdd: function($event) {
                return _vm.addWirelessSwitch()
              },
              subprocessLightWirelessSwitchSkip: function($event) {
                return _vm.skipWirelessSwitch()
              },
              subprocessLightSkip: function($event) {
                return _vm.onStoreAndExit()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep === "repeat"
        ? _c("LightRepeat", {
            attrs: { progressCurrent: 4, progressMax: 4 },
            on: {
              subprocessLightRepeat: _vm.onStoreAndRepeat,
              subprocessLightSkip: _vm.onStoreAndExit
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }