var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainFrame", {
    attrs: { "has-divider": false, "has-right-column-slot": false },
    scopedSlots: _vm._u([
      {
        key: "columnLeft",
        fn: function() {
          return [
            _vm._l(_vm.data, function(item) {
              return _c("div", { staticClass: "o-overview" }, [
                _c(
                  "h1",
                  {
                    staticClass: "o-overview__headline a-headline a-headline--1"
                  },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "subprocesses",
                            params: { room: item.room.id }
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.room.name) +
                            "\n                "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                item.devices.length
                  ? _c("table", { staticClass: "m-cart" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "m-cart__row" }, [
                          _c(
                            "th",
                            {
                              staticClass:
                                "m-cart__col m-cart__col--description",
                              attrs: { colspan: "1" }
                            },
                            [_vm._v(" ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            { staticClass: "m-cart__col m-cart__col--price" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t("global.attributes.unitPrice")
                                  ) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            { staticClass: "m-cart__col m-cart__col--price" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t("global.attributes.allRoundPrice")
                                  ) +
                                  "\n                    "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(item.devices, function(device) {
                          return _c("tr", { staticClass: "m-cart__row" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "m-cart__col m-cart__col--description"
                              },
                              [
                                _c("div", [
                                  _c("strong", [
                                    _vm._v(
                                      " " + _vm._s(device.translatedName) + " "
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "m-product-card__body" },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          device.translatedDescription
                                        )
                                      }
                                    }),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t("global.attributes.productNo")
                                        ) +
                                        " " +
                                        _vm._s(device.productNo) +
                                        "\n                        "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "m-cart__actions" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: device.currentQuantity,
                                          expression: "device.currentQuantity"
                                        }
                                      ],
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              device,
                                              "currentQuantity",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function($event) {
                                            $event.preventDefault()
                                            return _vm.onDelete(device)
                                          }
                                        ]
                                      }
                                    },
                                    _vm._l(
                                      _vm.getReversedProductNumber(
                                        device.quantity
                                      ),
                                      function(i) {
                                        return _c(
                                          "option",
                                          { domProps: { value: i.value } },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(i.label) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: {
                                            content: _vm.$t(
                                              "overview.tooltips.deleteAllDevices",
                                              {
                                                device: device.translatedName,
                                                num: device.quantity,
                                                room: item.room.name
                                              }
                                            )
                                          },
                                          expression:
                                            "{ content:$t('overview.tooltips.deleteAllDevices', {\n                                    device: device.translatedName,\n                                    num:device.quantity,\n                                    room:item.room.name,\n                                })}"
                                        }
                                      ],
                                      staticClass: "a-icon__trash",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.onDelete(device, true)
                                        }
                                      }
                                    },
                                    [
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("global.actions.delete")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "m-cart__col m-cart__col--price",
                                attrs: {
                                  "data-mobile-prefix": _vm.$t(
                                    "global.attributes.unitPrice"
                                  )
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(device.price)
                                ),
                                _c("FootnotePriceIndicator")
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "m-cart__col m-cart__col--price",
                                attrs: {
                                  "data-mobile-prefix": _vm.$t(
                                    "global.attributes.allRoundPrice"
                                  )
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(device.sum)
                                ),
                                _c("FootnotePriceIndicator")
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("tfoot", [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "m-cart__col m-cart__col--sum",
                              attrs: { colspan: "3" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("global.attributes.sum")) +
                                  ": " +
                                  _vm._s(item.sum) +
                                  " EUR"
                              ),
                              _c("FootnotePriceIndicator")
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            }),
            _vm._v(" "),
            _vm.selectedControlSystem
              ? _c("div", { staticClass: "o-overview" }, [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "o-overview__headline a-headline a-headline--1"
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "controlSystem" } } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("overview.titleSelectedControlSystem")
                              ) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ul", { staticClass: "o-overview__items" }, [
                    _c(
                      "li",
                      { staticClass: "o-overview__item m-product-card" },
                      [
                        _c("div", { staticClass: "m-product-card_inner" }, [
                          _c(
                            "div",
                            { staticClass: "m-product-card__content" },
                            [
                              _c(
                                "h2",
                                { staticClass: "m-product-card__headline" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "products.controlSystems." +
                                            _vm.selectedControlSystem +
                                            ".title"
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "a-icon__reset",
                                      attrs: { to: { name: "controlSystem" } }
                                    },
                                    [
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("global.actions.edit"))
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "m-product-card__body",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "products.controlSystems." +
                                        _vm.selectedControlSystem +
                                        ".description"
                                    )
                                  )
                                }
                              })
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "m-btn-group m-btn-group--right" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "a-btn a-btn--primary",
                    attrs: { to: { name: "preconfigure" } }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("global.actions.complete")) +
                        "\n            "
                    )
                  ]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }