var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    staticClass: "m-form",
                    attrs: { action: "#" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _c("MainFrame", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "columnRight",
                            fn: function() {
                              return [
                                _c("img", {
                                  attrs: { src: _vm.imageRight, alt: "" }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "columnLeft",
                            fn: function() {
                              return [
                                _c(
                                  "h1",
                                  { staticClass: "a-headline a-headline--1" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.iconSurveillanceSmoke,
                                        alt: ""
                                      }
                                    }),
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "subprocess.children.surveillance.children.smoke.title"
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "subprocess.children.surveillance.children.smoke.body"
                                      )
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("ProductNoWithPriceRow", {
                                  attrs: {
                                    "product-no": _vm.$t(
                                      "products.smokeDetector.productNo"
                                    ),
                                    price: _vm.$t(
                                      "products.smokeDetector.price"
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  staticClass:
                                    "m-form__group m-form__group--prefixed m-form__group--suffixed ",
                                  attrs: {
                                    name: _vm.$t(
                                      "subprocess.children.surveillance.children.smoke.form.fields.detectors.label"
                                    ),
                                    rules: "",
                                    tag: "div"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "m-form__prefix" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      _vm.iconSurveillanceSmokePlus,
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "m-form__label",
                                                attrs: { for: "detectors" }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "subprocess.children.surveillance.children.smoke.form.fields.detectors.label"
                                                          )
                                                        ) +
                                                        "\n                                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.detectors,
                                                      expression: "detectors"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "m-form__control",
                                                  attrs: {
                                                    id: "detectors",
                                                    type: "number",
                                                    min: "0",
                                                    placeholder: _vm.$t(
                                                      "subprocess.children.surveillance.children.smoke.form.fields.detectors.placeholder"
                                                    )
                                                  },
                                                  domProps: {
                                                    value: _vm.detectors
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.detectors =
                                                        $event.target.value
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "m-form__message m-form__message--error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.$t(errors[0])
                                                        ) +
                                                        "\n                                "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "m-form__suffix" },
                                              [
                                                _c("img", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: {
                                                        content: _vm.$t(
                                                          "subprocess.children.surveillance.children.smoke.form.fields.detectors.info"
                                                        )
                                                      },
                                                      expression:
                                                        "{ content:$t('subprocess.children.surveillance.children.smoke.form.fields.detectors.info')}"
                                                    }
                                                  ],
                                                  attrs: {
                                                    src: _vm.iconInfo,
                                                    alt: ""
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "m-btn-group m-btn-group--block"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "a-btn a-btn--primary  a-btn--big",
                                        attrs: { type: "submit" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.$t(
                                                "subprocess.children.surveillance.children.smoke.actions.next"
                                              )
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }