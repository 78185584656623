var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    staticClass: "m-form",
                    attrs: { action: "#" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _vm.selectedType === "init"
                      ? _c("MainFrame", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "columnRight",
                                fn: function() {
                                  return [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.imageRightIndex,
                                        alt: ""
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "columnLeft",
                                fn: function() {
                                  return [
                                    _c(
                                      "h1",
                                      {
                                        staticClass: "a-headline a-headline--1"
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.iconSurveillanceWater,
                                            alt: ""
                                          }
                                        }),
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "subprocess.children.surveillance.children.water.title"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "subprocess.children.surveillance.children.water.body"
                                          )
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-btn-group m-btn-group--block"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "a-btn a-btn--primary  a-btn--big",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                _vm.selectedType = "instant"
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "subprocess.children.surveillance.children.water.actions.instant"
                                                  )
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "a-btn a-btn--primary  a-btn--big",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                _vm.selectedType = "slices"
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "subprocess.children.surveillance.children.water.actions.slices"
                                                  )
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedType === "instant"
                      ? _c("MainFrame", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "columnRight",
                                fn: function() {
                                  return [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.imageRightInstant,
                                        alt: ""
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "columnLeft",
                                fn: function() {
                                  return [
                                    _c(
                                      "h1",
                                      {
                                        staticClass: "a-headline a-headline--1"
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.iconSurveillanceWaterInstant,
                                            alt: ""
                                          }
                                        }),
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "subprocess.children.surveillance.children.water.children.instant.title"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "subprocess.children.surveillance.children.water.children.instant.body"
                                          )
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("ProductNoWithPriceRow", {
                                      attrs: {
                                        "product-no": _vm.$t(
                                          "products.waterInstantSensors.productNo"
                                        ),
                                        price: _vm.$t(
                                          "products.waterInstantSensors.price"
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "m-form__group m-form__group--prefixed",
                                      attrs: {
                                        name: _vm.$t(
                                          "subprocess.children.surveillance.children.water.children.instant.form.fields.sensors.label"
                                        ),
                                        rules: "",
                                        tag: "div"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "m-form__prefix"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          _vm.iconSurveillanceWaterInstantPlus,
                                                        alt: ""
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "m-form__label",
                                                    attrs: {
                                                      for: "instantSensors"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "subprocess.children.surveillance.children.water.children.instant.form.fields.sensors.label"
                                                              )
                                                            ) +
                                                            "\n                                "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.instantSensors,
                                                          expression:
                                                            "instantSensors"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "m-form__control",
                                                      attrs: {
                                                        id: "instantSensors",
                                                        type: "number",
                                                        min: "0",
                                                        placeholder: _vm.$t(
                                                          "subprocess.children.surveillance.children.water.children.instant.form.fields.sensors.placeholder"
                                                        )
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.instantSensors
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.instantSensors =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "m-form__message m-form__message--error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    " +
                                                            _vm._s(
                                                              _vm.$t(errors[0])
                                                            ) +
                                                            "\n                                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-btn-group m-btn-group--block"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "a-btn a-btn--primary  a-btn--big",
                                            attrs: { type: "submit" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "subprocess.children.surveillance.children.water.children.instant.actions.next"
                                                  )
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedType === "slices"
                      ? _c("MainFrame", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "columnRight",
                                fn: function() {
                                  return [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.imageRightSlices,
                                        alt: ""
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "columnLeft",
                                fn: function() {
                                  return [
                                    _c(
                                      "h1",
                                      {
                                        staticClass: "a-headline a-headline--1"
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.iconSurveillanceWaterSlices,
                                            alt: ""
                                          }
                                        }),
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "subprocess.children.surveillance.children.water.children.slices.title"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "subprocess.children.surveillance.children.water.children.slices.body"
                                          )
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("ProductNoWithPriceRow", {
                                      attrs: {
                                        "product-no": _vm.$t(
                                          "products.waterSlicesSensors.productNo"
                                        ),
                                        price: _vm.$t(
                                          "products.waterSlicesSensors.price"
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass:
                                        "m-form__group m-form__group--prefixed",
                                      attrs: {
                                        name: _vm.$t(
                                          "subprocess.children.surveillance.children.water.children.slices.form.fields.sensors.label"
                                        ),
                                        rules: "",
                                        tag: "div"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "m-form__prefix"
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          _vm.iconSurveillanceWaterSlicesPlus,
                                                        alt: ""
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "m-form__label",
                                                    attrs: {
                                                      for: "slicesSensors"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "subprocess.children.surveillance.children.water.children.slices.form.fields.sensors.label"
                                                              )
                                                            ) +
                                                            "\n                                "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.slicesSensors,
                                                          expression:
                                                            "slicesSensors"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "m-form__control",
                                                      attrs: {
                                                        id: "slicesSensors",
                                                        type: "number",
                                                        min: "0",
                                                        placeholder: _vm.$t(
                                                          "subprocess.children.surveillance.children.water.children.slices.form.fields.sensors.placeholder"
                                                        )
                                                      },
                                                      domProps: {
                                                        value: _vm.slicesSensors
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.slicesSensors =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "m-form__message m-form__message--error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    " +
                                                            _vm._s(
                                                              _vm.$t(errors[0])
                                                            ) +
                                                            "\n                                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-btn-group m-btn-group--block"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "a-btn a-btn--primary  a-btn--big",
                                            attrs: { type: "submit" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "subprocess.children.surveillance.children.water.children.slices.actions.next"
                                                  )
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }