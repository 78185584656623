<template>

    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" class="m-form" action="#">
                <MainFrame v-if="selectedType === 'init'">
                    <template v-slot:columnRight>
                        <img :src="imageRightIndex" alt="">
                    </template>
                    <template v-slot:columnLeft>
                        <h1 class="a-headline a-headline--1">
                            <img :src="iconSurveillanceWater" alt="">
                            {{ $t('subprocess.children.surveillance.children.water.title') }}
                        </h1>
                        <div v-html="$t('subprocess.children.surveillance.children.water.body')"></div>

                        <div class="m-btn-group m-btn-group--block">
                            <button @click.prevent="selectedType = 'instant'"
                               class="a-btn a-btn--primary  a-btn--big">
                                {{ $t('subprocess.children.surveillance.children.water.actions.instant') }}
                            </button>
                            <button @click.prevent="selectedType = 'slices'"
                               class="a-btn a-btn--primary  a-btn--big">
                                {{ $t('subprocess.children.surveillance.children.water.actions.slices') }}
                            </button>
                        </div>
                    </template>
                </MainFrame>

                <MainFrame v-if="selectedType === 'instant'">
                    <template v-slot:columnRight>
                        <img :src="imageRightInstant" alt="">
                    </template>
                    <template v-slot:columnLeft>
                        <h1 class="a-headline a-headline--1">
                            <img :src="iconSurveillanceWaterInstant" alt="">
                            {{ $t('subprocess.children.surveillance.children.water.children.instant.title') }}
                        </h1>
                        <div v-html="$t('subprocess.children.surveillance.children.water.children.instant.body')"></div>
                        <ProductNoWithPriceRow :product-no="$t('products.waterInstantSensors.productNo')"
                                               :price="$t('products.waterInstantSensors.price')"/>

                        <ValidationProvider
                            :name="$t('subprocess.children.surveillance.children.water.children.instant.form.fields.sensors.label')"
                            rules="" v-slot="{ errors }" tag="div"
                            class="m-form__group m-form__group--prefixed">
                            <div class="m-form__prefix">
                                <img :src="iconSurveillanceWaterInstantPlus" alt="">
                            </div>
                            <label class="m-form__label" for="instantSensors">
                                    <span class="sr-only">
                                        {{
                                            $t('subprocess.children.surveillance.children.water.children.instant.form.fields.sensors.label')
                                        }}
                                    </span>
                                <input v-model="instantSensors"
                                       class="m-form__control"
                                       id="instantSensors"
                                       type="number"
                                       min="0"
                                       :placeholder="$t('subprocess.children.surveillance.children.water.children.instant.form.fields.sensors.placeholder')"/>
                                <span class="m-form__message m-form__message--error">
                                        {{ $t(errors[0]) }}
                                    </span>
                            </label>
                        </ValidationProvider>

                        <div class="m-btn-group m-btn-group--block">
                            <button type="submit" class="a-btn a-btn--primary  a-btn--big">
                                {{
                                    $t('subprocess.children.surveillance.children.water.children.instant.actions.next')
                                }}
                            </button>
                        </div>
                    </template>
                </MainFrame>


                <MainFrame v-if="selectedType === 'slices'">
                    <template v-slot:columnRight>
                        <img :src="imageRightSlices" alt="">
                    </template>
                    <template v-slot:columnLeft>
                        <h1 class="a-headline a-headline--1">
                            <img :src="iconSurveillanceWaterSlices" alt="">
                            {{ $t('subprocess.children.surveillance.children.water.children.slices.title') }}
                        </h1>
                        <div v-html="$t('subprocess.children.surveillance.children.water.children.slices.body')"></div>
                        <ProductNoWithPriceRow :product-no="$t('products.waterSlicesSensors.productNo')"
                                               :price="$t('products.waterSlicesSensors.price')"/>

                        <ValidationProvider
                            :name="$t('subprocess.children.surveillance.children.water.children.slices.form.fields.sensors.label')"
                            rules="" v-slot="{ errors }" tag="div"
                            class="m-form__group m-form__group--prefixed">
                            <div class="m-form__prefix">
                                <img :src="iconSurveillanceWaterSlicesPlus" alt="">
                            </div>
                            <label class="m-form__label" for="slicesSensors">
                                    <span class="sr-only">
                                        {{
                                            $t('subprocess.children.surveillance.children.water.children.slices.form.fields.sensors.label')
                                        }}
                                    </span>
                                <input v-model="slicesSensors"
                                       class="m-form__control"
                                       id="slicesSensors"
                                       type="number"
                                       min="0"
                                       :placeholder="$t('subprocess.children.surveillance.children.water.children.slices.form.fields.sensors.placeholder')"/>
                                <span class="m-form__message m-form__message--error">
                                        {{ $t(errors[0]) }}
                                    </span>
                            </label>
                        </ValidationProvider>

                        <div class="m-btn-group m-btn-group--block">
                            <button type="submit" class="a-btn a-btn--primary  a-btn--big">
                                {{ $t('subprocess.children.surveillance.children.water.children.slices.actions.next') }}
                            </button>
                        </div>
                    </template>
                </MainFrame>

            </form>
        </ValidationObserver>

    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import AppIcons from '../../../mixins/app-icons';
import App from '../../../mixins/app';
import MainFrame from '../../../components/MainFrame';
import Notification from '../../../components/Notification';
import ProductNoWithPriceRow from '../../../components/ProductNoWithPriceRow';
import ImageRightIndex from "../../../../../images/pages/surveillance-water-index.jpeg";
import ImageRightInstant from "../../../../../images/pages/surveillance-water-instant.jpg";
import ImageRightSlices from "../../../../../images/pages/surveillance-water-slices.jpg";

export default {
    name: 'Water',
    components: {
        ProductNoWithPriceRow,
        MainFrame,
        ValidationObserver,
        ValidationProvider,
    },
    mixins: [App, AppIcons],
    data() {
        return {
            imageRightIndex: ImageRightIndex,
            imageRightInstant: ImageRightInstant,
            imageRightSlices: ImageRightSlices,
            currentInstantSensorsValue: 0,
            currentSlicesSensorsValue: 0,
            selectedType: 'init',
            instantSensors: 0,
            slicesSensors: 0,
        };
    },
    computed: {
        currentRoom() {
            return this.$store.getters['configuration/currentRoom']();
        },
        currentState() {
            return this.$store.getters['configuration/currentRoomUniqueSubprocessByType']('surveillance.water');
        },
    },
    beforeMount() {
        if (this.currentState) {
            this.instantSensors = parseInt(this.currentState.config.instantSensors, 10);
            this.slicesSensors = parseInt(this.currentState.config.slicesSensors, 10);
            this.currentInstantSensorsValue = this.instantSensors;
            this.currentSlicesSensorsValue = this.slicesSensors;
        }
    },
    methods: {
        onSubmit: function() {
            const instantSensors = parseInt(this.instantSensors, 10);
            const slicesSensors = parseInt(this.slicesSensors, 10);

            this.$store.dispatch('configuration/addSubprocess', {
                type: 'surveillance.water',
                room: this.currentRoom.id,
                config: {
                    instantSensors: instantSensors,
                    slicesSensors: slicesSensors,
                },
            }).then(() => {
                this.adddedOrRemovedProductNotification(
                    this.currentInstantSensorsValue,
                    instantSensors,
                    this.$t('subprocess.children.surveillance.children.water.children.instant.notifications.added'),
                    this.$t('subprocess.children.surveillance.children.water.children.instant.notifications.removed'),

                );
                this.adddedOrRemovedProductNotification(
                    this.currentSlicesSensorsValue,
                    slicesSensors,
                    this.$t('subprocess.children.surveillance.children.water.children.slices.notifications.added'),
                    this.$t('subprocess.children.surveillance.children.water.children.slices.notifications.removed'),
                );

                this.$router.push({name: 'surveillance'});
            });
        },
    },
};
</script>
