<template>
    <div class="m-progress">
        <span class="m-progress__status" :style="progress">&nbsp;</span>
    </div>
    <!--    <div>-->
    <!--        <progress :max="max" :value="current">{{current}} von {{max}}</progress>-->
    <!--    </div>-->
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        max: {
            type: Number,
            required: true,
        },
        current: {
            type: Number,
            default: 1,
            required: false,
        },
    },
    computed: {
        progress() {
            return 'width: ' + this.current / this.max * 100 + '%;';
        }
    }
};
</script>
