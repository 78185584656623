var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("global.attributes.productNo")) +
          " " +
          _vm._s(_vm.productNo)
      ),
      _c("br"),
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("global.attributes.priceFrom")) +
          " " +
          _vm._s(_vm.price) +
          " EUR"
      ),
      _c("FootnotePriceIndicator"),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }