var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "m-form__group m-form__group--prefixed m-form__group--suffixed ",
      class: { "": _vm.hasInfo }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("label", { staticClass: "m-form__label", attrs: { for: "doors" } }, [
        _c("span", { staticClass: "sr-only" }, [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _vm.type === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value"
                }
              ],
              staticClass: "m-form__control",
              attrs: {
                id: "doors",
                placeholder: _vm.placeholder,
                type: "checkbox"
              },
              domProps: {
                checked: Array.isArray(_vm.value)
                  ? _vm._i(_vm.value, null) > -1
                  : _vm.value
              },
              on: {
                change: function($event) {
                  var $$a = _vm.value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.value = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.value = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.value = $$c
                  }
                }
              }
            })
          : _vm.type === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value"
                }
              ],
              staticClass: "m-form__control",
              attrs: {
                id: "doors",
                placeholder: _vm.placeholder,
                type: "radio"
              },
              domProps: { checked: _vm._q(_vm.value, null) },
              on: {
                change: function($event) {
                  _vm.value = null
                }
              }
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value"
                }
              ],
              staticClass: "m-form__control",
              attrs: {
                id: "doors",
                placeholder: _vm.placeholder,
                type: _vm.type
              },
              domProps: { value: _vm.value },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.value = $event.target.value
                }
              }
            }),
        _vm._v(" "),
        _vm.errors && _vm.errors.length
          ? _c(
              "span",
              { staticClass: "m-form__message m-form__message--error" },
              [_vm._v(_vm._s(_vm.$t(_vm.errors[0])))]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.hasInfo
        ? _c("div", { staticClass: "m-form__suffix" }, [
            _c("img", {
              attrs: {
                src: "https://picsum.photos/88/88",
                alt: "",
                title: _vm.info
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-form__prefix" }, [
      _c("img", { attrs: { src: "https://picsum.photos/88/88", alt: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }