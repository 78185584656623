var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainFrame", {
    attrs: {
      "has-progress-bar": true,
      "progress-bar-current": _vm.progressCurrent,
      "progress-bar-max": _vm.progressMax
    },
    scopedSlots: _vm._u([
      {
        key: "columnRight",
        fn: function() {
          return [_c("img", { attrs: { src: _vm.imageRight, alt: "" } })]
        },
        proxy: true
      },
      {
        key: "columnLeft",
        fn: function() {
          return [
            _c("h1", { staticClass: "a-headline a-headline--1" }, [
              _c("img", {
                attrs: { src: _vm.iconLightDoubleRockerSwitch, alt: "" }
              }),
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$t(
                      "subprocess.children.light.children.doubleRockerSwitch.title"
                    )
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t(
                    "subprocess.children.light.children.doubleRockerSwitch.bodyBridge1"
                  )
                )
              }
            }),
            _vm._v(" "),
            _c("ProductNoWithPriceRow", {
              attrs: {
                "product-no": _vm.$t(
                  "products.lightDoubleRockerSwitch.productNo"
                ),
                price: _vm.$t("products.lightDoubleRockerSwitch.price")
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "m-btn-group m-btn-group--block" }, [
              _c(
                "button",
                {
                  staticClass: "a-btn a-btn--primary a-btn--big",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "subprocess.children.light.children.doubleRockerSwitch.actions.yes"
                        )
                      ) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "a-btn a-btn--secondary a-btn--big",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onBack()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("global.actions.back")) +
                      "\n            "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t(
                    "subprocess.children.light.children.doubleRockerSwitch.bodyBridge2"
                  )
                )
              }
            }),
            _vm._v(" "),
            _c("ProductNoWithPriceRow", {
              attrs: {
                "product-no": _vm.$t(
                  "products.lightSingleRockerSwitch.productNo"
                ),
                price: _vm.$t("products.lightSingleRockerSwitch.price")
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "m-btn-group m-btn-group--block" }, [
              _c(
                "button",
                {
                  staticClass: "a-btn a-btn--primary a-btn--big",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onSkipStep()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "subprocess.children.light.children.doubleRockerSwitch.actions.no"
                        )
                      ) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "a-btn a-btn--secondary a-btn--big",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onBack()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("global.actions.back")) +
                      "\n            "
                  )
                ]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }