import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';
import VuexPersistence from 'vuex-persist';
import configuration from './modules/configuration';

Vue.use(Vuex);

// let axiosInstance = axios.create({
//   headers: {
//     'Accept': 'application/json',
//     'Content-Type': 'application/json',
//   },
// });

const debug = process.env.NODE_ENV !== 'production';
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

// const vuexApi = new VuexPersistence({
//   restoreState: (key, storage) => {
//     Cookies.getJSON(key)
//   },
//   saveState: (key, state, storage) => {
//     Cookies.set(key, state, {
//       expires: 3
//     });
//   },
//   // asyncStorage: true,
//
// })

export default new Vuex.Store({
  modules: {
    configuration
  },
  plugins: [vuexLocal.plugin],
  strict: debug,
});
