<template>
    <div>
        <MainFrame>
            <template v-slot:columnRight>
                <img :src="imageRightIndex" alt="">
            </template>
            <template v-slot:columnLeft>
                <h1 class="a-headline a-headline--1">{{ $t('preconfigure.title') }}</h1>
                <div v-html="$t('preconfigure.body')"></div>

                <div class="m-btn-group m-btn-group--block">
                    <button @click.prevent="onSubmit()" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('preconfigure.actions.yes') }}
                    </button>
                    <button @click.prevent="onSkipStep()" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('preconfigure.actions.no') }}
                    </button>
                </div>
            </template>
        </MainFrame>
    </div>
</template>

<script>
import App from '../mixins/app';
import MainFrame from '../components/MainFrame';
import imageRightIndex from "../../../images/pages/preconfigure-index.png";

export default {
    name: 'Preconfigure',
    components: {MainFrame},
    mixins: [App],
    data() {
        return {
            imageRightIndex: imageRightIndex,
        };
    },
    methods: {
        onSubmit: function() {
            this.$store.dispatch('configuration/setPreconfigureOptions', true).then(() => {
                this.$router.push({name: 'save'});
            });

        },
        onSkipStep: function() {
            this.$store.dispatch('configuration/setPreconfigureOptions', false).then(() => {
                this.$router.push({name: 'save'});
            });
        },
    },
};
</script>
