<template>
    <sup v-tooltip="{ content:$t('global.footnotes.price')}">*</sup>
</template>

<script>
export default {
name: "FootnotePriceIndicator"
}
</script>

<style scoped>

</style>
