var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentStep === "init"
        ? _c("MainFrame", {
            scopedSlots: _vm._u(
              [
                {
                  key: "columnRight",
                  fn: function() {
                    return [
                      _c("img", { attrs: { src: _vm.imageRight, alt: "" } })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "columnLeft",
                  fn: function() {
                    return [
                      _c(
                        "h1",
                        { staticClass: "a-headline a-headline--completed" },
                        [
                          _c("img", {
                            attrs: { src: _vm.iconCompleted, alt: "" }
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "saveConfiguration.children.success.title"
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("saveConfiguration.children.success.body")
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "h-completed" }, [
                        _c("span", { staticClass: "h-big" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("saveConfiguration.children.success.code")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "h-code" }, [
                          _vm._v(_vm._s(_vm.currentCode))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "m-btn-group m-btn-group--block" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              attrs: { type: "submit" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showForm("offer")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "saveConfiguration.children.success.offer"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              attrs: { type: "submit" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showForm("consultation")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "saveConfiguration.children.success.consultation"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "a-btn a-btn--secondary a-btn--big a-btn--block",
                              attrs: { to: { name: "index" } }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "saveConfiguration.children.success.goBack"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2884916426
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    staticClass: "m-form",
                    attrs: { action: "#" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _vm.currentStep === "form"
                      ? _c("MainFrame", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "columnRight",
                                fn: function() {
                                  return [
                                    _c("img", {
                                      attrs: { src: _vm.imageRight, alt: "" }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "columnLeft",
                                fn: function() {
                                  return [
                                    _vm.formType === "offer"
                                      ? _c(
                                          "h1",
                                          {
                                            staticClass:
                                              "a-headline a-headline--1"
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "saveConfiguration.children.contact.title.offer"
                                                  )
                                                ) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.formType === "consultation"
                                      ? _c(
                                          "h1",
                                          {
                                            staticClass:
                                              "a-headline a-headline--1"
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "saveConfiguration.children.contact.title.consultation"
                                                  )
                                                ) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass: "m-form__group",
                                      attrs: {
                                        name: _vm.$t(
                                          "saveConfiguration.children.contact.form.fields.name.label"
                                        ),
                                        rules: "required",
                                        tag: "div"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "m-form__label",
                                                    attrs: { for: "formName" }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "saveConfiguration.children.contact.form.fields.name.label"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.formName,
                                                          expression: "formName"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "m-form__control",
                                                      attrs: {
                                                        id: "formName",
                                                        type: "text",
                                                        min: "0",
                                                        placeholder: _vm.$t(
                                                          "saveConfiguration.children.contact.form.fields.name.placeholder"
                                                        )
                                                      },
                                                      domProps: {
                                                        value: _vm.formName
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.formName =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    errors && errors.length
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "m-form__message m-form__message--error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    errors[0]
                                                                  )
                                                                ) +
                                                                "\n                                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass: "m-form__group",
                                      attrs: {
                                        name: _vm.$t(
                                          "saveConfiguration.children.contact.form.fields.email.label"
                                        ),
                                        rules: "required",
                                        tag: "div"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "m-form__label",
                                                    attrs: { for: "formEmail" }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "saveConfiguration.children.contact.form.fields.email.label"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.formEmail,
                                                          expression:
                                                            "formEmail"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "m-form__control",
                                                      attrs: {
                                                        id: "formEmail",
                                                        type: "email",
                                                        min: "0",
                                                        placeholder: _vm.$t(
                                                          "saveConfiguration.children.contact.form.fields.email.placeholder"
                                                        )
                                                      },
                                                      domProps: {
                                                        value: _vm.formEmail
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.formEmail =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    errors && errors.length
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "m-form__message m-form__message--error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    errors[0]
                                                                  )
                                                                ) +
                                                                "\n                                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass: "m-form__group",
                                      attrs: {
                                        name: _vm.$t(
                                          "saveConfiguration.children.contact.form.fields.phone.label"
                                        ),
                                        rules: "",
                                        tag: "div"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "m-form__label",
                                                    attrs: { for: "formPhone" }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "saveConfiguration.children.contact.form.fields.phone.label"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.formPhone,
                                                          expression:
                                                            "formPhone"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "m-form__control",
                                                      attrs: {
                                                        id: "formPhone",
                                                        type: "tel",
                                                        min: "0",
                                                        placeholder: _vm.$t(
                                                          "saveConfiguration.children.contact.form.fields.phone.placeholder"
                                                        )
                                                      },
                                                      domProps: {
                                                        value: _vm.formPhone
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.formPhone =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    errors && errors.length
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "m-form__message m-form__message--error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    errors[0]
                                                                  )
                                                                ) +
                                                                "\n                                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass: "m-form__group",
                                      attrs: {
                                        name: _vm.$t(
                                          "saveConfiguration.children.contact.form.fields.privacyPolicy.label"
                                        ),
                                        rules: "required",
                                        tag: "div"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.formTerms,
                                                      expression: "formTerms"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "m-form__control",
                                                  attrs: {
                                                    id: "formPrivacyPolicy",
                                                    type: "checkbox",
                                                    required: ""
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.formTerms
                                                    )
                                                      ? _vm._i(
                                                          _vm.formTerms,
                                                          null
                                                        ) > -1
                                                      : _vm.formTerms
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a = _vm.formTerms,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.formTerms = $$a.concat(
                                                              [$$v]
                                                            ))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.formTerms = $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                        }
                                                      } else {
                                                        _vm.formTerms = $$c
                                                      }
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "m-form__label",
                                                    attrs: {
                                                      for: "formPrivacyPolicy"
                                                    }
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$t(
                                                            "saveConfiguration.children.contact.form.fields.privacyPolicy.label",
                                                            {
                                                              url: _vm.$t(
                                                                "global.footerMenu.items.privacy.href"
                                                              )
                                                            }
                                                          )
                                                        )
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    errors && errors.length
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "m-form__message m-form__message--error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    errors[0]
                                                                  )
                                                                ) +
                                                                "\n                                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-btn-group m-btn-group--block"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "a-btn a-btn--primary  a-btn--big",
                                            attrs: { type: "submit" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "saveConfiguration.children.contact.actions.send"
                                                  )
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }