var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentStep === "init"
        ? _c("MainFrame", {
            attrs: {
              "has-progress-bar": true,
              "progress-bar-current": _vm.progressCurrent,
              "progress-bar-max": _vm.progressMax
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "columnRight",
                  fn: function() {
                    return [
                      _c("img", {
                        attrs: { src: _vm.imageRightIndex, alt: "" }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "columnLeft",
                  fn: function() {
                    return [
                      _c("h1", { staticClass: "a-headline a-headline--1" }, [
                        _c("img", {
                          attrs: { src: _vm.iconShadingTubularMotor, alt: "" }
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t(
                                "subprocess.children.shading.children.tubularMotor.title"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "subprocess.children.shading.children.tubularMotor.body"
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "m-btn-group m-btn-group--block" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSkipStep()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "subprocess.children.shading.children.tubularMotor.actions.yes"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.currentStep = "options"
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "subprocess.children.shading.children.tubularMotor.actions.no"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--secondary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onBack()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("global.actions.back")) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              288767582
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    staticClass: "m-form",
                    attrs: { action: "#" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      }
                    }
                  },
                  [
                    _vm.currentStep === "options"
                      ? _c("MainFrame", {
                          attrs: {
                            "has-progress-bar": true,
                            "progress-bar-current": _vm.progressCurrent,
                            "progress-bar-max": _vm.progressMax
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "columnRight",
                                fn: function() {
                                  return [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.imageRightOptions,
                                        alt: ""
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "columnLeft",
                                fn: function() {
                                  return [
                                    _c(
                                      "h1",
                                      {
                                        staticClass: "a-headline a-headline--1"
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.iconShadingTubularMotor,
                                            alt: ""
                                          }
                                        }),
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "subprocess.children.shading.children.tubularMotor.children.options.title"
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "subprocess.children.shading.children.tubularMotor.children.options.body"
                                          )
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass: "m-form__group",
                                      attrs: {
                                        name: _vm.$t(
                                          "subprocess.children.shading.children.tubularMotor.children.options.form.fields.width.label"
                                        ),
                                        rules: "required",
                                        tag: "div"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "m-form__label",
                                                    attrs: {
                                                      for: "windowWidth"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "subprocess.children.shading.children.tubularMotor.children.options.form.fields.width.label"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.windowWidth,
                                                          expression:
                                                            "windowWidth"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "m-form__control",
                                                      attrs: {
                                                        id: "windowWidth",
                                                        type: "number",
                                                        min: "0",
                                                        placeholder: _vm.$t(
                                                          "subprocess.children.shading.children.tubularMotor.children.options.form.fields.width.placeholder"
                                                        )
                                                      },
                                                      domProps: {
                                                        value: _vm.windowWidth
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.windowWidth =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    errors && errors.length
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "m-form__message m-form__message--error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    errors[0]
                                                                  )
                                                                ) +
                                                                "\n                                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass: "m-form__group",
                                      attrs: {
                                        name: _vm.$t(
                                          "subprocess.children.shading.children.tubularMotor.children.options.form.fields.height.label"
                                        ),
                                        rules: "required",
                                        tag: "div"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "m-form__label",
                                                    attrs: {
                                                      for: "windowHeight"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "subprocess.children.shading.children.tubularMotor.children.options.form.fields.height.label"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.windowHeight,
                                                          expression:
                                                            "windowHeight"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "m-form__control",
                                                      attrs: {
                                                        id: "windowHeight",
                                                        type: "number",
                                                        min: "0",
                                                        placeholder: _vm.$t(
                                                          "subprocess.children.shading.children.tubularMotor.children.options.form.fields.height.placeholder"
                                                        )
                                                      },
                                                      domProps: {
                                                        value: _vm.windowHeight
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.windowHeight =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    errors && errors.length
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "m-form__message m-form__message--error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    errors[0]
                                                                  )
                                                                ) +
                                                                "\n                                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass: "m-form__group",
                                      attrs: {
                                        name: _vm.$t(
                                          "subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.label"
                                        ),
                                        rules:
                                          "required|oneOf:wood,plastic,aluminium",
                                        tag: "div"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "m-form__label",
                                                    attrs: {
                                                      for: "shutterMaterial"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.label"
                                                              )
                                                            ) +
                                                            "\n                            "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.shutterMaterial,
                                                            expression:
                                                              "shutterMaterial"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "m-form__control",
                                                        attrs: {
                                                          id: "shutterMaterial"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.shutterMaterial = $event
                                                              .target.multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: { value: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.placeholder"
                                                                  )
                                                                ) +
                                                                "\n                                "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "wood"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.options.wood"
                                                                  )
                                                                ) +
                                                                "\n                                "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "plastic"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.options.plastic"
                                                                  )
                                                                ) +
                                                                "\n                                "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "aluminium"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.options.aluminium"
                                                                  )
                                                                ) +
                                                                "\n                                "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    errors && errors.length
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "m-form__message m-form__message--error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    errors[0]
                                                                  )
                                                                ) +
                                                                "\n                                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "m-btn-group m-btn-group--block"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "a-btn a-btn--primary  a-btn--big",
                                            attrs: { type: "submit" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.onSelect($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "subprocess.children.shading.children.tubularMotor.children.options.actions.next"
                                                  )
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "a-btn a-btn--secondary",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.onSkipStep()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "subprocess.children.shading.children.tubularMotor.children.options.actions.cancel"
                                                  )
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "a-btn a-btn--secondary a-btn--big",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.onBack()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.$t("global.actions.back")
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _vm.currentStep === "repeat"
        ? _c("MainFrame", {
            attrs: {
              "has-progress-bar": true,
              "progress-bar-current": _vm.progressCurrent,
              "progress-bar-max": _vm.progressMax
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "columnRight",
                  fn: function() {
                    return [
                      _c("img", {
                        attrs: { src: _vm.imageRightRepeat, alt: "" }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "columnLeft",
                  fn: function() {
                    return [
                      _c("h1", { staticClass: "a-headline a-headline--1" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.iconShadingTubularMotorPlus,
                            alt: ""
                          }
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t(
                                "subprocess.children.shading.children.tubularMotor.children.repeat.title"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "subprocess.children.shading.children.tubularMotor.children.repeat.body"
                            )
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "m-btn-group m-btn-group--block" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.currentStep = "options"
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "subprocess.children.shading.children.tubularMotor.children.repeat.actions.yes"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--primary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSubmit()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "subprocess.children.shading.children.tubularMotor.children.repeat.actions.no"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "a-btn a-btn--secondary a-btn--big",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onBack()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("global.actions.back")) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3455845034
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }