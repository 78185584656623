<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" class="m-form" action="#">
                <MainFrame>
                    <template v-slot:columnRight>
                        <img :src="imageRight" alt="">
                    </template>
                    <template v-slot:columnLeft>
                        <h1 class="a-headline a-headline--1">
                            <img :src="iconAddRoom" alt="">
                            <span v-if="isEditView">{{ $t('editRoom.title') }}</span>
                            <span v-else>{{ $t('addRoom.title') }}</span>
                        </h1>
                        <div v-if="!isEditView" v-html="$t('addRoom.body')"></div>
                        <ValidationProvider :name="$t('addRoom.form.fields.roomName.label')"
                                            rules="required" v-slot="{ errors }" tag="div"
                                            class="m-form__group">
                            <label class="m-form__label" for="roomName">
                                <span class="sr-only">                                        {{
                                        $t('addRoom.form.fields.roomName.label')
                                    }}                                    </span>
                                <input v-model="roomName"
                                       class="m-form__control"
                                       id="roomName"
                                       type="text"
                                       min="0"
                                       :placeholder="$t('addRoom.form.fields.roomName.placeholder')"/>
                                <span class="m-form__message m-form__message--error">
                                        {{ $t(errors[0]) }}
                                    </span>
                                <span v-if="rooms && rooms.length" class="m-form__message m-form__message--info">
                                    {{
                                        $t('addRoom.form.fields.roomName.hint',
                                            {names: rooms.map(room => room.name).join(', ')})
                                    }}
                                </span>
                            </label>
                        </ValidationProvider>

                        <div class="m-btn-group m-btn-group--left">
                            <button type="submit" class="a-btn a-btn--primary">
                                {{ $t('addRoom.actions.next') }}
                            </button>
                            <router-link v-if="rooms && rooms.length" :to="{name: 'subprocesses'}" class="a-btn a-btn--secondary">
                                {{ $t('addRoom.actions.cancel') }}
                            </router-link>
                        </div>
                    </template>
                </MainFrame>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import ImageRight from '../../../images/pages/add-room.jpeg';
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import AppIcons from '../mixins/app-icons';
import App from '../mixins/app';
import MainFrame from '../components/MainFrame';
import Notification from '../components/Notification';

extend('required', {
    ...required,
    message: 'global.forms.errors.required',
});
export default {
    name: 'AddRoom',
    components: {
        MainFrame,
        ValidationObserver,
        ValidationProvider,
    },
    mixins: [App, AppIcons],
    data() {
        return {
            imageRight: ImageRight,
            roomName: '',
            isEditView: false,
        };
    },
    computed: {
        rooms: function() {
            return this.$store.getters['configuration/roomSet']();
        },
    },
    mounted() {
        if (this.$route.params.hasOwnProperty('id') && this.$route.params.id) {
            const find = this.rooms.find(room => room.id === this.$route.params.id);
            if(find) {
                this.roomName = find.name;
                this.isEditView = true;
            }
        }
    },
    methods: {
        onSubmit: function() {
            if(this.isEditView) {
                this.$store.dispatch('configuration/editRoom', {id: this.$route.params.id, name: this.roomName}).then(() => {
                    this.$toast.success({component: Notification, props: {
                            content: this.$t('addRoom.notifications.edited'),
                        }});
                    this.$router.push({name: 'subprocesses'});
                });
            } else {
                this.$store.dispatch('configuration/addRoom', this.roomName).then(() => {
                    this.$toast.success({component: Notification, props: {
                            content: this.$t('addRoom.notifications.added'),
                        }});
                    this.$router.push({name: 'subprocesses'});
                });
            }

        },
    },
};
</script>
