var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainFrame", {
    attrs: { "has-divider": false, "has-right-column-slot": false },
    scopedSlots: _vm._u(
      [
        _vm.currentRoom
          ? {
              key: "columnLeft",
              fn: function() {
                return [
                  _c("h1", { staticClass: "a-headline a-headline--1" }, [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.showRoomSelection = !_vm.showRoomSelection
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.currentRoom.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: _vm.$t("subprocess.actions.editRoom")
                            },
                            expression:
                              "{ content:$t('subprocess.actions.editRoom')}"
                          }
                        ],
                        staticClass: "a-headline__action",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onEditRoom(_vm.currentRoom)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            size: "50",
                            src: _vm.iconPen,
                            alt: _vm.$t("subprocess.actions.editRoom")
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(_vm._s(_vm.$t("subprocess.actions.editRoom")))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: _vm.$t("subprocess.actions.deleteRoom")
                            },
                            expression:
                              "{ content:$t('subprocess.actions.deleteRoom')}"
                          }
                        ],
                        staticClass: "a-headline__action",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onDeleteRoom(_vm.currentRoom)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            size: "50",
                            src: _vm.iconTrash,
                            alt: _vm.$t("subprocess.actions.deleteRoom")
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v(
                            _vm._s(_vm.$t("subprocess.actions.deleteRoom"))
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showRoomSelection,
                            expression: "showRoomSelection"
                          }
                        ],
                        staticClass: "m-select"
                      },
                      _vm._l(_vm.getRooms(), function(room) {
                        return _c(
                          "li",
                          {
                            key: room.id,
                            staticClass: "m-select__item",
                            class: {
                              "m-select__item--selected":
                                room.id === _vm.selectedRoom
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "m-select__link",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.selectedRoom = room.id
                                    _vm.showRoomSelection = !_vm.showRoomSelection
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(room.name) +
                                    "\n                        "
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid__row" }, [
                    _c("div", { staticClass: "grid_col grid_col--offset-1" }, [
                      _c("ul", { staticClass: "o-cards o-cards--thirds" }, [
                        _c(
                          "li",
                          {
                            staticClass: "m-card m-card--light",
                            class: { "m-card--checked": _vm.lightCompleted }
                          },
                          [
                            _vm.lightCompleted
                              ? _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content: _vm.$t(
                                            "subprocess.actions.resetSubprocess"
                                          )
                                        },
                                        expression:
                                          "{ content:$t('subprocess.actions.resetSubprocess')}"
                                      }
                                    ],
                                    staticClass: "m-card__reset",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.onResetSubprocess(
                                          _vm.currentRoom.id,
                                          "light"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.iconReset,
                                        alt: _vm.$t(
                                          "subprocess.actions.resetSubprocess"
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "subprocess.actions.resetSubprocess"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "m-card__trigger",
                                attrs: { to: { name: "light" } }
                              },
                              [
                                _c("div", { staticClass: "m-card__title" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "subprocess.actions.subprocessLight"
                                        )
                                      ) +
                                      "\n                                "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass: "m-card m-card--shading",
                            class: { "m-card--checked": _vm.shadingCompleted }
                          },
                          [
                            _vm.shadingCompleted
                              ? _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content: _vm.$t(
                                            "subprocess.actions.resetSubprocess"
                                          )
                                        },
                                        expression:
                                          "{ content:$t('subprocess.actions.resetSubprocess')}"
                                      }
                                    ],
                                    staticClass: "m-card__reset",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.onResetSubprocess(
                                          _vm.currentRoom.id,
                                          "shading"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.iconReset,
                                        alt: _vm.$t(
                                          "subprocess.actions.resetSubprocess"
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "subprocess.actions.resetSubprocess"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "m-card__trigger",
                                attrs: { to: { name: "shading" } }
                              },
                              [
                                _c("div", { staticClass: "m-card__title" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "subprocess.actions.subprocessShading"
                                        )
                                      ) +
                                      "\n                                "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass: "m-card m-card--surveillance",
                            class: {
                              "m-card--checked": _vm.surveillanceCompleted
                            }
                          },
                          [
                            _vm.surveillanceCompleted
                              ? _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          content: _vm.$t(
                                            "subprocess.actions.resetSubprocess"
                                          )
                                        },
                                        expression:
                                          "{ content:$t('subprocess.actions.resetSubprocess')}"
                                      }
                                    ],
                                    staticClass: "m-card__reset",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.onResetSubprocess(
                                          _vm.currentRoom.id,
                                          "surveillance"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.iconReset,
                                        alt: _vm.$t(
                                          "subprocess.actions.resetSubprocess"
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "subprocess.actions.resetSubprocess"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "m-card__trigger",
                                attrs: { to: { name: "surveillance" } }
                              },
                              [
                                _c("div", { staticClass: "m-card__title" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "subprocess.actions.subprocessSurveillance"
                                        )
                                      ) +
                                      "\n                                "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "m-btn-group m-btn-group--right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "a-btn a-btn--primary",
                          attrs: { to: { name: "room" } }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("subprocess.actions.addMoreRooms")
                              ) +
                              "\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "a-btn a-btn--primary",
                          attrs: { to: { name: "overview" } }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("subprocess.actions.continue")) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }