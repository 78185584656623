<template>
    <MainFrame>
        <template v-slot:columnRight>
            <img :src="imageRight" alt="">
        </template>
        <template v-slot:columnLeft>
            <h1 class="a-headline a-headline--1">{{ $t('controlSystem.title') }}</h1>
            <div v-html="$t('controlSystem.body')"></div>

            <div class="m-btn-group m-btn-group--left">
                <button v-for="option in options" :key="option.value"
                        @click.prevent="onSubmit(option.value)"
                        class="a-btn a-btn--primary a-btn--big">
                    {{ option.label }}
                </button>
            </div>
        </template>
    </MainFrame>
</template>

<script>
import App from '../mixins/app';
import MainFrame from '../components/MainFrame';
import ImageRight from "../../../images/pages/control-system.jpg";

export default {
    name: 'ControlSystem',
    components: {MainFrame},
    mixins: [App],
    data() {
        return {
            imageRight: ImageRight,
            options: [
                {value: 'amazonAlexa', label: this.$t('controlSystem.actions.amazonAlexa')},
                {value: 'appleHomeKit', label: this.$t('controlSystem.actions.appleHomeKit')},
                // {value: 'googleHome', label: this.$t('controlSystem.actions.googleHome')},
                {value: 'none', label: this.$t('controlSystem.actions.none')},
            ],
        };
    },
    methods: {
        onSubmit: function(value) {
            this.$store.dispatch('configuration/setControlSystems', [value]).then(() => {
                this.$router.push({name: 'overview'});
            });
        },
        onSkipStep: function() {
            this.$router.push({name: 'overview'});
        },
    },
};
</script>
