<template>

    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" class="m-form" action="#">
                <MainFrame>
                    <template v-slot:columnRight>
                        <img :src="imageRight" alt="">
                    </template>
                    <template v-slot:columnLeft>
                        <h1 class="a-headline a-headline--1">
                            <img :src="iconSurveillanceDoorWindow" alt="">
                            {{ $t('subprocess.children.surveillance.children.door.title') }}</h1>
                        <div v-html="$t('subprocess.children.surveillance.children.door.body')"></div>
                        <ProductNoWithPriceRow :product-no="$t('products.doorSensor.productNo')"
                                               :price="$t('products.doorSensor.price')"/>

                        <ValidationProvider
                            :name="$t('subprocess.children.surveillance.children.door.form.fields.doors.label')"
                            rules=""
                            v-slot="{ errors }" tag="div"
                            class="m-form__group m-form__group--prefixed m-form__group--suffixed ">
                            <div class="m-form__prefix">
                                <img :src="iconSurveillanceDoorPlus" alt="">
                            </div>
                            <label class="m-form__label" for="doors">
                                        <span class="sr-only">
                                            {{
                                                $t('subprocess.children.surveillance.children.door.form.fields.doors.label')
                                            }}
                                        </span>
                                <input v-model="doors"
                                       class="m-form__control"
                                       id="doors"
                                       type="number"
                                       min="0"
                                       :placeholder="$t('subprocess.children.surveillance.children.door.form.fields.doors.placeholder')"/>
                                <span v-if="errors && errors.length" class="m-form__message m-form__message--error">
                                            {{ $t(errors[0]) }}
                                        </span>
                            </label>
                            <div class="m-form__suffix">
                                <img :src="iconInfo" alt=""
                                     v-tooltip="{ content:$t('subprocess.children.surveillance.children.door.form.fields.doors.info')}"/>
                            </div>
                        </ValidationProvider>

                        <ValidationProvider
                            :name="$t('subprocess.children.surveillance.children.door.form.fields.windows.label')"
                            rules="" v-slot="{ errors }" tag="div"
                            class="m-form__group m-form__group--prefixed">
                            <div class="m-form__prefix">
                                <img :src="iconSurveillanceWindowPlus" alt="">
                            </div>
                            <label class="m-form__label" for="windows">
                                    <span class="sr-only">
                                        {{
                                            $t('subprocess.children.surveillance.children.door.form.fields.windows.label')
                                        }}
                                    </span>
                                <input v-model="windows"
                                       class="m-form__control"
                                       id="windows"
                                       type="number"
                                       min="0"
                                       :placeholder="$t('subprocess.children.surveillance.children.door.form.fields.windows.placeholder')"/>
                                <span class="m-form__message m-form__message--error">
                                        {{ $t(errors[0]) }}
                                    </span>
                            </label>
                        </ValidationProvider>

                        <div class="m-btn-group m-btn-group--block">
                            <button type="submit" class="a-btn a-btn--primary  a-btn--big">
                                {{ $t('subprocess.children.surveillance.children.door.actions.next') }}
                            </button>
                        </div>
                    </template>
                </MainFrame>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import OpusInput from '../../../components/foms/OpusInput';
import AppIcons from '../../../mixins/app-icons';
import App from '../../../mixins/app';
import MainFrame from '../../../components/MainFrame';
import Notification from '../../../components/Notification';
import ProductNoWithPriceRow from '../../../components/ProductNoWithPriceRow';
import ImageRight from "../../../../../images/pages/surveillance-door.jpg";

export default {
    name: 'Door',
    components: {
        ProductNoWithPriceRow,
        MainFrame,
        OpusInput,
        ValidationObserver,
        ValidationProvider,
    },
    mixins: [App, AppIcons],
    data() {
        return {
            imageRight: ImageRight,
            currentValue: 0,
            doors: 0,
            windows: 0,
        };
    },
    computed: {
        currentRoom() {
            return this.$store.getters['configuration/currentRoom']();
        },
        currentState() {
            return this.$store.getters['configuration/currentRoomUniqueSubprocessByType']('surveillance.door');
        },
    },
    beforeMount() {
        if (this.currentState) {
            this.doors = parseInt(this.currentState.config.doors, 10);
            this.windows = parseInt(this.currentState.config.windows, 10);
            this.currentValue = this.doors + this.windows;
        }
    },
    methods: {
        onSubmit: function() {
            const doors = parseInt(this.doors, 10);
            const windows = parseInt(this.windows, 10);

            this.$store.dispatch('configuration/addSubprocess', {
                type: 'surveillance.door',
                room: this.currentRoom.id,
                config: {
                    doors: doors,
                    windows: windows,
                },
            }).then(() => {
                this.adddedOrRemovedProductNotification(
                    this.currentValue,
                    doors+windows,
                    this.$t('subprocess.children.surveillance.children.door.notifications.added'),
                    this.$t('subprocess.children.surveillance.children.door.notifications.removed'),
                    {name: 'surveillance'}
                );
            });
        },
    },
};
</script>
