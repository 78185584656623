<template>
    <div>
        <img v-if="icon" :src="icon" alt="">
        <div v-if="title">{{ title }}</div>
        <div v-html="content"/>
    </div>
</template>

<script>
export default {
    name: "Notification",
    props: ['title', 'content', 'icon']
}
</script>
