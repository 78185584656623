var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainFrame", {
    scopedSlots: _vm._u([
      {
        key: "columnLeft",
        fn: function() {
          return [
            _c("h1", { staticClass: "a-headline a-headline--1" }, [
              _vm._v(_vm._s(_vm.$t("privacyPolicy.title")))
            ]),
            _vm._v(" "),
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.$t("privacyPolicy.body")) }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }