<template>

    <MainFrame :has-divider="false" :has-right-column-slot="false">
        <template v-slot:columnLeft>
            <h1 class="a-headline a-headline--1">{{ $t('subprocess.children.surveillance.title') }}</h1>
            <div v-html="$t('subprocess.children.surveillance.body')"></div>

            <div class="grid__row">
                <div class="grid__col">
                    <ul class="o-cards o-cards--fill">
                        <li class="m-card"
                            :class="{'m-card--checked': subpage.completed}"
                            v-for="subpage in subpages">
                            <router-link class="m-card__trigger" :to="subpage.route">
                                <div v-if="subpage.icon" class="m-card__image">
                                    <img :src="subpage.icon" alt="">
                                </div>
                                <div class="m-card__title">{{ subpage.title }}</div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="m-btn-group m-btn-group--right">
                <router-link :to="{name: 'subprocesses'}" class="a-btn a-btn--primary">
                    {{ $t('subprocess.children.surveillance.cards.exit.title') }}
                </router-link>
            </div>
        </template>
    </MainFrame>
</template>

<script>

import AppIcons from '../../mixins/app-icons';
import App from '../../mixins/app';
import MainFrame from '../../components/MainFrame';

export default {
    name: 'Surveillance',
    components: {MainFrame},
    mixins: [App, AppIcons],
    data() {
        return {
            selectedRoom: null,
        };
    },
    computed: {
        subpages() {
            return [
                {
                    completed: this.isCompleted('surveillance.door'),
                    title: this.$t('subprocess.children.surveillance.cards.door.title'),
                    icon: this.iconSurveillanceHome,
                    route: {name: 'surveillanceDoors'},
                },
                {
                    completed: this.isCompleted('surveillance.smoke'),
                    title: this.$t('subprocess.children.surveillance.cards.smoke.title'),
                    icon: this.iconSurveillanceSmoke,
                    route: {name: 'surveillanceSmoke'},
                },
                {
                    completed: this.isCompleted('surveillance.heat'),
                    title: this.$t('subprocess.children.surveillance.cards.heat.title'),
                    icon: this.iconSurveillanceHeat,
                    route: {name: 'surveillanceHeat'},
                },
                {
                    completed: this.isCompleted('surveillance.temperature'),
                    title: this.$t('subprocess.children.surveillance.cards.temperature.title'),
                    icon: this.iconSurveillanceTemperature,
                    route: {name: 'surveillanceTemperature'},
                },
                {
                    completed: this.isCompleted('surveillance.motion'),
                    title: this.$t('subprocess.children.surveillance.cards.motion.title'),
                    icon: this.iconSurveillanceMotion,
                    route: {name: 'surveillanceMotion'},
                },
                {
                    completed: this.isCompleted('surveillance.water'),
                    title: this.$t('subprocess.children.surveillance.cards.water.title'),
                    icon: this.iconSurveillanceWater,
                    route: {name: 'surveillanceWater'},
                },
                // {
                //     completed: false,
                //     title: this.$t('subprocess.children.surveillance.cards.exit.title'),
                //     icon: false,
                //     route: {name: 'subprocesses'},
                // },
            ];
        },
    },
    methods: {
        isCompleted: function(type) {
            return !!this.$store.getters['configuration/currentRoomUniqueSubprocessByType'](type);
        },
    },
    mounted() {
        this.selectedRoom = this.currentRoom.id;
    },
};
</script>
