<template>

    <div>
        <MainFrame v-if="currentStep === 'init'"
                   :has-progress-bar="true"
                   :progress-bar-current="progressCurrent"
                   :progress-bar-max="progressMax">
            <template v-slot:columnRight>
                <img :src="imageRightIndex" alt="">
            </template>
            <template v-slot:columnLeft>
                <h1 class="a-headline a-headline--1">
                    <img :src="iconShadingShutterSwitch" alt="">
                    {{ $t('subprocess.children.shading.children.shutterSwitch.title') }}
                </h1>
                <div v-html="$t('subprocess.children.shading.children.shutterSwitch.body')"></div>
                <ProductNoWithPriceRow :product-no="$t('products.shadingShutterSwitch.productNo')"
                                       :price="$t('products.shadingShutterSwitch.price')"/>

                <div class="m-btn-group m-btn-group--block">
                    <button @click.prevent="onSelect()" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('subprocess.children.shading.children.shutterSwitch.actions.yes') }}
                    </button>

                    <!--                    :disabled="tubularMotorsNumber > shutterSwitches"-->
                    <button @click.prevent="onSkipStep()" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('subprocess.children.shading.children.shutterSwitch.actions.no') }}
                    </button>
                    <button @click.prevent="onBack()" class="a-btn a-btn--secondary a-btn--big">
                        {{ $t('global.actions.back') }}
                    </button>
                </div>
            </template>
        </MainFrame>

        <MainFrame v-if="currentStep === 'repeat'"
                   :has-progress-bar="true"
                   :progress-bar-current="progressCurrent"
                   :progress-bar-max="progressMax">
            <template v-slot:columnRight>
                <img :src="imageRightRepeat" alt="">
            </template>
            <template v-slot:columnLeft>
                <h1 class="a-headline a-headline--1">
                    <img :src="iconShadingShutterSwitchPlus" alt="">
                    {{ $t('subprocess.children.shading.children.shutterSwitch.children.repeat.title') }}
                </h1>
                <div v-html="$t('subprocess.children.shading.children.shutterSwitch.children.repeat.body')"></div>

                <div class="m-btn-group m-btn-group--block">
                    <button @click.prevent="currentStep = 'init'" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('subprocess.children.shading.children.shutterSwitch.children.repeat.actions.yes') }}
                    </button>
                    <button @click.prevent="onSubmit()"
                            class="a-btn a-btn--primary a-btn--big">
                        {{ $t('subprocess.children.shading.children.shutterSwitch.children.repeat.actions.no') }}
                    </button>
                    <button @click.prevent="onBack()" class="a-btn a-btn--secondary a-btn--big">
                        {{ $t('global.actions.back') }}
                    </button>
                </div>
            </template>
        </MainFrame>
    </div>
</template>

<script>
import ProgressBar from '../../ProgressBar';
import MainFrame from '../../MainFrame';
import AppIcons from '../../../mixins/app-icons';
import ProductNoWithPriceRow from '../../ProductNoWithPriceRow';
import ImageRightIndex from "../../../../../images/pages/shading-shutter-switch-index.jpeg";
import ImageRightRepeat from "../../../../../images/pages/shading-shutter-switch-index.jpeg";

export default {
    name: 'ShadingShutterSwitch',
    components: {ProductNoWithPriceRow, MainFrame, ProgressBar},
    mixins: [AppIcons],
    props: {
        progressMax: {
            type: Number,
            required: true,
        },
        progressCurrent: {
            type: Number,
            required: true,
            default: 1,
        },
        tubularMotorsNumber: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    data() {
        return {
            imageRightIndex: ImageRightIndex,
            imageRightRepeat: ImageRightRepeat,
            currentStep: 'init',
            shutterSwitches: 0,
        };
    },
    methods: {
        onSelect: function() {
            this.shutterSwitches += 1;
            this.currentStep = 'repeat';
        },
        onSubmit: function() {
            this.$emit('subprocessShadingShutterSwitchAdd', this.shutterSwitches);
        },
        onSkipStep: function() {
            this.$emit('subprocessShadingShutterSwitchSkip', this.shutterSwitches);
        },
        onBack: function() {
            this.$emit('subprocessShadingShutterSwitchSkipAndExit', this.shutterSwitches);
        },
    },
};
</script>
