var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainFrame", {
    scopedSlots: _vm._u([
      {
        key: "columnRight",
        fn: function() {
          return [_c("img", { attrs: { src: _vm.imageRight, alt: "" } })]
        },
        proxy: true
      },
      {
        key: "columnLeft",
        fn: function() {
          return [
            _c("h1", { staticClass: "a-headline a-headline--1" }, [
              _vm._v(_vm._s(_vm.$t("controlSystem.title")))
            ]),
            _vm._v(" "),
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.$t("controlSystem.body")) }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "m-btn-group m-btn-group--left" },
              _vm._l(_vm.options, function(option) {
                return _c(
                  "button",
                  {
                    key: option.value,
                    staticClass: "a-btn a-btn--primary a-btn--big",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onSubmit(option.value)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(option.label) +
                        "\n            "
                    )
                  ]
                )
              }),
              0
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }