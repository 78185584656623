import { render, staticRenderFns } from "./TermsOfUse.vue?vue&type=template&id=41738dea&scoped=true&"
import script from "./TermsOfUse.vue?vue&type=script&lang=js&"
export * from "./TermsOfUse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41738dea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41738dea')) {
      api.createRecord('41738dea', component.options)
    } else {
      api.reload('41738dea', component.options)
    }
    module.hot.accept("./TermsOfUse.vue?vue&type=template&id=41738dea&scoped=true&", function () {
      api.rerender('41738dea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/app/pages/TermsOfUse.vue"
export default component.exports