var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sup",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: { content: _vm.$t("global.footnotes.price") },
          expression: "{ content:$t('global.footnotes.price')}"
        }
      ]
    },
    [_vm._v("*")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }