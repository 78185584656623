<template>
    <div>
        <MainFrame v-if="currentStep === 'init'"
                   :has-progress-bar="true"
                   :progress-bar-current="progressCurrent"
                   :progress-bar-max="progressMax">
            <template v-slot:columnRight>
                <img :src="imageRightIndex" alt="">
            </template>
            <template v-slot:columnLeft>
                <h1 class="a-headline a-headline--1">
                    <img :src="iconShadingTubularMotor" alt="">
                    {{ $t('subprocess.children.shading.children.tubularMotor.title') }}
                </h1>
                <div v-html="$t('subprocess.children.shading.children.tubularMotor.body')"></div>

                <div class="m-btn-group m-btn-group--block">
                    <button @click.prevent="onSkipStep()" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('subprocess.children.shading.children.tubularMotor.actions.yes') }}
                    </button>
                    <button @click.prevent="currentStep = 'options'" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('subprocess.children.shading.children.tubularMotor.actions.no') }}
                    </button>
                    <button @click.prevent="onBack()" class="a-btn a-btn--secondary a-btn--big">
                        {{ $t('global.actions.back') }}
                    </button>
                </div>
            </template>
        </MainFrame>

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" class="m-form" action="#">
                <MainFrame v-if="currentStep === 'options'"
                           :has-progress-bar="true"
                           :progress-bar-current="progressCurrent"
                           :progress-bar-max="progressMax">
                    <template v-slot:columnRight>
                        <img :src="imageRightOptions" alt="">
                    </template>
                    <template v-slot:columnLeft>
                        <h1 class="a-headline a-headline--1">
                            <img :src="iconShadingTubularMotor" alt="">
                            {{ $t('subprocess.children.shading.children.tubularMotor.children.options.title') }}
                        </h1>
                        <div v-html="$t('subprocess.children.shading.children.tubularMotor.children.options.body')"></div>

                        <ValidationProvider
                            :name="$t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.width.label')"
                            rules="required"
                            v-slot="{ errors }" tag="div"
                            class="m-form__group">
                            <label class="m-form__label" for="windowWidth">
                                        <span class="sr-only">
                                            {{
                                                $t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.width.label')
                                            }}
                                        </span>
                                <input v-model="windowWidth"
                                       class="m-form__control"
                                       id="windowWidth"
                                       type="number"
                                       min="0"
                                       :placeholder="$t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.width.placeholder')"/>
                                <span v-if="errors && errors.length" class="m-form__message m-form__message--error">
                                            {{ $t(errors[0]) }}
                                        </span>
                            </label>
                        </ValidationProvider>

                        <ValidationProvider
                            :name="$t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.height.label')"
                            rules="required"
                            v-slot="{ errors }" tag="div"
                            class="m-form__group">
                            <label class="m-form__label" for="windowHeight">
                                        <span class="sr-only">
                                            {{
                                                $t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.height.label')
                                            }}
                                        </span>
                                <input v-model="windowHeight"
                                       class="m-form__control"
                                       id="windowHeight"
                                       type="number"
                                       min="0"
                                       :placeholder="$t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.height.placeholder')"/>
                                <span v-if="errors && errors.length" class="m-form__message m-form__message--error">
                                            {{ $t(errors[0]) }}
                                        </span>
                            </label>
                        </ValidationProvider>

                        <ValidationProvider
                            :name="$t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.label')"
                            rules="required|oneOf:wood,plastic,aluminium"
                            v-slot="{ errors }" tag="div"
                            class="m-form__group">
                            <label class="m-form__label" for="shutterMaterial">
                                <span class="sr-only">
                                    {{
                                        $t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.label')
                                    }}
                                </span>
                                <select v-model="shutterMaterial"
                                        class="m-form__control"
                                        id="shutterMaterial">
                                    <option value="">
                                        {{
                                            $t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.placeholder')
                                        }}
                                    </option>
                                    <option value="wood">
                                        {{
                                            $t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.options.wood')
                                        }}
                                    </option>
                                    <option                                        value="plastic">
                                        {{
                                            $t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.options.plastic')
                                        }}
                                    </option>
                                    <option value="aluminium">
                                        {{
                                            $t('subprocess.children.shading.children.tubularMotor.children.options.form.fields.material.options.aluminium')
                                        }}
                                    </option>
                                </select>
                                <span v-if="errors && errors.length" class="m-form__message m-form__message--error">
                                            {{ $t(errors[0]) }}
                                        </span>
                            </label>
                        </ValidationProvider>

                        <div class="m-btn-group m-btn-group--block">
                            <button @click.prevent="onSelect"
                                    type="submit"
                                    class="a-btn a-btn--primary  a-btn--big">
                                {{
                                    $t('subprocess.children.shading.children.tubularMotor.children.options.actions.next')
                                }}
                            </button>

                            <button @click.prevent="onSkipStep()"
                                    class="a-btn a-btn--secondary">
                                {{
                                    $t('subprocess.children.shading.children.tubularMotor.children.options.actions.cancel')
                                }}
                            </button>
                            <button @click.prevent="onBack()" class="a-btn a-btn--secondary a-btn--big">
                                {{ $t('global.actions.back') }}
                            </button>
                        </div>
                    </template>
                </MainFrame>
            </form>
        </ValidationObserver>

        <MainFrame v-if="currentStep === 'repeat'"
                   :has-progress-bar="true"
                   :progress-bar-current="progressCurrent"
                   :progress-bar-max="progressMax">
            <template v-slot:columnRight>
                <img :src="imageRightRepeat" alt="">
            </template>
            <template v-slot:columnLeft>
                <h1 class="a-headline a-headline--1">
                    <img :src="iconShadingTubularMotorPlus" alt="">
                    {{ $t('subprocess.children.shading.children.tubularMotor.children.repeat.title') }}
                </h1>
                <div v-html="$t('subprocess.children.shading.children.tubularMotor.children.repeat.body')"></div>

                <div class="m-btn-group m-btn-group--block">
                    <button @click.prevent="currentStep = 'options'" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('subprocess.children.shading.children.tubularMotor.children.repeat.actions.yes') }}
                    </button>
                    <button @click.prevent="onSubmit()" class="a-btn a-btn--primary a-btn--big">
                        {{ $t('subprocess.children.shading.children.tubularMotor.children.repeat.actions.no') }}
                    </button>
                    <button @click.prevent="onBack()" class="a-btn a-btn--secondary a-btn--big">
                        {{ $t('global.actions.back') }}
                    </button>
                </div>
            </template>
        </MainFrame>
    </div>
</template>

<script>
import ProgressBar from '../../ProgressBar';
import MainFrame from '../../MainFrame';
import AppIcons from '../../../mixins/app-icons';
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import {oneOf} from 'vee-validate/dist/rules';
import ProductNoWithPriceRow from '../../ProductNoWithPriceRow';
import ImageRightIndex from "../../../../../images/pages/shading-tubular-motor-index.jpeg";
import ImageRightOptions from "../../../../../images/pages/shading-tubular-motor-options.jpg";
import ImageRightRepeat from "../../../../../images/pages/shading-tubular-motor-repeat.jpg";

extend('oneOf', oneOf);

export default {
    name: 'ShadingTubularMotor',
    components: {
        ProductNoWithPriceRow,
        MainFrame,
        ProgressBar,
        ValidationObserver,
        ValidationProvider,
    },
    mixins: [AppIcons],
    props: {
        progressMax: {
            type: Number,
            required: true,
        },
        progressCurrent: {
            type: Number,
            required: true,
            default: 1,
        },
    },
    data() {
        return {
            imageRightIndex: ImageRightIndex,
            imageRightOptions: ImageRightOptions,
            imageRightRepeat: ImageRightRepeat,
            currentStep: 'init',
            windows: [],
            windowWidth: null,
            windowHeight: null,
            shutterMaterial: '',
        };
    },
    methods: {
        onSelect: function() {
            this.windows.push({
                windowWidth: this.windowWidth,
                windowHeight: this.windowHeight,
                shutterMaterial: this.shutterMaterial,
            });

            this.windowWidth = null;
            this.windowHeight = null;
            this.shutterMaterial = '';
            this.currentStep = 'repeat';
        },
        onSubmit: function() {
            this.$emit('subprocessShadingTubularMotorAdd', this.windows);
        },
        onSkipStep: function() {
            this.$emit('subprocessShadingTubularMotorSkip', this.windows);
        },
        onBack: function() {
            this.$emit('subprocessShadingTubularMotorSkipAndExit', this.windows);
        },
    },
};
</script>
